import { connect } from "react-redux";
import Screen from "./ForgotPasswordScreen";
import { doForgotPassword } from "../../actions/ForgotPasswordActions";
import { ActionType } from "../../utils/constan";

const mapStateToProps = (state) => ({
  forgot_password: state.forgot_password,
});

const mapDispatchToProps = (dispatch) => ({
  doForgotPassword: (email) => dispatch(doForgotPassword(email)),
  resetState: () =>
    dispatch({
      type: ActionType.RESET_FORGOT_PASSWORD_STATE,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
