import { ActionType, Api } from "../utils/constan";
import client from "../utils/service";
import { ApiConfig } from "../utils/constan";

export const doApplyJob = (job_id) => async (dispatch, getState) => {
  const state = getState().auth_reducer;
  dispatch({ type: ActionType.REQUEST_APPLY_JOB });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const headers = state.token
      ? {
          Authorization: `Bearer ${state.token}`,
        }
      : {};
    const response = await client.post(
      `${Api.applied_jobs}/${job_id}`,
      {},
      { headers }
    );
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      dispatch({
        type: ActionType.REQUEST_APPLY_JOB_SUCCESS,
        payload: { message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_APPLY_JOB_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_POPULAR_FAILED,
      payload: {
        message: "Failed Request APPLY JOB",
        status: response?.status,
      },
    });
  }
};

export const getAppliedJobs = () => async (dispatch, getState) => {
  const state = getState().auth_reducer;
  dispatch({ type: ActionType.REQUEST_LIST_APPLY_JOB });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const headers = state.token
      ? {
          Authorization: `Bearer ${state.token}`,
        }
      : {};
    const response = await client.get(`${Api.applied_jobs}`, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_LIST_APPLY_JOB_SUCCESS,
        payload: { message, data, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_LIST_APPLY_JOB_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_POPULAR_FAILED,
      payload: {
        message: "Failed Request LIST APPLY JOB",
        status: response?.status,
      },
    });
  }
};
