import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";
import useStyles from "./InquiryStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Images } from "../../themes";
import client from "../../utils/service";
import { Api, ApiConfig } from "../../utils/constan";
import Alert from "../../components/Alert";

//Data submitted successfully, our consultants will contact you immediately

const inquiryValidation = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "First Name is only contain alphabets")
    .required("First Name is required"),
  last_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Last Name is only contain alphabets")
    .required("Last Name is required"),
  phone: Yup.string()
    .matches(/^\d+$/, "Phone is only contain numbers")
    .min(10, "Please input at least 10 digits")
    .required("Phone is required"),
});

const InquiryScreen = (props) => {
  const styles = useStyles();
  const [corporateSector, setCorporateSector] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      business_email: "",
      company: "",
      phone: "",
      description: "",
      parameter_id: "",
    },
    validationSchema: inquiryValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      setOpenSnackbar(false);
      submitInquiry(values);
    },
  });

  useEffect(() => {
    props.getParameters();
  }, []);

  useEffect(() => {
    if (props.parameters.data.length > 0) {
      let _data = [];
      props.parameters.data.map((item) => {
        if (item?.is_active && item?.code == "BS") {
          _data.push(item);
        }
      });

      setCorporateSector(_data.sort((a, b) => a?.seq_no - b?.seq_no));
    }
  }, [props.parameters]);

  const submitInquiry = async (values) => {
    let body = values;
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    client
      .post(Api.hiringInquiry, body)
      .then(() => {
        setIsError(false);
        setAlertMessage(
          "Data submitted successfully, our consultants will contact you immediately"
        );
        setOpenSnackbar(true);
        formik.setSubmitting(false);
        formik.resetForm();
      })
      .catch((err) => {
        console.log(err.response);
        setIsError(true);
        setAlertMessage("Failed to submit data, please try again");
        setOpenSnackbar(true);
        formik.setSubmitting(false);
        formik.resetForm();
      });
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.inquiryContainer}>
        <Paper className={styles.inquiryBox}>
          <Typography
            className={styles.inquiryTitle}
            style={{ marginBottom: 50 }}
          >
            Hiring Inquiry
          </Typography>
          <img
            src={Images.linardi_logo}
            style={{ marginBottom: 50, width: 144, height: 72 }}
          />
          <Typography style={{ marginBottom: "63px", textAlign: "center" }}>
            If you are an employer and would like to discuss about hiring needs,
            you can complete the form below and we will respond as soon as
            possible.{" "}
          </Typography>
          <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography>First Name</Typography>
                <TextField
                  fullWidth
                  id="first_name"
                  value={formik.values["first_name"]}
                  placeholder="First Name"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched("first_name")}
                  inputProps={{
                    className:
                      formik.touched.first_name && formik.errors.first_name
                        ? styles.inputError
                        : styles.inputText,
                  }}
                  disabled={formik.isSubmitting}
                  required
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <span className={styles.errorMessage}>
                    {formik.errors.first_name}
                  </span>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>Last Name</Typography>
                <TextField
                  fullWidth
                  id="last_name"
                  value={formik.values["last_name"]}
                  placeholder="Last Name"
                  variant="outlined"
                  onBlur={() => formik.setFieldTouched("last_name")}
                  inputProps={{
                    className:
                      formik.touched.last_name && formik.errors.last_name
                        ? styles.inputError
                        : styles.inputText,
                  }}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  required
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <span className={styles.errorMessage}>
                    {formik.errors.last_name}
                  </span>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Business Email</Typography>
                <TextField
                  fullWidth
                  id="business_email"
                  value={formik.values["business_email"]}
                  placeholder="Email Address"
                  variant="outlined"
                  type="email"
                  inputProps={{
                    className: styles.inputText,
                  }}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Phone Number</Typography>
                <TextField
                  fullWidth
                  id="phone"
                  value={formik.values["phone"]}
                  placeholder="Phone Number"
                  variant="outlined"
                  onBlur={() => formik.setFieldTouched("phone")}
                  inputProps={{
                    className:
                      formik.touched.phone && formik.errors.phone
                        ? styles.inputError
                        : styles.inputText,
                  }}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  required
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <span className={styles.errorMessage}>
                    {formik.errors.phone}
                  </span>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Company</Typography>
                <TextField
                  fullWidth
                  id="company"
                  value={formik.values["company"]}
                  placeholder="Company Name"
                  variant="outlined"
                  inputProps={{
                    className: styles.inputText,
                  }}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Corporate Sector or Business Sector</Typography>
                <FormControl variant="outlined" className={styles.selectInput}>
                  <Select
                    id="parameter_id"
                    value={formik.values["parameter_id"]}
                    inputProps={{
                      style: { height: 44 },
                    }}
                    onChange={(e) =>
                      formik.setFieldValue("parameter_id", e.target.value)
                    }
                    disabled={formik.isSubmitting}
                    required
                  >
                    {corporateSector &&
                      corporateSector.map((sector) => (
                        <MenuItem value={sector.id} key={sector.id}>
                          {sector.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography>Describe the Role You Are Hiring For</Typography>
                <TextField
                  fullWidth
                  id="description"
                  value={formik.values["description"]}
                  placeholder="Description"
                  variant="outlined"
                  multiline
                  rows={10}
                  inputProps={{
                    className: styles.textArea,
                  }}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  required
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              className={styles.inquiryButton}
            >
              SUBMIT
            </Button>
          </form>
        </Paper>
      </div>
      <Footer />

      <Alert
        message={alertMessage}
        isOpen={openSnackbar}
        isError={isError}
        onClose={onCloseSnackBar}
      />
    </div>
  );
};

export default InquiryScreen;
