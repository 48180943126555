import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flex: 1,
    padding: "50px 73px",
    justifyContent: "center",
    alignItems: "stretch",
    backgroundColor: Colors.lighter_gray,
    "@media (max-width: 768px)": {
      padding: "20px",
      justifyContent: "flex-start",
    },
  },
  paper: {
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
  },
  sidebar: {
    // padding: "40px 0px",
    flex: 0.25,
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
  },
  sidebarBtn: {
    height: 70,
    fontSize: 18,
    fontWeight: 600,
    justifyContent: "flex-start",
    padding: "20px 0 20px 34px",
    textAlign: "left",
  },
  activeMenu: {
    color: Colors.blue,
    backgroundColor: "rgba(24, 117, 209, 0.2)",
    "&:hover": {
      backgroundColor: "rgba(24, 117, 209, 0.2)",
    },
  },
  mainbox: {
    flex: 0.75,
    marginLeft: 10,
    padding: "50px 75px",
    "@media (max-width: 768px)": {
      flex: 1,
      padding: 0,
    },
  },
  mdContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  mobileSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  },
  sectionBody: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  },
  mdAvatar: {
    width: 100,
    height: 100,
    borderRadius: 10,
    alignSelf: "center",
    marginBottom: 10,
  },
  sectionTextGroup: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  sectionText: {
    display: "flex",
    alignItems: "center",
  },
  titleContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
  },
  titleButton: {
    fontSize: 10,
    fontWeight: 600,
    height: 20,
    width: 60,
    borderColor: Colors.blue,
    color: Colors.blue,
    borderRadius: 5,
  },
  cvPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px",
  },
});

export default useStyles;
