import { connect } from "react-redux";
import Screen from "./InquiryScreen";
import { getParameters } from "../../actions/ParameterActions";

const mapStateToProps = (state) => ({
  parameters: state.parameters,
});

const mapDispatchToProps = (dispatch) => ({
  getParameters: (page = 1, page_size = 100) =>
    dispatch(getParameters(page, page_size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
