import React, { useState } from "react";
import {
  useMediaQuery,
  Link,
  Typography,
  Paper,
  Grow,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import useStyles from "./styles";
import { useHistory } from "react-router";

const PopOver = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const md = useMediaQuery("(max-width:768px)");
  const [isOpen, setIsOpen] = useState(null);

  const onHover = () => {
    setIsOpen(true);
  };

  const onExit = () => {
    setIsOpen(false);
  };

  const handleClick = (item) => {
    history.push({
      pathname: "/search",
      state: {
        industry: item?.display_value,
      },
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Link
        href="#"
        onMouseEnter={onHover}
        onMouseLeave={onExit}
        className={styles.title}
      >
        {props.title}
        <ExpandMore
          className={clsx(
            styles.dropdown,
            isOpen ? styles.dropdownOpen : styles.dropdownClosed
          )}
        />
      </Link>
      <Grow
        in={isOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...(isOpen ? { timeout: 300 } : {})}
      >
        <Paper
          square
          elevation={3}
          className={clsx(styles.popover, md ? styles.popoverMd : null)}
          onMouseEnter={onHover}
          onMouseLeave={onExit}
        >
          <div style={{ margin: 25, display: "flex", flexWrap: "wrap" }}>
            {props.items.map((item, i) => (
              <Link href="#" onClick={() => handleClick(item)} key={i}>
                <Typography className={styles.popoverLink}>
                  {item.display_value}
                </Typography>
              </Link>
            ))}
            <Link
              href="#"
              onClick={() =>
                history.push({
                  pathname: "/jobs",
                })
              }
            >
              <Typography className={styles.moreHover}>More ...</Typography>
            </Link>
          </div>
        </Paper>
      </Grow>
    </div>
  );
};

export default PopOver;
