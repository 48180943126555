import axios from "axios";
import { ApiConfig } from "./constan";

const client = axios.create({
  baseURL: ApiConfig.baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
  timeout: Number(ApiConfig.timeOut),
});

client.interceptors.request.use(
  (req) => {
    console.log(`${req.method} ${req.baseURL} ${req.url} ${req.timeout}`);
    return req;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

export default client;
