import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  InputBase,
  IconButton,
  Button,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  CheckCircle as CheckIcon,
  AddCircleRounded,
} from "@material-ui/icons";
import useStyles from "./styles";
import DefaultDialog from "../../../../components/DefaultDialog";
import Alert from "../../../../components/Alert";
import { editLanguages, getLanguages } from "../../../../actions/UsersActions";
import { connect } from "react-redux";
import { Images } from "../../../../themes";
import clsx from "clsx";

const LanguageForm = (props) => {
  const styles = useStyles();
  const [alertMessage, setAlertMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [languageData, setLanguageData] = useState(null);
  const [onAddValue, setOnAddValue] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [OnDelete, setOnDelete] = useState(false);

  useEffect(() => {
    const _data = props.users_reducer.language;
    if (!_data || _data.length < 1) {
      props.getLanguages();
    }
  }, []);

  useEffect(() => {
    const _data = props.users_reducer.language;
    if (_data && _data.length > 0) {
      setLanguageData(_data);
    }
  }, [props.users_reducer.language]);

  const onChangeInput = (e) => {
    const value = e.target.value;
    if (value.match("^[A-Za-z ]+$")) setInputValue(value);
  };

  const onSubmitEdit = (i) => {
    if (inputValue) {
      let other_languages = languageData;
      other_languages[i] = inputValue;
      const body = {
        other_languages,
      };
      props.editLanguages(body);
    }
    setInputValue(null);
    setEditValue(null);
  };

  const onSubmitAdd = () => {
    if (inputValue) {
      let other_languages = languageData;
      if (other_languages == null) other_languages = [];
      other_languages.push(inputValue);
      const body = {
        other_languages,
      };
      props.editLanguages(body);
    }
    setInputValue(null);
    setOnAddValue(false);
  };

  const onDeleteValue = (i) => {
    let other_languages = languageData;
    other_languages.splice(i, 1);
    const body = {
      other_languages,
    };
    props.editLanguages(body);
    setOnDelete(false);
    if (other_languages && other_languages.length > 0) {
      setLanguageData(other_languages);
    } else {
      setLanguageData(null);
    }

    setIsError(false);
    setAlertMessage("Successfully been deleted");
    setOpenSnackbar(true);
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOnAddValue(false);
    setEditValue(null);
    props?.closeDialog(false);
  };

  const onCancel = () => {
    setOnDelete(false);
    setDeleteIndex(null);
  };

  const confirmDialog = () => (
    <div>
      <Typography variant="h5">Are you sure to delete this data?</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "20px 0 10px 0",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
          style={{ marginRight: 20 }}
          className={clsx(styles.btn, styles.grayBtn)}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => onDeleteValue(deleteIndex)}
          style={{ marginLeft: 20 }}
          className={clsx(styles.btn, styles.blueBtn)}
        >
          OK
        </Button>
      </div>
    </div>
  );

  return (
    <DefaultDialog md={true} isOpen={props?.dialogOpen} onClose={handleClose}>
      {OnDelete ? (
        confirmDialog()
      ) : (
        <>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Languages
          </Typography>
          <div className={styles.content}>
            {languageData
              ? languageData.map((item, i) => {
                  if (editValue == i) {
                    return (
                      <div key={i} className={styles.langGroup}>
                        <Paper elevation={3} className={styles.langItem}>
                          <InputBase
                            autoFocus
                            className={styles.langText}
                            value={inputValue}
                            placeholder={item}
                            onChange={onChangeInput}
                            onKeyDown={(e) => {
                              if (e.keyCode == 13) onSubmitEdit(i);
                            }}
                          />
                          <CheckIcon
                            onClick={() => onSubmitEdit(i)}
                            className={styles.checkIcon}
                          />
                        </Paper>
                      </div>
                    );
                  } else {
                    return (
                      <div key={i} className={styles.langGroup}>
                        <Paper elevation={3} className={styles.langItem}>
                          <Typography className={styles.langText}>
                            {item}
                          </Typography>
                          <EditIcon
                            onClick={() => setEditValue(i)}
                            className={styles.editIcon}
                          />
                        </Paper>
                        <IconButton
                          onClick={() => {
                            setDeleteIndex(i);
                            setOnDelete(true);
                          }}
                          style={{ marginLeft: 20 }}
                        >
                          <img src={Images.trashIcon} />
                        </IconButton>
                      </div>
                    );
                  }
                })
              : !onAddValue && (
                  <div>
                    <p>Language is Empty</p>
                  </div>
                )}
            {onAddValue && (
              <div className={styles.langGroup}>
                <Paper elevation={3} className={styles.langItem}>
                  <InputBase
                    autoFocus
                    className={styles.langText}
                    value={inputValue}
                    placeholder="Languages"
                    onChange={onChangeInput}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) onSubmitAdd();
                    }}
                  />
                  <CheckIcon
                    onClick={() => onSubmitAdd()}
                    className={styles.checkIcon}
                  />
                </Paper>
                <IconButton
                  onClick={() => setOnAddValue(false)}
                  style={{ marginLeft: 20 }}
                >
                  <img src={Images.trashIcon} />
                </IconButton>
              </div>
            )}
            {/* </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "20px 0",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                onClick={() => setOnAddValue(true)}
                variant="outlined"
                style={{
                  lineHeight: "18px",
                  width: "fit-content",
                  marginBottom: 20,
                }}
                className={clsx(styles.btn, styles.addBtn)}
              >
                <AddCircleRounded className={styles.addIcon} />
                ADD LANGUAGE
              </Button>
              <Button
                onClick={() => handleClose()}
                style={{ lineHeight: "18px", width: "fit-content" }}
                className={clsx(styles.btn, styles.blueBtn)}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      )}

      <Alert
        message={alertMessage}
        isOpen={openSnackbar}
        isError={isError}
        onClose={onCloseSnackBar}
      />
    </DefaultDialog>
  );
};

const mapStateToProps = (state) => ({
  users_reducer: state.users_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLanguages: () => dispatch(getLanguages()),
  editLanguages: (body) => dispatch(editLanguages(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageForm);
