import React, { useEffect, useState } from "react";
import { Paper, Typography, Button, TextField, Grid } from "@material-ui/core";

import { useParams, useHistory } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import useStyles from "./ResetPasswordStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import Alert from "../../components/Alert";

const resetPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^.*(?=.{8,})((?=.*[[\]\\!@#$%^&*()\-_=+{};:,<.>'"/?`|~]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("Password is required"),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords don't match."),
});

const ResetPasswordScreen = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const { key } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const submitResetPassword = (values, setSubmitting) => {
    setOpenSnackbar(false);
    setOpenSuccessSnackbar(false);
    const { password, confirm_password } = values;
    const body = {
      password,
      confirm_password,
      key,
    };
    if (password && password === confirm_password) {
      props.doResetPassword(body);
    }
    setSubmitting(false);
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    let loading = props.reset_password.loading;
    let error = props.reset_password.error;
    let message = props.reset_password.message;

    if (!loading && !error && message) {
      setOpenSuccessSnackbar(true);
      setTimeout(() => {
        history.push("/signin");
      }, 1500);
    } else if (error && message) {
      setOpenSnackbar(true);
    }
  }, [props.reset_password.loading]);
  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.resetContainer}>
        <Paper className={styles.resetBox}>
          <Typography
            className={styles.resetTitle}
            style={{ marginBottom: "69px" }}
          >
            Reset Your Password
          </Typography>
          <Formik
            initialValues={{
              password: "",
              confirm_password: "",
            }}
            validationSchema={resetPasswordValidation}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              submitResetPassword(values, setSubmitting);
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              handleChange,
              setFieldTouched,
              errors,
              touched,
            }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography>New Password</Typography>
                    <TextField
                      fullWidth
                      placeholder="New Password"
                      type="password"
                      variant="outlined"
                      name="password"
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("password")}
                      inputProps={{
                        className:
                          touched.password && errors.password
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.password && errors.password ? (
                      <span className={styles.errorMessage}>
                        {errors.password}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Confirm New Password</Typography>
                    <TextField
                      fullWidth
                      placeholder="Confirm New Password"
                      type="password"
                      variant="outlined"
                      name="confirm_password"
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("confirm_password")}
                      inputProps={{
                        className:
                          touched.confirm_password && errors.confirm_password
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.confirm_password && errors.confirm_password ? (
                      <span className={styles.errorMessage}>
                        {errors.confirm_password}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Typography className={styles.passwordNotice}>
                  Your password must contain the following: an uppercase letter,
                  a number, and a special character.
                </Typography>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className={styles.resetButton}
                >
                  RESET PASSWORD
                </Button>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
      <Alert
        message={"Reset Password Failed"}
        isOpen={openSnackbar}
        isError={true}
        onClose={onCloseSnackBar}
      />
      <Alert
        message={"Your Password Successfully changed"}
        isOpen={openSuccessSnackbar}
        isError={false}
        onClose={onCloseSnackBar}
      />
      <Footer />
    </div>
  );
};

export default ResetPasswordScreen;
