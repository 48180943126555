import React, { useEffect, useState } from "react";
import {
  Typography,
  Avatar,
  Button,
  Grid,
  OutlinedInput,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Alert from "../../../../components/Alert";
import { useFormik } from "formik";
import clsx from "clsx";
import useStyles from "./styles";
import moment from "moment";
import { Api, ApiConfig } from "../../../../utils/constan";
import client from "../../../../utils/service";
import * as Yup from "yup";
import { getProfile, editProfile } from "../../../../actions/UsersActions";
import { connect } from "react-redux";
import DefaultDialog from "../../../../components/DefaultDialog";

const template = {
  First_Name: "",
  Last_Name: "",
  Email: "",
  Phone: "",
  Street: "",
  City: "",
  Country: "",
  LinkedIn_URL: "",
  AvatarUrl: "",
};

const FORMDATE = {
  year: null,
  month: null,
  day: null,
};

const msg = {
  success: "Data successfully changed",
  error: "Failed to edit profile, please try again",
};

const validationSchema = Yup.object().shape({
  Phone: Yup.string().min(10, "Please input at least 10 digits"),
});

const ProfileForm = (props) => {
  const styles = useStyles();
  const [imageFile, setImageFile] = useState(null);
  const [profileData, setProfileData] = useState(template);
  const [profileError, setProfileError] = useState(false);
  const [formDate, setFormDate] = useState(FORMDATE);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState(msg.success);
  const [isUploading, setIsUploading] = useState(false);

  const formik = useFormik({
    initialValues: profileData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      saveChanges(values);
    },
  });

  useEffect(() => {
    let loading = props.users_reducer.loading;
    let error = props.users_reducer.error;

    setAlertMessage(error ? msg.error : msg.success);
    setProfileError(error);
    if (!loading && formik.isSubmitting) {
      setOpenSnackbar(true);
      formik.setSubmitting(false);
    }
  }, [props.users_reducer.loading]);

  useEffect(() => {
    if (!props.users_reducer.profile) {
      props.getProfile();
    }
  }, []);

  useEffect(() => {
    if (props.data) {
      let data = props.data;
      let s = data?.Year_of_Birth.split("-");
      const date = {
        year: s[0],
        month: s[1],
        day: s[2],
      };
      if (data?.Photo_URL) {
        setImageFile(data.Photo_URL);
      } else {
        setImageFile(null);
      }

      setProfileData(data);
      setFormDate(date);
    }
  }, [props.users_reducer]);

  const saveChanges = (values) => {
    let dob = `${formDate?.year}-${formDate?.month}-${formDate?.day}`;
    let data = {
      first_name: values?.First_Name,
      last_name: values?.Last_Name,
      email: values?.Email,
      phone: values?.Phone.toString(),
      street: values?.Street,
      city: values?.City,
      country: values?.Country,
      date_of_birth: dob,
      linkedin_url: values?.LinkedIn_URL,
    };
    console.log(data);
    props.editProfile(data);
  };

  // const handleDateChange = (e, val) => {
  //   const newDate = formDate;

  //   let value = moment(val);
  //   switch (e) {
  //     case "year":
  //       value = value.year();
  //       break;
  //     case "month":
  //       newDate["day"] = 1;
  //       value = value.month() + 1;
  //       break;
  //     case "day":
  //       newDate["year"] = value.year();
  //       newDate["month"] = value.month() + 1;
  //       value = value.date();
  //       break;
  //   }

  //   newDate[e] = value;
  //   setFormDate({ ...newDate });
  // };

  const onFileChange = (e) => {
    e.preventDefault();
    uploadHandler(e.target.files);
  };

  const deleteAvatar = () => {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .delete(Api.avatar, {
        headers: {
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
      })
      .then(() => {
        setAlertMessage("Remove Avatar Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        setImageFile(null);
        props.getProfile();
        props.getProfile();
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage("Failed To Remove Avatar");
        setProfileError(true);
        setOpenSnackbar(true);
        setIsUploading(false);
      });
  };

  const uploadHandler = async (file) => {
    setIsUploading(true);
    setProfileError(false);
    let formData = new FormData();
    formData.append("file", file[0]);
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .post(Api.avatar, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
      })
      .then(() => {
        setAlertMessage("Upload Avatar Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        props.getProfile();
        props.getProfile();
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage("Failed To Upload File");
        setProfileError(true);
        setOpenSnackbar(true);
        setIsUploading(false);
      });
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DefaultDialog
        md={true}
        isOpen={props?.dialogOpen}
        onClose={props?.closeDialog}
      >
        <Typography variant="h5" style={{ marginBottom: 40 }}>
          Edit Profile
        </Typography>

        <div className={styles.avatarGroup}>
          {isUploading ? (
            <Avatar
              variant="square"
              className={styles.avatar}
              style={{ margin: 0 }}
            >
              <CircularProgress />
            </Avatar>
          ) : imageFile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                variant="square"
                src={imageFile}
                className={styles.avatar}
                style={{ margin: 0 }}
              />
              <Typography style={{ marginTop: "10px" }}>
                Only accepted .jpg, .jpeg & .png
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                variant="square"
                className={styles.avatar}
                style={{ margin: 0 }}
              >
                Profile
                <br />
                Picture
              </Avatar>
              <Typography style={{ marginTop: "10px" }}>
                Only accepted .jpg, .jpeg & .png
              </Typography>
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              onChange={onFileChange}
              accept="image/jpeg,image/jpg,image/png"
              type="file"
              id="file-input"
              style={{ display: "none" }}
            />
            <label htmlFor="file-input">
              <Button
                style={{ marginBottom: 5, marginTop: 10 }}
                className={clsx(styles.avatarBtn, styles.blueBtn)}
                component="span"
              >
                UPLOAD OR CHANGE PICTURE
              </Button>
            </label>
            <Button
              variant="outlined"
              style={{ marginTop: 5 }}
              className={clsx(styles.avatarBtn, styles.grayBtn)}
              onClick={() => deleteAvatar()}
            >
              REMOVE PHOTO
            </Button>
          </div>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className={styles.column}
          style={{ alignItems: "center" }}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <InputLabel htmlFor="First_Name" className={styles.inputLabel}>
                  First Name
                </InputLabel>
                <OutlinedInput
                  className={styles.input}
                  id="First_Name"
                  placeholder="First Name"
                  disabled={formik.isSubmitting}
                  value={formik.values["First_Name"]}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={styles.formGroup}>
                <InputLabel htmlFor="Last_Name" className={styles.inputLabel}>
                  Last Name
                </InputLabel>
                <OutlinedInput
                  className={styles.input}
                  id="Last_Name"
                  placeholder="Last Name"
                  disabled={formik.isSubmitting}
                  value={formik.values["Last_Name"]}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <InputLabel htmlFor="Email" className={styles.inputLabel}>
                  Email
                </InputLabel>
                <OutlinedInput
                  disabled={true}
                  className={styles.input}
                  type="email"
                  id="Email"
                  placeholder="Email"
                  value={formik.values["Email"]}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <InputLabel htmlFor="Phone" className={styles.inputLabel}>
                  Phone Number
                </InputLabel>
                <OutlinedInput
                  className={styles.input}
                  id="Phone"
                  placeholder="Phone Number"
                  disabled={formik.isSubmitting}
                  value={formik.values["Phone"]}
                  error={formik.touched.Phone && Boolean(formik.errors.Phone)}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    const regex = /^[0-9\b]+$/;
                    if (value === "" || regex.test(value.toString())) {
                      formik.setFieldValue("Phone", value);
                    }
                  }}
                />
                {formik.touched.Phone && Boolean(formik.errors.Phone) && (
                  <span className={styles.errorMessage}>
                    {formik.errors.Phone}
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <InputLabel htmlFor="Street" className={styles.inputLabel}>
                  Address
                </InputLabel>
                <OutlinedInput
                  className={styles.input}
                  id="Street"
                  placeholder="Address"
                  disabled={formik.isSubmitting}
                  value={formik.values["Street"]}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={6}>
              <div className={styles.formGroup}>
                <OutlinedInput
                  className={styles.input}
                  id="City"
                  placeholder="City"
                  disabled={formik.isSubmitting}
                  value={formik.values["City"]}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.formGroup}>
                <OutlinedInput
                  className={styles.input}
                  id="Country"
                  placeholder="Country"
                  disabled={formik.isSubmitting}
                  value={formik.values["Country"]}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
          </Grid>

          <InputLabel
            className={styles.inputLabel}
            style={{ alignSelf: "flex-start", marginLeft: 8 }}
          >
            Date Of Birth
          </InputLabel>
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <DatePicker
                  disabled={formik.isSubmitting}
                  autoOk
                  label="Date of Birth"
                  maxDate={moment().subtract(17, "years")}
                  minDate={new Date("1970-01-01")}
                  format="dd/MM/yyyy"
                  value={
                    formDate.year
                      ? new Date(
                          `${formDate["year"]}-${formDate["month"]}-${formDate["day"]}`
                        )
                      : null
                  }
                  onChange={(newValue) => {
                    let value = moment(newValue);
                    const newDate = formDate;
                    newDate["year"] = value.year();
                    newDate["month"] = value.month() + 1;
                    newDate["day"] = value.date();
                    console.log(newDate);
                    setFormDate({ ...newDate });
                    let dob = `${newDate?.year}-${newDate?.month || 1}-${
                      newDate?.day || 1
                    }`;
                    formik.setFieldValue("Date_of_Birth", dob);
                  }}
                  TextFieldComponent={OutlinedInput}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
            }}
          >
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <InputLabel
                  htmlFor="LinkedIn_URL"
                  className={styles.inputLabel}
                >
                  Linkedin Url
                </InputLabel>
                <OutlinedInput
                  className={styles.input}
                  id="LinkedIn_URL"
                  placeholder="Linkedin Url"
                  disabled={formik.isSubmitting}
                  value={formik.values["LinkedIn_URL"]}
                  onChange={formik.handleChange}
                />
              </div>
            </Grid>
          </Grid>
          {/* </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "20px 0",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              disabled={formik.isSubmitting}
              onClick={() => props?.closeDialog()}
              style={{ marginRight: 20, width: "fit-content" }}
              className={clsx(styles.btn, styles.grayBtn)}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              style={{ lineHeight: "18px", width: "fit-content" }}
              className={clsx(styles.btn, styles.blueBtn)}
            >
              SAVE CHANGES
            </Button>
          </div>
        </form>

        <Alert
          message={alertMessage}
          isOpen={openSnackbar}
          isError={profileError}
          onClose={onCloseSnackBar}
        />
      </DefaultDialog>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  users_reducer: state.users_reducer,
  auth_reducer: state.auth_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  editProfile: (body) => dispatch(editProfile(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
