import { connect } from "react-redux";
import { doLogin, doLinkedinLogin } from "../../actions/AuthActions";
import Screen from "./SigninScreen";
import { ActionType } from "../../utils/constan";

const mapStateToProps = (state) => ({
  auth_reducer: state.auth_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: (data) => dispatch(doLogin(data)),
  doLinkedinLogin: (code) => dispatch(doLinkedinLogin(code)),
  resetState: () =>
    dispatch({
      type: ActionType.RESET_FORGOT_PASSWORD_STATE,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
