import React, { useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Typography,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
} from "@material-ui/core";
import Alert from "../../components/Alert";
// import FAB from "../../components/FAB";
import clsx from "clsx";
import useStyles from "./SettingsStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import client from "../../utils/service";
import { Api, ApiConfig } from "../../utils/constan";
import { connect } from "react-redux";

const menus = ["Account Setting"];

const resetPasswordValidation = Yup.object().shape({
  current_password: Yup.string().required("Please input your current password"),
  new_password: Yup.string()
    .matches(
      /^.*(?=.{8,})((?=.*[[\]\\!@#$%^&*()\-_=+{};:,<.>'"/?`|~]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("Please input your new password")
    .notOneOf(
      [Yup.ref("current_password")],
      "Your new password is same as current password"
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("new_password"), null], "Passwords don't match."),
});

const AccountSettings = ({ token }) => {
  const styles = useStyles();
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const md = useMediaQuery("(max-width:768px)");

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: resetPasswordValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitChangePassword(values);
    },
  });

  const submitChangePassword = (body) => {
    console.log(token);
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .put(Api.changePassword, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsError(false);
        setAlertMessage("Change Password Success");
        setOpenSnackbar(true);
        formik.setSubmitting(false);
        formik.resetForm();
      })
      .catch((err) => {
        console.log(err.response?.data?.meta?.message);
        setIsError(true);
        setAlertMessage(err.response?.data?.meta?.message);
        setOpenSnackbar(true);
        formik.setSubmitting(false);
        formik.resetForm();
      });
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className={styles.container}>
      <Typography
        variant="h4"
        className={styles.pageTitle}
        style={{
          marginLeft: md ? "15px" : "15px",
          marginTop: md ? "15px" : null,
        }}
      >
        Setting
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={md ? 12 : 8}>
            <div className={styles.formGroup}>
              <InputLabel
                htmlFor="current_password"
                className={styles.inputLabel}
              >
                Current Password
              </InputLabel>
              <OutlinedInput
                type="password"
                className={styles.input}
                id="current_password"
                placeholder="Current Password"
                disabled={formik.isSubmitting}
                value={formik.values["current_password"]}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched("current_password")}
              />
              {formik.touched.current_password &&
              formik.errors.current_password ? (
                <span className={styles.errorMessage}>
                  {formik.errors.current_password}
                </span>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={md ? 12 : 8}>
            <div className={styles.formGroup}>
              <InputLabel htmlFor="new_password" className={styles.inputLabel}>
                New Password
              </InputLabel>
              <OutlinedInput
                type="password"
                className={styles.input}
                id="new_password"
                placeholder="New Password"
                disabled={formik.isSubmitting}
                value={formik.values["new_password"]}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched("new_password")}
              />
              {formik.touched.new_password && formik.errors.new_password ? (
                <span className={styles.errorMessage}>
                  {formik.errors.new_password}
                </span>
              ) : (
                <></>
              )}
            </div>
            <p style={{ margin: "10px 15px" }}>
              Your password must contain the following: an uppercase letter, a
              number, and a special character
            </p>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={md ? 12 : 8}>
            <div className={styles.formGroup}>
              <InputLabel
                htmlFor="confirm_password"
                className={styles.inputLabel}
              >
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                type="password"
                className={styles.input}
                id="confirm_password"
                placeholder="Confirm New Password"
                disabled={formik.isSubmitting}
                value={formik.values["confirm_password"]}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched("confirm_password")}
              />
              {formik.touched.confirm_password &&
              formik.errors.confirm_password ? (
                <span className={styles.errorMessage}>
                  {formik.errors.confirm_password}
                </span>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: md ? "40px 0 10px 15px" : "40px 0 10px 0",
            justifyContent: md ? "center" : "flex-start",
          }}
        >
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            className={clsx(styles.btn, styles.blueBtn)}
          >
            SAVE CHANGES
          </Button>
        </div>
      </form>

      <Alert
        message={alertMessage}
        isOpen={openSnackbar}
        isError={isError}
        onClose={onCloseSnackBar}
      />
    </div>
  );
};

const SettingsScreen = (props) => {
  const styles = useStyles();
  const md = useMediaQuery("(max-width:768px)");

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.content}>
        {md ? null : (
          <Paper className={clsx(styles.paper, styles.sidebar)}>
            {menus.map((item, i) => (
              <Button
                key={i}
                onClick={() => console.log("Account Settings")}
                className={clsx(styles.sidebarBtn, styles.activeMenu)}
              >
                {item}
              </Button>
            ))}
          </Paper>
        )}
        <Paper className={clsx(styles.paper, styles.mainbox)}>
          <AccountSettings token={props.auth_reducer.token} />
        </Paper>
      </div>
      {/* {md && (
        <Dialog
          open={isDialogMobileOpen}
          onClose={handleDialogMobileClose}
          PaperProps={{ style: { overflow: "hidden" } }}
        >
          <ProfileContent />
        </Dialog>
      )} */}
      {/* <FAB /> */}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth_reducer: state.auth_reducer,
});

export default connect(mapStateToProps, null)(SettingsScreen);
