import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    backgroundColor: "#FFFFFF",
  },
  bgContainer: {
    display: "flex",
    flexDirection: "column",
    background: "url('/assets/images/img_bg.png') no-repeat center",
    backgroundSize: "cover",
    height: "350px",
    padding: "0 73px",
    justifyContent: "center",
    "@media (max-width: 641px)": {
      padding: "0 16px !important",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  bgContainerMd: {
    height: 500,
  },
  containerTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "21px",
    lineHeight: "20px",
    marginBottom: "50px",
  },
  searchTitle: {
    color: "white",
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
  },
  listContainer: {
    padding: "73px",
  },
  listWrapper: {
    columnCount: 3,
    columnGap: 20,
    "@media (max-width: 768px)": {
      columnCount: "unset",
      columnGap: 0,
    },
  },
  listItem: {
    color: Colors.blue,
    fontSize: 18,
  },
  listLink: {
    color: Colors.blue,
  },
  listLinkTitle: {
    fontSize: "24px",
    fontWeight: "600",
    color: Colors.blue,
    marginTop: "16px",
  },
});

export default useStyles;
