import React from "react";
import { Popover, Link, Typography } from "@material-ui/core";
import useStyles from "./styles";

const Dropdown = ({
  id,
  items,
  anchor,
  handleClose,
  clickItem,
  isOpen,
  sizeProps,
}) => {
  const styles = useStyles();
  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={sizeProps}
    >
      <div
        style={{
          margin: 25,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {items.map((item, i) => (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              clickItem(item);
            }}
            key={i}
          >
            <Typography className={styles.popoverLink}>
              {item.display_value}
            </Typography>
          </Link>
        ))}
      </div>
    </Popover>
  );
};

export default Dropdown;
