import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useMediaQuery,
  AppBar,
  Toolbar,
  Button,
  Paper,
  Grow,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
} from "@material-ui/core";
import {
  ExpandMore,
  AccountCircle,
  Menu as MenuIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { useLocation, useHistory, NavLink, Link } from "react-router-dom";
import clsx from "clsx";
import useStyles from "./styles.js";
import { autoLogin, doLogout } from "../../actions/AuthActions.js";
import { getProfile, clearData } from "../../actions/UsersActions.js";
import { Images } from "../../themes/index.js";
import { styled } from "@material-ui/core/styles";
import { useScrollTrigger } from "@material-ui/core";

const hideButton = {
  signup: ["/signup/success"],
  formWizard: ["/signup/profile"],
};

function AuthBox(props) {
  const [isOpen, setIsOpen] = useState(false);
  const md = useMediaQuery("(max-width:1007px)");
  const history = useHistory();
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {props.isLoggedIn ? (
        <>
          {md ? (
            <>
              <a href="#" className={classes.profileBoxMd}>
                {/* <AccountCircle className={classes.userIcon} /> */}
                {props.avatar ? (
                  <Avatar className={classes.userIcon} src={props.avatar} />
                ) : (
                  <AccountCircle className={classes.userIcon} />
                )}
                <p className={classes.userBtn}>{props.username}</p>
              </a>
              <ListItem button onClick={() => history.push("/profile")}>
                <ListItemText className={classes.listItemText}>
                  YOUR ACCOUNT
                </ListItemText>
              </ListItem>
              <ListItem button onClick={() => history.push("/settings")}>
                <ListItemText className={classes.listItemText}>
                  SETTINGS
                </ListItemText>
              </ListItem>
              <ListItem button onClick={() => history.push("/savedjobs")}>
                <ListItemText className={classes.listItemText}>
                  SAVED JOBS
                </ListItemText>
              </ListItem>
              <ListItem button onClick={() => history.push("/appliedjobs")}>
                <ListItemText className={classes.listItemText}>
                  APPLIED JOBS
                </ListItemText>
              </ListItem>
              <ListItem button onClick={() => props.handleLogout()}>
                <ListItemText className={classes.listItemText}>
                  LOGOUT
                </ListItemText>
              </ListItem>
            </>
          ) : (
            <div style={{ position: "relative" }}>
              <a href="#" onClick={handleClick} className={classes.profileBox}>
                {props.avatar ? (
                  <Avatar className={classes.userIcon} src={props.avatar} />
                ) : (
                  <AccountCircle className={classes.userIcon} />
                )}
                <p className={classes.userBtn}>{props.username}</p>
                <ExpandMore
                  className={clsx(
                    classes.dropdown,
                    isOpen ? classes.dropdownOpen : classes.dropdownClosed
                  )}
                />
              </a>
              <Grow
                in={isOpen}
                style={{ transformOrigin: "0 0 0" }}
                {...(isOpen ? { timeout: 300 } : {})}
              >
                <Paper square elevation={3} className={classes.popover}>
                  <div className={classes.popoverWrapper}>
                    <Link to="/profile" className={classes.popoverLink}>
                      Your Account
                    </Link>
                    <Link to="/settings" className={classes.popoverLink}>
                      Settings
                    </Link>
                    <Link
                      onClick={() => props.handleLogout()}
                      className={classes.popoverLink}
                    >
                      Logout
                    </Link>
                  </div>
                </Paper>
              </Grow>
            </div>
          )}
        </>
      ) : (
        <>
          {md ? (
            <>
              <ListItem button onClick={() => history.push("/signup")}>
                <ListItemText className={classes.listItemText}>
                  SIGN UP
                </ListItemText>
              </ListItem>
              <ListItem button onClick={() => history.push("/signin")}>
                <ListItemText className={classes.listItemText}>
                  SIGN IN
                </ListItemText>
              </ListItem>
            </>
          ) : (
            <div className={classes.authBox}>
              <Button
                onClick={() => history.push("/signin")}
                className={classes.signInButton}
                style={{
                  color:
                    location.pathname == "/"
                      ? trigger
                        ? "#808080"
                        : "#FFFFFF"
                      : "#808080",
                }}
              >
                SIGN IN
              </Button>
              <Button
                onClick={() => history.push("/signup")}
                className={classes.signUpButton}
                style={{
                  visibility: `${
                    hideButton.signup.includes(location.pathname)
                      ? "hidden"
                      : "visible"
                  }`,
                }}
              >
                SIGN UP
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}

function Menu(props) {
  const classes = useStyles();
  const md = useMediaQuery("(max-width:1007px)");
  const { pathname } = useLocation();
  const history = useHistory();
  const routeWithouteParam = pathname.slice(0, pathname.lastIndexOf("/"));

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const NavLinkOnScroll = styled(NavLink)({
    color:
      location.pathname == "/" ? (trigger ? "#808080" : "#FFFFFF") : "#808080",
    fontWeight: "500",
    textDecoration: "none",
    margin: "0 30px",
  });
  return (
    <>
      {md ? (
        <List>
          <AuthBox
            isLoggedIn={props.isLoggedIn}
            handleLogin={props.handleLogin}
            handleLogout={props.handleLogout}
            username={props.username}
            avatar={props.avatar}
          />
          <Divider classes={{ root: classes.divider }} variant="middle" />
          <ListItem button onClick={() => history.push("/home")}>
            <ListItemText className={classes.listItemText}>HOME</ListItemText>
          </ListItem>
          <ListItem button onClick={() => history.push("/jobs")}>
            <ListItemText className={classes.listItemText}>JOB</ListItemText>
          </ListItem>
          <ListItem button onClick={() => history.push("/blogs")}>
            <ListItemText className={classes.listItemText}>BLOG</ListItemText>
          </ListItem>
          <ListItem button onClick={() => history.push("/contact")}>
            <ListItemText className={classes.listItemText}>
              CONTACT
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => history.push("/inquiry")}>
            <ListItemText className={classes.listItemText}>
              HIRING INQUIRY
            </ListItemText>
          </ListItem>
        </List>
      ) : (
        <>
          <div
            className={classes.menu}
            style={{
              visibility: `${
                hideButton.formWizard.includes(routeWithouteParam)
                  ? "hidden"
                  : "visible"
              }`,
            }}
          >
            <NavLinkOnScroll
              to="/"
              className={classes.link}
              activeClassName={classes.linkActive}
              isActive={() => ["/"].includes(pathname)}
            >
              HOME
            </NavLinkOnScroll>
            <NavLinkOnScroll
              to="/jobs"
              className={classes.link}
              activeClassName={classes.linkActive}
              isActive={() => ["/jobs", "/search"].includes(pathname)}
            >
              JOB
            </NavLinkOnScroll>
            <NavLinkOnScroll
              to="/blogs"
              className={classes.link}
              activeClassName={classes.linkActive}
            >
              BLOG
            </NavLinkOnScroll>
            <NavLinkOnScroll
              to="/contact"
              className={classes.link}
              activeClassName={classes.linkActive}
            >
              CONTACT
            </NavLinkOnScroll>
            <NavLinkOnScroll
              to="/inquiry"
              className={classes.link}
              activeClassName={classes.linkActive}
            >
              HIRING INQUIRY
            </NavLinkOnScroll>
          </div>
          {routeWithouteParam == "/signup/profile" ? (
            <Button
              type="button"
              className={classes.skipForm}
              disabled={props.disableSkip}
              onClick={() => props.onSkip()}
            >
              {"SKIP THIS FORM >"}
            </Button>
          ) : (
            <AuthBox
              isLoggedIn={props.isLoggedIn}
              handleLogin={props.handleLogin}
              handleLogout={props.handleLogout}
              username={props.username}
              avatar={props.avatar}
            />
          )}
        </>
      )}
    </>
  );
}

function Navbar(props) {
  const md = useMediaQuery("(max-width:1007px)");
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [username, setUsername] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    setAvatar(props.avatar);
  }, [props.avatar]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tk = query.get("_tk");
    if (tk !== null) {
      props.autoLogin(tk);
      props.getProfile();
    }
  }, [location]);

  useEffect(() => {
    props.auth_reducer.isLoggedIn && props.getProfile();
  }, []);

  useEffect(() => {
    if (props.auth_reducer.token && props.auth_reducer.isLoggedIn) {
      let userData = props.auth_reducer.data;
      setUsername(userData.first_name);
      setIsLoggedIn(true);
    } else {
      setUsername(null);
      setIsLoggedIn(false);
    }
  }, [props.auth_reducer]);

  const doLogout = (e) => {
    if (e) e.preventDefault();
    props.doLogout();
    props.clearUserData();
    history.push("/");
  };

  const renderMenu = () => (
    <>
      {md ? (
        <>
          <IconButton
            edge="end"
            className={classes.menuButton}
            aria-label="menu"
            onClick={() => setToggleDrawer(true)}
          >
            <MenuIcon style={{ fontSize: 42 }} />
          </IconButton>
          <Drawer
            anchor={"right"}
            open={toggleDrawer}
            onClose={() => setToggleDrawer(false)}
            classes={{ paper: classes.rootDrawer }}
          >
            <div className={classes.menuMdContainer}>
              <IconButton
                onClick={() => setToggleDrawer(false)}
                classes={{ root: classes.iconButton }}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
              <Menu
                isLoggedIn={isLoggedIn}
                handleLogout={doLogout}
                username={username}
                avatar={avatar}
              />
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <Menu
            isLoggedIn={isLoggedIn}
            handleLogout={doLogout}
            route={location.pathname}
            username={username}
            avatar={avatar}
            onSkip={props.onSkip}
            disableSkip={props.disableSkip}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <AppBar
        position={location.pathname == "/" ? "fixed" : "static"}
        style={
          location.pathname
            ? {
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                background: trigger ? "#ffffff" : "transparent",
                transition: trigger ? "0.3s" : "0.5s",
              }
            : null
        }
      >
        <Toolbar className={classes.navbar}>
          <Link to="/">
            <img
              src={
                location.pathname == "/"
                  ? trigger
                    ? Images.linardi_logo
                    : Images.linardi_logo_white
                  : Images.linardi_logo
              }
              style={{ width: "86.06px", height: "43px" }}
            />
          </Link>
          {renderMenu()}
        </Toolbar>
      </AppBar>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth_reducer: state.auth_reducer,
  avatar: state.users_reducer.avatar,
});

const mapDispatchToProps = (dispatch) => ({
  clearUserData: () => dispatch(clearData()),
  doLogout: () => dispatch(doLogout()),
  getProfile: () => dispatch(getProfile()),
  autoLogin: (token) => dispatch(autoLogin(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
