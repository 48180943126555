import React, { useEffect, useState } from "react";
import {
  useMediaQuery,
  Paper,
  Button,
  Typography,
  Avatar,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  AddCircle as AddCircleIcon,
  HistoryOutlined,
  ImportContacts,
} from "@material-ui/icons/";
// import FAB from "../../components/FAB";
import clsx from "clsx";
import useStyles from "./ProfileStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ProgressBar from "../../components/ProgressBar";

import {
  ProfileContent,
  ExperienceContent,
  EducationContent,
  PortfolioContent,
  LanguagesContent,
  SavedJobsContent,
  ApplicationContent,
} from "./menu";
import ProfileForm from "./menu/Profile/form";
import ExperienceForm from "./menu/Experience/form";
import EducationForm from "./menu/Education/form";
import PortfolioForm from "./menu/Portfolio/form";
import LanguageForm from "./menu/Languages/form";
import { Images, Colors } from "../../themes";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import {
  getEducations,
  getExperience,
  getLanguages,
  getPortfolio,
  getProfileProgress,
} from "../../actions/UsersActions";
import moment from "moment";

const menus = [
  "Profile",
  "Work Experience",
  "Education",
  "CV/Portfolio",
  "Languages",
  "Saved Jobs",
  "Applications",
];

const ProfileScreen = (props) => {
  const styles = useStyles();
  const md = useMediaQuery("(max-width:768px)");
  const [profileData, setProfileData] = useState(null);
  const [inputData, setInputData] = useState(null);
  const [workData, setWorkData] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const [languageData, setLanguageData] = useState(null);

  const [activeMenu, setActiveMenu] = useState(menus[0]);
  const [mobileDialog, setMobileDialog] = useState(null);
  const [isDialogMobileOpen, setIsDialogMobileOpen] = useState(false);
  const [Progress, setProgress] = useState(0);

  useEffect(() => {
    props.getProfileProgress();
    if (props.users_reducer.profile) {
      let data = props.users_reducer.profile;
      setProfileData(data);
    }
  }, [activeMenu]);

  useEffect(() => {
    props.getWorkExperience();
    props.getEducation();
    props.getLanguage();
    props.getPortfolio();
  }, []);

  useEffect(() => {
    if (props.users_reducer.experience) {
      let data = props.users_reducer.experience;
      setWorkData(data);
    }

    if (props.users_reducer.education) {
      let data = props.users_reducer.education;
      setEducationData(data);
    }

    if (props.users_reducer.language) {
      let data = props.users_reducer.language;
      setLanguageData(data);
    }

    if (props.users_reducer.portfolio) {
      let data = props.users_reducer.portfolio;
      setPortfolioData(data);
    }
    if (props.users_reducer.progress) {
      let data = props.users_reducer.progress;
      setProgress(data);
    }

    if (props.users_reducer.profile) {
      let data = props.users_reducer.profile;
      setProfileData(data);
    }
  }, [props.users_reducer]);

  // useEffect(() => {
  //   if (props.users_reducer.profile) {
  //     let data = props.users_reducer.profile;

  //     setProfileData(data);
  //   }
  // }, [props.users_reducer]);

  const handleClickMenu = (menu) => {
    setActiveMenu(menu);
    console.log(activeMenu);
  };

  const handleDialogMobileClose = () => {
    setIsDialogMobileOpen(false);
    setInputData(null);
    console.log("tombol cancel ditekan");
    window.location.reload();
  };

  const renderContent = () => {
    switch (activeMenu) {
      case "Profile":
        return <ProfileContent />;
      case "Work Experience":
        return <ExperienceContent />;
      case "Education":
        return <EducationContent />;
      case "CV/Portfolio":
        return <PortfolioContent />;
      case "Languages":
        return <LanguagesContent />;
      case "Saved Jobs":
        return <SavedJobsContent />;
      case "Applications":
        return <ApplicationContent />;
    }
  };

  const renderMobileDialog = () => {
    switch (mobileDialog) {
      case "experience":
        return (
          <ExperienceForm
            data={inputData}
            dialogOpen={isDialogMobileOpen}
            closeDialog={handleDialogMobileClose}
          />
        );
      case "education":
        return (
          <EducationForm
            data={inputData}
            dialogOpen={isDialogMobileOpen}
            closeDialog={handleDialogMobileClose}
          />
        );
      case "portfolio":
        return (
          <PortfolioForm
            data={portfolioData}
            dialogOpen={isDialogMobileOpen}
            closeDialog={handleDialogMobileClose}
          />
        );
      case "language":
        return (
          <LanguageForm
            data={inputData}
            dialogOpen={isDialogMobileOpen}
            closeDialog={handleDialogMobileClose}
          />
        );
      default:
        return (
          <ProfileForm
            data={profileData}
            dialogOpen={isDialogMobileOpen}
            closeDialog={handleDialogMobileClose}
          />
        );
    }
  };

  const handleClickItem = (component, data = null) => {
    setInputData(data);
    setMobileDialog(component);
    setIsDialogMobileOpen(true);
  };

  const renderMobileContent = () => (
    <div className={styles.mdContainer}>
      {/* PROGRESS */}
      <div className={styles.mobileSection}>
        <Typography className={styles.title}>
          Profile Completeness: {Progress}%
        </Typography>
        <ProgressBar value={Progress} />
        {/* <Typography className={styles.sectionText}>
          Complete profile fields
        </Typography> */}
      </div>

      {/* PROFILE */}
      <div className={styles.mobileSection}>
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>Profile</Typography>
          <Button
            variant="outlined"
            className={styles.titleButton}
            startIcon={<EditIcon style={{ fontSize: 14 }} />}
            onClick={() => handleClickItem("profile")}
          >
            EDIT
          </Button>
        </div>
        <div className={styles.sectionBody}>
          {profileData?.Photo_Link ? (
            <Avatar
              src={profileData?.Photo_Link}
              variant="square"
              className={styles.mdAvatar}
            />
          ) : (
            <Avatar variant="square" className={styles.mdAvatar}>
              Profile
              <br />
              Picture
            </Avatar>
          )}
          <div className={styles.sectionTextGroup}>
            <Typography className={styles.sectionText}>
              {profileData?.Full_Name}
            </Typography>
            <Typography className={styles.sectionText}>
              {profileData?.Email}
            </Typography>
            <Typography className={styles.sectionText}>
              {profileData?.Phone}
            </Typography>
          </div>
        </div>
      </div>

      {/* WORK EXPERIENCE */}
      <div className={styles.mobileSection}>
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>Work Experience</Typography>
          <Button
            disabled={workData && workData.length > 1}
            variant="outlined"
            className={styles.titleButton}
            startIcon={<AddCircleIcon style={{ fontSize: 14 }} />}
            onClick={() => handleClickItem("experience")}
          >
            ADD
          </Button>
        </div>
        <div className={styles.sectionBody}>
          {workData &&
            workData.length > 0 &&
            workData.map((item, i) => (
              <div
                key={i}
                className={styles.row}
                style={{ justifyContent: "space-between", margin: "10px 0" }}
              >
                <div>
                  <Typography className={styles.sectionText}>
                    <b>{item?.Company}</b> - {item["Occupation_/_Title"]}
                  </Typography>
                  <div className={styles.sectionTextGroup}>
                    <Typography
                      className={styles.sectionText}
                      style={{ margin: "10px 0" }}
                    >
                      <img
                        src={Images.clock_icon}
                        style={{ marginRight: "15px" }}
                      />
                      {moment(item?.Work_Duration?.from)
                        .locale("en")
                        .format("MMMM yyyy")}
                      {item?.I_currently_work_here
                        ? " - Currently work here"
                        : " - " +
                          moment(item?.Work_Duration?.to)
                            .locale("en")
                            .format("MMMM yyyy")}
                    </Typography>
                    <Typography className={styles.sectionText}>
                      <img
                        src={Images.file_icon}
                        style={{ marginRight: "15px" }}
                      />
                      {item?.Summary || "-"}
                    </Typography>
                  </div>
                </div>
                <IconButton
                  disableRipple
                  onClick={() => handleClickItem("experience", item)}
                >
                  <EditIcon />
                </IconButton>
              </div>
            ))}
        </div>
      </div>

      {/* EDUCATION */}
      <div className={styles.mobileSection}>
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>Education</Typography>
          <Button
            variant="outlined"
            className={styles.titleButton}
            startIcon={<AddCircleIcon style={{ fontSize: 14 }} />}
            onClick={() => handleClickItem("education")}
          >
            ADD
          </Button>
        </div>
        <div className={styles.sectionBody}>
          {educationData &&
            educationData.length > 0 &&
            educationData.map((item, i) => (
              <div
                key={i}
                className={styles.row}
                style={{ justifyContent: "space-between", margin: "10px 0" }}
              >
                <div>
                  <Typography className={styles.sectionText}>
                    <b>{item["Institute_/_School"]}</b> - {item?.Degree}
                  </Typography>
                  <div className={styles.sectionTextGroup}>
                    <Typography
                      className={styles.sectionText}
                      style={{ margin: "10px 0" }}
                    >
                      <HistoryOutlined
                        className={styles.itemIcon}
                        style={{ marginRight: 15 }}
                      />
                      {moment(item?.Duration?.from)
                        .locale("en")
                        .format("MMMM yyyy")}
                      {item?.Currently_pursuing
                        ? " - Currently Pursuing"
                        : " - " +
                          moment(item?.Duration?.to)
                            .locale("en")
                            .format("MMMM yyyy")}
                    </Typography>
                    <Typography className={styles.sectionText}>
                      <ImportContacts
                        className={styles.itemIcon}
                        style={{ marginRight: 15 }}
                      />
                      {item["Major_/_Department"] || "-"}
                    </Typography>
                  </div>
                </div>

                <IconButton
                  disableRipple
                  onClick={() => handleClickItem("education", item)}
                >
                  <EditIcon />
                </IconButton>
              </div>
            ))}
        </div>
      </div>

      {/* CV / PORTFOLIO */}
      <div className={styles.mobileSection}>
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>CV / Portfolio</Typography>
          <Button
            variant="outlined"
            className={styles.titleButton}
            startIcon={<EditIcon style={{ fontSize: 14 }} />}
            onClick={() => handleClickItem("portfolio")}
          >
            EDIT
          </Button>
        </div>
        <div className={styles.sectionBody}>
          {portfolioData ? (
            <Paper elevation="3" className={styles.cvPaper}>
              <img src={Images.pdfIcon} />
              <div style={{ margin: "0 20px" }}>
                <Typography
                  style={{ fontWeight: 600, fontSize: 14, lineHeight: 1 }}
                >
                  {portfolioData?.filename}
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: 1,
                    color: Colors.light_gray,
                  }}
                >
                  {Math.round(portfolioData?.size / 1e6)} MB
                </Typography>
              </div>
            </Paper>
          ) : (
            <Typography className={styles.itemText}>
              No Data Available
            </Typography>
          )}
        </div>
      </div>

      {/* LANGUAGES */}
      <div className={styles.mobileSection}>
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>Languages</Typography>
          <Button
            variant="outlined"
            className={styles.titleButton}
            startIcon={<EditIcon style={{ fontSize: 14 }} />}
            onClick={() => handleClickItem("language")}
          >
            EDIT
          </Button>
        </div>
        <div className={styles.sectionBody}>
          <div className={styles.sectionTextGroup}>
            {languageData && languageData.length > 0 ? (
              languageData.map((item, i) => (
                <Typography key={i} className={styles.sectionText}>
                  {item}
                </Typography>
              ))
            ) : (
              <Typography>Language is Empty</Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 20,
      borderRadius: 10,
      margin: 10,
    },
    bar: {
      borderRadius: 10,
      backgroundColor: "#CC0000",
    },
  }))(LinearProgress);

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.content}>
        {md ? (
          <div className={styles.mainbox}>{renderMobileContent()}</div>
        ) : (
          <>
            <div className={styles.sidebar}>
              <Paper className={styles.paper}>
                {menus.map((item, i) => (
                  <Button
                    key={i}
                    onClick={() => handleClickMenu(item)}
                    className={clsx(
                      styles.sidebarBtn,
                      activeMenu == item && styles.activeMenu
                    )}
                  >
                    {item}
                  </Button>
                ))}
              </Paper>
              <br />
              <Paper className={styles.paper}>
                <Typography
                  align="center"
                  style={{
                    fontWeight: `bold`,
                    fontSize: 18,
                    lineHeight: 2,
                    marginTop: 30,
                  }}
                >
                  Profile Completeness: {Progress}%
                </Typography>
                <BorderLinearProgress variant="determinate" value={Progress} />
                {/* <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: 17,
                    lineHeight: 2,
                    marginBottom: 30,
                    margin: 10,
                  }}
                >
                  Complete profile fields
                </Typography> */}
              </Paper>
            </div>
            <Paper className={clsx(styles.paper, styles.mainbox)}>
              {renderContent()}
            </Paper>
          </>
        )}
      </div>
      {md && renderMobileDialog()}
      {/* <FAB /> */}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  users_reducer: state.users_reducer,
  auth_reducer: state.auth_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getWorkExperience: () => dispatch(getExperience()),
  getEducation: () => dispatch(getEducations()),
  getLanguage: () => dispatch(getLanguages()),
  getPortfolio: () => dispatch(getPortfolio()),
  getProfileProgress: () => dispatch(getProfileProgress()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
