import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";

export const doForgotPassword = (email) => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_FORGOT_PASSWORD });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/auth`;
    const response = await client.post(Api.forgot_password, { email });
    const message = response?.data?.meta?.message;

    if (response?.status === 200) {
      dispatch({
        type: ActionType.REQUEST_FORGOT_PASSWORD_SUCCESS,
        payload: { message: "Please check your email" },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_FORGOT_PASSWORD_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    if (response?.status == 401) {
      dispatch({
        type: ActionType.REQUEST_FORGOT_PASSWORD_FAILED,
        payload: { message: "Email is not registered" },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_FORGOT_PASSWORD_FAILED,
        payload: { message: "Failed Request Forgot Password" },
      });
    }
  }
};
