import { ActionType } from "../utils/constan";

const initialState = {
  loading: false,
  success: false,
  error: false,
  message: "",
  data: {},
};

export const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.RESET_REGISTER_STATE:
      return {
        loading: false,
        success: false,
        error: false,
        message: "",
        data: {},
      };
    case ActionType.REQUEST_REGISTER:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
      };
    case ActionType.REQUEST_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        data: [],
        message: action.payload.message,
      };

    default:
      return state;
  }
};
