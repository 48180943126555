import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";

import { FormikProvider, FieldArray } from "formik";

import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import useStyles from "../SetupProfileStyles";

const listDegree = [
  "High School",
  "Diploma (D3)",
  "Bachelor (S1)",
  "Master (S2)",
  "Doctor (S3)",
];

const EducationStepScreen = ({ formik }) => {
  const styles = useStyles();

  const handleDateChange = (e, index, val) => {
    const newDate = formik.values.formDate;
    const startDate = moment(
      `${newDate[index].start_year}-${
        newDate[index].start_month
      }-${moment().date()}`,
      "yyyy-M-D",
      true
    );
    let value = moment(val);
    switch (e) {
      case "start_date":
        newDate[index]["start_month"] = value.month() + 1;
        newDate[index]["start_year"] = value.year();
        newDate[index]["end_month"] = "";
        newDate[index]["end_year"] = "";
        break;
      case "end_date":
        if (value.isSameOrAfter(startDate)) {
          newDate[index]["end_month"] = value.month() + 1;
          newDate[index]["end_year"] = value.year();
        } else {
          window.alert("Please input valid end date");
          newDate[index]["end_month"] = "";
          newDate[index]["end_year"] = "";
        }
        break;
    }
    formik.setFieldValue("formDate", newDate);
  };

  useEffect(() => {
    formik.setFieldTouched("educations");
    // onLoad();
  }, []);

  return (
    <Paper className={styles.signupBox}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Typography
          className={styles.signupTitle}
          style={{ textAlign: "center" }}
        >
          Please Provide your Most Recent and Relevant Education
        </Typography>
        <FormikProvider value={formik}>
          <form
            style={{ width: "100%", marginTop: "47px" }}
            onSubmit={formik.handleSubmit}
          >
            <FieldArray
              name="educations"
              render={(arrayHelpers) => (
                <>
                  {formik.values.educations.map((education, index) => (
                    <Grid container spacing={3} key={index}>
                      <Grid item xs={12}>
                        <Typography>University / School</Typography>
                        <TextField
                          fullWidth
                          placeholder="University / School"
                          variant="outlined"
                          name={`educations.${index}.school`}
                          value={formik.values.educations[index]?.school}
                          onChange={formik.handleChange}
                          onBlur={() =>
                            formik.setFieldTouched(`educations.${index}.school`)
                          }
                          inputProps={{
                            className: styles.inputText,
                          }}
                        />
                        {formik.errors?.educations?.length &&
                        formik.errors?.educations[index]?.school ? (
                          <span className={styles.errorMessage}>
                            {formik.errors?.educations[index]?.school}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Degree</Typography>
                        {formik.errors?.educations?.length &&
                        formik.errors?.educations[index]?.degree ? (
                          <span
                            className={styles.errorMessage}
                            style={{ marginTop: "5px" }}
                          >
                            {formik.errors?.educations[index]?.degree}
                          </span>
                        ) : (
                          <></>
                        )}
                        <FormControl
                          variant="outlined"
                          className={styles.selectInput}
                        >
                          <Select
                            name={`educations.${index}.degree`}
                            value={formik.values.educations[index]?.degree}
                            onChange={formik.handleChange}
                          >
                            {listDegree.map((degree, index) => {
                              return (
                                <MenuItem value={degree} key={index}>
                                  {degree}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Major</Typography>
                        <TextField
                          fullWidth
                          placeholder="Major"
                          variant="outlined"
                          name={`educations.${index}.major`}
                          value={formik.values.educations[index]?.major}
                          onChange={formik.handleChange}
                          onBlur={() =>
                            formik.setFieldTouched(`educations.${index}.major`)
                          }
                          inputProps={{
                            className: styles.inputText,
                          }}
                        />
                        {formik.errors?.educations?.length &&
                        formik.errors?.educations[index]?.major ? (
                          <span className={styles.errorMessage}>
                            {formik.errors?.educations[index]?.major}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Start Date</Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <DatePicker
                              autoOk
                              maxDate={moment()}
                              minDate={moment("1970-01-01")}
                              variant="dialog"
                              id={`start_month.${index}`}
                              placeholder="Start Date"
                              disabled={formik.isSubmitting}
                              value={
                                formik.values?.formDate[index]?.start_month
                                  ? moment(
                                      `${formik.values?.formDate[index]?.start_year}-${formik.values?.formDate[index]?.start_month}-01`,
                                      "yyyy-M-D",
                                      true
                                    )
                                  : null
                              }
                              format="MM/yyyy"
                              views={["year", "month"]}
                              onChange={(val) =>
                                handleDateChange("start_date", index, val)
                              }
                              TextFieldComponent={(props) => (
                                <OutlinedInput
                                  {...props}
                                  style={{ marginRight: 10 }}
                                  className={styles.input}
                                />
                              )}
                            />
                            {formik.errors?.formDate?.length &&
                            formik.errors?.formDate[index]?.start_month ? (
                              <span className={styles.errorMessage}>
                                <br />
                                {formik.errors?.formDate[index]?.start_month}
                              </span>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>End Date</Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <DatePicker
                              autoOk
                              maxDate={moment()}
                              minDate={moment(
                                `${
                                  formik.values?.formDate[index]?.start_month <
                                  12
                                    ? formik.values?.formDate[index]?.start_year
                                    : formik.values?.formDate[index]
                                        ?.start_year + 1
                                }-${
                                  formik.values?.formDate[index]?.start_month <
                                  12
                                    ? formik.values?.formDate[index]
                                        ?.start_month + 1
                                    : 1
                                }-01`,
                                "yyyy-M-D",
                                true
                              )}
                              variant="dialog"
                              id={`end_month.${index}`}
                              placeholder="End Date"
                              disabled={
                                formik.values?.educations[index][
                                  "is_currently_persuing"
                                ] || formik.isSubmitting
                              }
                              required={
                                !formik.values?.educations[index][
                                  "is_currently_persuing"
                                ]
                              }
                              value={
                                formik.values?.formDate[index]?.end_month
                                  ? moment(
                                      `${formik.values?.formDate[index]?.end_year}-${formik.values?.formDate[index]?.end_month}-01`,
                                      "yyyy-M-D",
                                      true
                                    )
                                  : null
                              }
                              format="MM/yyyy"
                              views={["year", "month"]}
                              onChange={(val) =>
                                handleDateChange("end_date", index, val)
                              }
                              TextFieldComponent={(props) => (
                                <OutlinedInput
                                  {...props}
                                  style={{ marginRight: 10 }}
                                  className={styles.input}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <div className={styles.checkbox}>
                          <Checkbox
                            size="medium"
                            color="primary"
                            name={`educations.${index}.is_currently_persuing`}
                            checked={
                              formik.values.educations[index]
                                ?.is_currently_persuing || false
                            }
                            defaultValue={false}
                            onChange={formik.handleChange}
                          />
                          <Typography>I currently study here</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {formik.values.educations.length - 1 === index ? (
                          <Button
                            className={styles.addButton}
                            onClick={() => {
                              if (formik.values.educations.length >= 1) {
                                let newFormatDate = [...formik.values.formDate];
                                newFormatDate.push({
                                  start_month: "",
                                  start_year: "",
                                  end_month: "",
                                  end_year: "",
                                });
                                formik.setFieldValue("formDate", newFormatDate);
                              }
                              arrayHelpers.push({});
                            }}
                          >
                            <AddCircleRoundedIcon
                              className={styles.iconButton}
                            />
                            Add Education
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {(formik.values.educations.length > 1 &&
                          formik.values.canRemove) ||
                        (!formik.values.canRemove &&
                          index >= formik.values.initialDataLength) ? (
                          <Button
                            className={styles.removeButton}
                            onClick={() => {
                              if (index < 1) {
                                let newFormatDate = [...formik.values.formDate];
                                newFormatDate[index] = {
                                  start_month: "",
                                  start_year: "",
                                  end_month: "",
                                  end_year: "",
                                };
                                formik.setFieldValue("formDate", newFormatDate);

                                let newEdu = [...formik.values.educations];
                                newEdu[index] = {
                                  school: "",
                                  degree: "",
                                  major: "",
                                  is_currently_persuing: "",
                                };
                                formik.setFieldValue("educations", newEdu);
                              } else {
                                let newFormatDate = [...formik.values.formDate];
                                newFormatDate.pop();
                                formik.setFieldValue("formDate", newFormatDate);
                                arrayHelpers.remove(index);
                              }
                            }}
                          >
                            <RemoveCircleRoundedIcon
                              className={styles.iconButton}
                            />{" "}
                            Remove Education
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            ></FieldArray>
          </form>
        </FormikProvider>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default EducationStepScreen;
