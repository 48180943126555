import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";

import useStyles from "./PrivacyPolicyStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const PrivacyPolicyScreen = () => {
  const styles = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.contentContainer}>
        <Paper className={styles.contentBox}>
          <Typography
            className={styles.contentTitle}
            style={{ marginBottom: "49px" }}
          >
            Privacy Policy - Jobseeker
          </Typography>
          <div className={styles.contentWrapper}>
            <Typography className={styles.sectionTitle}>
              Linardi Associates
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates prioritizes your right to privacy and our
              treatment of your personal information is important. The
              statements below describe how we will use and protect your data.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Personal Information - Definition
            </Typography>
            <Typography className={styles.contentText}>
              Personal information is any data belonging to a living individual
              or from those data and other information which is in the
              possession of.
            </Typography>
            <Typography className={styles.contentText}>
              It may range from the very sensitive (e.g. medical history or
              condition) to the everyday (e.g. address and phone number). It
              would include the opinions of others about your work performance
              (whether true or not), your work experience and qualifications
              obtained by Linardi Associates in connection with your possible
              work placements.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Who will be collecting your Personal Information?
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates is a part of PT Qerja Manfaat Bangsa, a startup
              providing end-to-end recruitment solutions for companies and job
              seekers. Linardi Associates provides professional recruitment
              solutions for various functions, industries, and levels,
              specializing in Managers and up to C Levels.
            </Typography>
            <Typography className={styles.contentText}>
              We specialize in placing candidates on a permanent, contract and
              interim basis in accountancy & finance, banking, operations,
              legal, tech & transformation, sales & marketing, human resources,
              support & administration in various industries.
            </Typography>
            <Typography className={styles.contentText}>
              Therefore, Linardi Associates operates as:
              <ol>
                <li>
                  An agency when recruiting for permanent positions or
                  fixed-term assignments direct with Linardi Associates’ client;
                  and
                </li>
                <li>
                  An employment business when recruiting for temporary
                  assignments where services will be supplied to Linardi
                  Associates&lsquo; clients via Linardi Associates.
                </li>
              </ol>
              Your personal information will be collected by Linardi Associates
              to provide you with recruitment and/or related intermediary
              services.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates may collect your personal information on behalf
              of PT Qerja Manfaat Bangsa, our main business group, in connection
              with the provision of recruitment and/or related intermediary
              services to or for you.
            </Typography>
            <Typography className={styles.sectionTitle}>
              How will your Personal Information be collected?
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates will collect your personal information directly
              from you. It will collect data when you:
            </Typography>
            <Typography className={styles.contentText}>
              Deal with Linardi Associates in person, by telephone, letter,
              email or via our website; when you complete your profile and
              attach your resume; fill out and submit a registration form;
              subscribe to our job alerts emails; submit any other information
              in connection with your application for registration; complete
              assessments.
            </Typography>
            <Typography className={styles.contentText}>
              Personal information about you may also be collected from third
              parties that Linardi Associates contacts for verification or
              background check purposes, for example any reference from previous
              employers, performance feedback (whether positive or negative),
              any complaint from or about you in the workplace, or any
              information that is relevant to the recruitment process.
            </Typography>
            <Typography className={styles.contentText}>
              For the purpose of the recruitment process, Linardi Associates
              will be requesting for your personal information (reference or
              background check) to third parties with or without your consent.
            </Typography>
            <Typography className={styles.sectionTitle}>
              How Linardi Associates may use and disclose your personal
              information
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates will use your personal information for the
              matter of providing and facilitating recruitment services,
              assessing your suitability for employment, applying jobs on behalf
              of you by giving your data to clients, giving you relevant job
              recommendations based on your profile and preferences, to improve
              our product and services, to answer your enquiries and questions,
              and any other purposes that are relevant with our main services.
            </Typography>
            <Typography className={styles.contentText}>
              If Linardi Associates enters into a joint venture with, is sold to
              or merged with another business entity, your personal information
              may be disclosed to Linardi Associates’ new, or potential,
              business partners or owners. By providing Linardi Associates with
              your
            </Typography>
            <Typography className={styles.contentText}>
              personal information, you consent to Linardi Associates disclosing
              your data to its new, or potential, business partners or owners if
              one of these situations arises.
            </Typography>
            <Typography className={styles.contentText}>
              Unless required or permitted to do so by law, Linardi Associates
              will not otherwise share, sell or distribute any of your personal
              information without your consent.
            </Typography>
            <Typography className={styles.sectionTitle}>
              If you do not give Linardi Associates the data it seeks
            </Typography>
            <Typography className={styles.contentText}>
              If you do not give Linardi Associates the personal information it
              seeks (provided that the data sought is reasonable and relevant to
              the services being provided to or for you), it is unable to
              provide you with recruitment and/or related intermediary services.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Security of your personal information
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates holds your personal information in a
              combination of secure computer storage facilities.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates has secure security procedures in place to
              protect the personal information it holds from misuse, loss,
              unauthorised access, modification or disclosure.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates will keep your personal information as long as
              needed.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Keeping your personal information up to date
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates takes reasonable steps to ensure that your
              personal information is accurate, complete and up to date.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates may also contact you from time to time to check
              that the data is still valid. However, please update your profile
              frequently should there be any changes to your details as soon as
              you reasonably can in order for Linardi Associates to maintain the
              accuracy of information held on you.
            </Typography>
            <Typography className={styles.contentText}>
              You may request to unsubscribe from job alerts and marketing
              material at any time. If you wish to contact us in this regard,
              please e-mail us at: cs@linardiassociates.com
            </Typography>
            <Typography className={styles.sectionTitle}>Cookies</Typography>
            <Typography className={styles.contentText}>
              A cookie is a simple text file that is stored on your computer or
              mobile device by a website’s server. Each cookie is unique to your
              web browser. It will contain some anonymous information, such as a
              unique identifier and the site name.
            </Typography>
            <Typography className={styles.contentText}>
              Cookies make the interaction between you and the website faster
              and easier. If a website doesn’t use cookies, it will think you
              are a new visitor every time you move to a new page on the site –
              for example, when you enter your login details and move to another
              page it won’t recognise you and it won’t be able to keep you
              logged in.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates also use ‘analytical’ cookies to help us
              improve the way our website works, for example, by making sure
              users are able to find what they need easily.
            </Typography>
            <Typography className={styles.contentText}>
              You can find out more about the use of cookies on
              http://www.allaboutcookies.org/
            </Typography>
            <Typography className={styles.sectionTitle}>
              Other Websites
            </Typography>
            <Typography className={styles.contentText}>
              Clicking on links and banner advertisements and RSS feeds may
              result in your transfer to another website, where data privacy
              practices may be different to that of Linardi Associates. It is
              your responsibility to familiarise yourself with these privacy
              policies as Linardi Associates accepts no responsibility for and
              has no control over them or any information or data collected by
              or for them.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Changes to Privacy Policy
            </Typography>
            <Typography className={styles.contentText}>
              This privacy policy may be altered or updated by Linardi
              Associates at any time, in which case Linardi Associates will
              advise you of any such alterations or updates by notice on the
              Linardi Associates website.
            </Typography>
            <Typography className={styles.sectionTitle}>
              How to contact us:
            </Typography>
            <Typography className={styles.contentText}>
              If you wish to correct your personal information or make a
              complaint about a breach of your privacy, please contact:
            </Typography>
            <Typography className={styles.contentText}>
              <span className={styles.boldText}>Tel: +62-21-5020-1201</span>
            </Typography>
            <Typography className={styles.contentText}>
              <span className={styles.boldText}>E-mail:</span>
              &nbsp;cs@linardiassociates.com
            </Typography>
          </div>
          <Typography
            className={styles.contentTitle}
            style={{ marginBottom: "49px", marginTop: "49px" }}
          >
            Privacy Policy - Company
          </Typography>
          <div className={styles.contentWrapper}>
            <Typography className={styles.sectionTitle}>
              Linardi Associates
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates prioritizes your right to privacy and our
              treatment of your personal information is important. The
              statements below describe how we will use and protect your data.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Personal Information - Definition
            </Typography>
            <Typography className={styles.contentText}>
              Personal information is any data belonging to a living individual
              or from that data and other information which is in the possession
              of, including but not limited to, full name, phone number, email
              address.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Who will be collecting your Personal Information?
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates is a part of PT Qerja Manfaat Bangsa, a startup
              providing end-to-end recruitment solutions for companies and job
              seekers. Linardi Associates provides professional recruitment
              solutions for various functions, industries, and levels,
              specializing in Managers and up to C Levels.
            </Typography>
            <Typography className={styles.contentText}>
              We specialize in placing candidates on a permanent, contract and
              interim basis in accountancy & finance, banking, operations,
              legal, tech & transformation, sales & marketing, human resources,
              support & administration in various industries.
            </Typography>
            <Typography className={styles.contentText}>
              Therefore, Linardi Associates operates as:
              <ol>
                <li>
                  An agency when recruiting for permanent positions or
                  fixed-term assignments direct with Linardi Associates’ client;
                  and
                </li>
                <li>
                  An employment business when recruiting for temporary
                  assignments where services will be supplied to Linardi
                  Associates&lsquo; clients via Linardi Associates.
                </li>
              </ol>
              Your personal information will be collected by Linardi Associates
              to provide you with professional recruitment services.
            </Typography>
            <Typography className={styles.sectionTitle}>
              How will your Personal Information be collected?
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates will collect your personal information
              including but not limited to, our networks and databases. Linardi
              Associates will also be able to collect information from third
              parties.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates will also be able to collect your personal
              information from relevant documents needed for the recruitment
              process, including but not limited to, MOUs, NDAs, TNCs, and any
              other relevant documents that need to be signed.
            </Typography>
            <Typography className={styles.sectionTitle}>
              How Linardi Associates may use and disclose your personal
              information
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates will not be sharing your personal information
              to third parties without your consent. All of the personal
              information given will be used for recruitment purposes only.
            </Typography>
            <Typography className={styles.sectionTitle}>
              How Linardi Associates will be displaying your company information
              on website
            </Typography>
            <Typography className={styles.contentText}>
              We will display your company information on our website based on
              your preference (whether it is a recruitment for a confidential
              company or not). This should be based on company’ needs and
              requirements.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Security of your personal information
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates holds your personal information in a
              combination of secure computer storage facilities.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates has secure security procedures in place to
              protect the personal information it holds from misuse, loss,
              unauthorised access, modification or disclosure.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates will keep your personal information as long as
              needed.
            </Typography>
            <Typography className={styles.sectionTitle}>Cookies</Typography>
            <Typography className={styles.contentText}>
              A cookie is a simple text file that is stored on your computer or
              mobile device by a website’s server. Each cookie is unique to your
              web browser. It will contain some anonymous information, such as a
              unique identifier and the site name.
            </Typography>
            <Typography className={styles.contentText}>
              Cookies make the interaction between you and the website faster
              and easier. If a website doesn’t use cookies, it will think you
              are a new visitor every time you move to a new page on the site –
              for example, when you enter your login details and move to another
              page it won’t recognise you and it won’t be able to keep you
              logged in.
            </Typography>
            <Typography className={styles.contentText}>
              Linardi Associates also use ‘analytical’ cookies to help us
              improve the way our website works, for example, by making sure
              users are able to find what they need easily.
            </Typography>
            <Typography className={styles.contentText}>
              You can find out more about the use of cookies on
              http://www.allaboutcookies.org/
            </Typography>
            <Typography className={styles.sectionTitle}>
              Other Websites
            </Typography>
            <Typography className={styles.contentText}>
              Clicking on links and banner advertisements and RSS feeds may
              result in your transfer to another website, where data privacy
              practices may be different to that of Linardi Associates. It is
              your responsibility to familiarise yourself with these privacy
              policies as Linardi Associates accepts no responsibility for and
              has no control over them or any information or data collected by
              or for them.
            </Typography>
            <Typography className={styles.sectionTitle}>
              Changes to Privacy Policy
            </Typography>
            <Typography className={styles.contentText}>
              This privacy policy may be altered or updated by Linardi
              Associates at any time, in which case Linardi Associates will
              advise you of any such alterations or updates by notice on the
              Linardi Associates website.
            </Typography>
            <Typography className={styles.sectionTitle}>
              How to contact us:
            </Typography>
            <Typography className={styles.contentText}>
              If you wish to correct your personal information or make a
              complaint about a breach of your privacy, please contact:
            </Typography>
            <Typography className={styles.contentText}>
              <span className={styles.boldText}>Tel: +62-21-5020-1201</span>
            </Typography>
            <Typography className={styles.contentText}>
              <span className={styles.boldText}>E-mail:</span>
              &nbsp;cs@linardiassociates.com
            </Typography>
          </div>
        </Paper>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyScreen;
