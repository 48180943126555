import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  selectInput: {
    borderWidth: 2,
    borderRadius: 5,
    width: "100%",
    height: "44px",
  },
  inquiryContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "35px",
    marginBottom: "36px",
    padding: "0 72px",
    [theme.breakpoints.down("md")]: {
      padding: "0 23px",
    },
  },
  inquiryBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: "100%",
    height: "auto",
    padding: "57px 24% 72px 24%",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      padding: "39px 23px",
      height: "auto",
    },
  },
  inquiryTitle: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "19px",
    color: "#23201D",
  },
  inputText: {
    height: "44px",
    padding: "0 30px",
    fontStyle: "italic",
  },
  inputError: {
    border: "1px solid red",
    color: "red",
    height: "44px",
    padding: "0 30px",
  },
  errorMessage: {
    color: "red",
  },
  textArea: {
    height: "200px",
    padding: "0 5px",
    fontStyle: "italic",
  },
  inquiryButton: {
    marginTop: "69px",
    width: "138px",
    background: "#1875D1",
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: "16px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
  },
}));

export default useStyles;
