import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    width: "31%",
    // maxWidth: "415px",
    // minWidth: "336px",
    minWidth: location.pathname == "/savedjobs" ? "90vw" : "336px",
    // height: "288px",
    height: "fit-content",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    boxSizing: "border-box",
    margin: "18px 12px",
    padding: "28px",
  },
  rootFullwidth: {
    width: "100% !important",
    maxWidth: "inherit !important",
  },
  cardContent: {
    padding: "0 !important",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: "31px",
  },
  footer: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: "58px",
    height: "58px",
    marginRight: "21px",
  },
  jobHeader: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
  jobTitle: {
    color: "#23201D",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "17px",
    margin: 0,
    marginBottom: "5px",
    display: "-webkit-box !important",
    WebkitLineClamp: 2,
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    WebkitBoxOrient: "vertical",
  },
  company: {
    color: "#1875D1",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "15px",
    margin: 0,
  },
  footnote: {
    color: "#808080",
    fontSize: "15px",
    fontWeight: "400",
    margin: 0,
  },
  contentItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
    fontSize: "16px",
    lineHeight: "15px",
    fontWeight: "400",
    color: "#23201D",
  },
  contentIcon: {
    marginRight: "21px",
    width: "21px",
  },
  favoriteButton: {
    minWidth: "auto !important",
    width: "37px",
    height: "37px",
    border: "1px solid rgba(179, 179, 179, 0.25)",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: 0,
  },
  unFavoriteButton: {
    minWidth: "auto !important",
    backgroundColor: "#1875D1",
    width: "37px",
    height: "37px",
    border: "1px solid rgba(179, 179, 179, 0.25)",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: 0,
  },
});

export default useStyles;
