import { ActionType } from "../utils/constan";

const initialState = {
  loading: true,
  success: false,
  message: "",
  data: [],
};

export const parametersReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get Parameter
    case ActionType.REQUEST_PARAMETERS:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_PARAMETERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_PARAMETERS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        data: [],
        message: action.payload.message,
      };

    default:
      return state;
  }
};
