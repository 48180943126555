/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import CrossTabSync from "./utils/persistListener";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { doLogout, autoLogin } from "./actions/AuthActions";

const refreshToken =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const refresh_token = action.payload?.refresh_token;
    const status = action.payload?.status;
    if (status === 401) {
      dispatch(doLogout());
      const url = window.location.href;
      const arrayURL = url.split("/");
      if (arrayURL.includes("profile")) {
        window.location.replace(window.location.origin + "/signin");
      } else {
        window.location.reload();
      }
    } else if (refresh_token) {
      dispatch(autoLogin(refresh_token));
    }
    next(action);
  };

const defaultState = {};
const middleware = [thunk, refreshToken];

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth_reducer"],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  defaultState,
  composeEnhancers(applyMiddleware(...middleware))
);

if (module.hot) {
  module.hot.accept("./reducers", () => {
    const nextRootReducer = require("./reducers/index").default;
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}

const persistor = persistStore(store);
// CrossTabSync(store, persistConfig);

export { store, persistor };
