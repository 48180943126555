import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  OutlinedInput,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";
import moment from "moment";
import { useFormik } from "formik";
import Alert from "../../../../components/Alert";
import DefaultDialog from "../../../../components/DefaultDialog";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  addEducations,
  editEducations,
} from "../../../../actions/UsersActions";
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";

const template = {
  degree: "",
  school: "",
  major: "",
  is_currently_persuing: false,
};

const degreeOptions = [
  "High School",
  "Diploma (D3)",
  "Bachelor (S1)",
  "Master (S2)",
  "Doctor (S3)",
];

const msg = {
  successAdd: "Data successfully added",
  success: "Data successfully changed",
  error: "Failed to edit data, please try again",
};

const EducationForm = (props) => {
  const styles = useStyles();
  const [inputValues, setInputValues] = useState(template);
  const [selectedValues, setSelectedValues] = useState({
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
  });
  const [postSubmit, setPostSubmit] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState(msg.success);

  const formik = useFormik({
    initialValues: inputValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      let duration = {
        from: `${selectedValues["start_year"]}-${selectedValues["start_month"]}-01`,
        to: values["is_currently_persuing"]
          ? ""
          : `${selectedValues["end_year"]}-${selectedValues["end_month"]}-01`,
      };
      let data = {
        educations: [
          {
            ...values,
            duration,
          },
        ],
      };

      console.log(data);
      if (postSubmit) {
        props?.addEducations(data);
      } else {
        props?.editEducations(data);
      }
    },
  });

  useEffect(() => {
    let loading = props.users_reducer.loading;
    let error = props.users_reducer.error;

    setAlertMessage(
      error ? msg.error : postSubmit ? msg.successAdd : msg.success
    );
    setIsError(error);

    if (!loading && formik.isSubmitting) {
      setOpenSnackbar(true);
      formik.setSubmitting(false);
      if (!error) handleClose();
    }
  }, [props.users_reducer.loading]);

  useEffect(() => {
    if (props?.data) {
      let data = {
        id: props?.data?.id,
        school: props?.data["Institute_/_School"],
        degree: props?.data?.Degree,
        major: props?.data["Major_/_Department"],
        is_currently_persuing: props?.data?.Currently_pursuing,
      };
      let fromValue = moment(props?.data?.Duration?.from);
      let toValue = moment(props?.data?.Duration?.to);
      const newDate = {
        start_year: fromValue ? fromValue.year() : null,
        start_month: fromValue ? fromValue.month() + 1 : null,
        end_year: toValue ? toValue.year() : null,
        end_month: toValue ? toValue.month() + 1 : null,
      };
      setInputValues(data);
      setSelectedValues(newDate);
      setPostSubmit(false);
    } else {
      setInputValues(template);
      setSelectedValues({
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      });
      setPostSubmit(true);
    }
  }, [props?.data]);

  const handleClose = () => {
    setInputValues(template);
    setSelectedValues({
      start_month: "",
      start_year: "",
      end_month: "",
      end_year: "",
    });
    setOpenSnackbar(false);
    formik.setSubmitting(false);
    formik.resetForm();
    props?.closeDialog(false);
  };

  const handleDateChange = (e, val) => {
    const newDate = selectedValues;
    const startDate = new Date(
      `${newDate.start_year}-${newDate.start_month}-${moment().date()}`
    );
    let value = moment(val);
    switch (e) {
      case "start_date":
        newDate["start_month"] = value.month() + 1;
        newDate["start_year"] = value.year();
        newDate["end_month"] = "";
        newDate["end_year"] = "";
        break;
      case "end_date":
        if (val > startDate) {
          newDate["end_month"] = value.month() + 1;
          newDate["end_year"] = value.year();
        } else {
          window.alert("Please input valid end date");
          newDate["end_month"] = "";
          newDate["end_year"] = "";
        }
        break;
    }
    setSelectedValues({ ...newDate });
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DefaultDialog md={true} isOpen={props?.dialogOpen} onClose={handleClose}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          {postSubmit ? "Add" : "Edit"} Education
        </Typography>
        <form onSubmit={formik.handleSubmit} style={{ padding: 8 }}>
          <div className={styles.formGroup}>
            <InputLabel htmlFor="school" className={styles.inputLabel}>
              University / School
            </InputLabel>
            <OutlinedInput
              disabled={formik.isSubmitting}
              className={styles.input}
              id="school"
              placeholder="University / School"
              value={formik.values["school"]}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <InputLabel htmlFor="degree" className={styles.inputLabel}>
              Degree
            </InputLabel>
            <Autocomplete
              disabled={formik.isSubmitting}
              id="degree"
              options={degreeOptions}
              getOptionLabel={(option) => option}
              style={{ marginRight: 10, flex: 1 }}
              value={formik.values["degree"]}
              onChange={(_, event) => formik.setFieldValue("degree", event)}
              renderInput={(params) => (
                <OutlinedInput
                  {...params}
                  ref={params.InputProps.ref}
                  className={styles.input}
                  placeholder="Degree"
                />
              )}
            />
          </div>
          <div className={styles.formGroup}>
            <InputLabel htmlFor="major" className={styles.inputLabel}>
              Major
            </InputLabel>
            <OutlinedInput
              disabled={formik.isSubmitting}
              className={styles.input}
              id="major"
              placeholder="Major"
              value={formik.values["major"]}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <InputLabel className={styles.inputLabel}>Start Date</InputLabel>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <DatePicker
                autoOk
                minDate={new Date("1970-01-01")}
                maxDate={new Date()}
                variant="dialog"
                id="start_month"
                placeholder="Month"
                disabled={formik.isSubmitting}
                value={
                  selectedValues["start_month"]
                    ? new Date(
                        `${selectedValues["start_year"]}-${selectedValues["start_month"]}-01`
                      )
                    : null
                }
                format="MM/yyyy"
                required
                views={["year", "month"]}
                onChange={(val) => handleDateChange("start_date", val)}
                TextFieldComponent={(props) => (
                  <OutlinedInput
                    {...props}
                    style={{ marginRight: 10 }}
                    className={styles.input}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <InputLabel className={styles.inputLabel}>End Date</InputLabel>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <DatePicker
                autoOk
                maxDate={new Date()}
                minDate={
                  new Date(
                    `${
                      selectedValues["start_month"] < 12
                        ? selectedValues["start_year"]
                        : selectedValues["start_year"] + 1
                    }-${
                      selectedValues["start_month"] < 12
                        ? selectedValues["start_month"] + 1
                        : 1
                    }-01`
                  )
                }
                variant="dialog"
                id="end_month"
                placeholder="Month"
                disabled={
                  formik.values["is_currently_persuing"] || formik.isSubmitting
                }
                required={!formik.values["is_currently_persuing"]}
                value={
                  selectedValues["end_month"]
                    ? new Date(
                        `${selectedValues["end_year"]}-${selectedValues["end_month"]}-01`
                      )
                    : null
                }
                format="MM/yyyy"
                views={["year", "month"]}
                onChange={(val) => handleDateChange("end_date", val)}
                TextFieldComponent={(props) => (
                  <OutlinedInput
                    {...props}
                    style={{ marginRight: 10 }}
                    className={styles.input}
                  />
                )}
              />
            </div>
            <FormControlLabel
              disabled={formik.isSubmitting}
              control={
                <Checkbox
                  checked={formik.values["is_currently_persuing"]}
                  onChange={() =>
                    formik.setFieldValue(
                      "is_currently_persuing",
                      !formik.values["is_currently_persuing"]
                    )
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="I currently study here"
            />
          </div>
          {/* </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "20px 0",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              disabled={formik.isSubmitting}
              onClick={handleClose}
              style={{ marginRight: 20, width: "fit-content" }}
              className={clsx(styles.btn, styles.grayBtn)}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              style={{ lineHeight: "18px", width: "fit-content" }}
              className={clsx(styles.btn, styles.blueBtn)}
            >
              SAVE CHANGES
            </Button>
          </div>
        </form>

        <Alert
          message={alertMessage}
          isOpen={openSnackbar}
          isError={isError}
          onClose={onCloseSnackBar}
        />
      </DefaultDialog>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  users_reducer: state.users_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  addEducations: (body) => dispatch(addEducations(body)),
  editEducations: (body) => dispatch(editEducations(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EducationForm);
