import { ActionType } from "../utils/constan";

const initialState = {
  loading: true,
  success: false,
  message: "",
};

export const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_RESET_PASSWORD:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
      };
    case ActionType.REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        message: action.payload.message,
      };
    case ActionType.REQUEST_RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
