import { connect } from "react-redux";
import Screen from "./HomeScreen";
import { getParameters } from "../../actions/ParameterActions";
import { getIndustryPrefs } from "../../actions/JobMasterActions";
import { getPopularJobs } from "../../actions/PopularActions";

const mapStateToProps = (state) => ({
  parameters: state.parameters,
  job_master: state.job_master,
  popular_jobs: state.popular_jobs,
});

const mapDispatchToProps = (dispatch) => ({
  getParameters: (page = 1, page_size = 1000) =>
    dispatch(getParameters(page, page_size)),
  getIndustryPrefs: () => dispatch(getIndustryPrefs()),
  getPopularJobs: (page = 1, page_size = 6) =>
    dispatch(getPopularJobs(page, page_size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
