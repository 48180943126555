import { connect } from "react-redux";
import Screen from "./SignupScreen";
import { register } from "../../actions/RegisterActions";
import { doLinkedinLogin } from "../../actions/AuthActions";
import { ActionType } from "../../utils/constan";

const mapStateToProps = (state) => ({
  register_reducer: state.register_reducer,
  auth_reducer: state.auth_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  register: (data) => dispatch(register(data)),
  doLinkedinLogin: (code) => dispatch(doLinkedinLogin(code)),
  resetState: () =>
    dispatch({
      type: ActionType.RESET_REGISTER_STATE,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
