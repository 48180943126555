import React, { useEffect, useState } from "react";
import { useMediaQuery, Typography, Link } from "@material-ui/core";
import { useHistory } from "react-router";
// import FAB from "../../components/FAB";
import useStyles from "./JobStyles";
import JobSearch from "../../components/JobSearch";
import BrowseJob from "../../components/BrowseJob";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import clsx from "clsx";

const JobScreen = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const md = useMediaQuery("(max-width:768px)");
  const [industries, setIndustries] = useState(null);

  useEffect(() => {
    if (props.job_master.industry_prefs.length < 1) {
      props.getIndustryPrefs();
    }
  }, []);

  useEffect(() => {
    if (props.job_master.industry_prefs.length > 0) {
      let data = props.job_master.industry_prefs.filter(
        (item) => item.display_value !== "-None-"
      );
      setIndustries(data);
    }
  }, [props.job_master]);

  const handleClick = (item) => {
    history.push({
      pathname: "/search",
      state: {
        industry: item?.display_value,
      },
    });
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div
        className={clsx(styles.bgContainer, md ? styles.bgContainerMd : null)}
      >
        <Typography variant="h5" className={styles.searchTitle}>
          Find your dream job immediately
        </Typography>
        <JobSearch />
        {!props.job_master.loading &&
          props.job_master.success &&
          industries && (
            <BrowseJob
              title={"Browse Jobs By Industry"}
              items={industries.slice(0, 14)}
            />
          )}
      </div>
      <div className={styles.listContainer}>
        <Typography variant="h5" className={styles.containerTitle}>
          Jobs By Industry
        </Typography>
        <ul className={styles.listWrapper}>
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("A")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>A</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("A"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("B")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>B</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("B"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("C")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>C</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("C"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("D")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>D</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("D"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("E")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>E</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("E"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("F")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>F</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("F"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("G")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>G</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("G"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("H")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>H</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("H"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}

          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("I")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>I</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("I"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("J")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>J</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("J"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("K")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>K</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("K"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("L")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>L</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("L"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("M")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>M</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("M"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("N")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>N</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("N"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("O")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>O</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("O"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("P")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>P</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("P"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("Q")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>Q</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("Q"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("R")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>R</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("R"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("S")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>S</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("S"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("T")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>T</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("T"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("U")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>U</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("U"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("V")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>V</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("V"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("W")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>W</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("W"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("X")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>X</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("X"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("Y")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>Y</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("Y"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
          {industries &&
          industries.filter((industry) =>
            industry.display_value.startsWith("Z")
          ).length > 0 ? (
            <Typography className={styles.listLinkTitle}>Z</Typography>
          ) : null}
          {!props.job_master.loading &&
            props.job_master.success &&
            industries &&
            industries
              .filter((industry) => industry.display_value.startsWith("Z"))
              .map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    onClick={() => handleClick(item)}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))}
        </ul>
      </div>
      {/* <FAB /> */}
      <Footer />
    </div>
  );
};

export default JobScreen;
