import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";

import useStyles from "./TermConditionStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const TermConditionScreen = () => {
  const styles = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.contentContainer}>
        <Paper className={styles.contentBox}>
          <Typography
            className={styles.contentTitle}
            style={{ marginBottom: "49px" }}
          >
            Terms and Conditions - Jobseeker
          </Typography>
          <div className={styles.contentWrapper}>
            <Typography className={styles.contentText}>
              This page states the terms of use (“terms”) under which you
              (“you”) may use the linardiassociates.com website, and your
              relationship with linardiassociates.com (“Linardi Associates”,
              “we” or “us”). Please read them carefully as they affect your
              rights and liabilities under the law. If you do not agree to these
              terms, please do not register for or use the linardiassociates.com
              website.
              <ol>
                <li className={styles.boldText}>Definitions</li>
                <Typography className={styles.contentText}>
                  <ol>
                    <li>
                      In these Terms and Conditions, the following terms shall
                      have the respective meanings specified below unless the
                      context otherwise requires:
                    </li>
                    <ol>
                      <li>
                        &quot;Linardi Associates&quot; means Linardi Associates.
                      </li>
                      <li>
                        &quot;Job seeker(s)&quot; means users seeking
                        employment.
                      </li>
                      <li>
                        &quot;Website&quot; means any website, mobile
                        application owned and operated by Linardi Associates and
                        its affiliates/subsidiaries or its service providers.
                      </li>
                    </ol>
                  </ol>
                </Typography>
                <li className={styles.boldText}>Website Usage</li>
                <Typography className={styles.contentText}>
                  <ol>
                    <li>
                      Job seekers agree not to include any incorrect
                      information, misleading, harassing, arousing hatred,
                      slandering, discriminating against ethnicity, religion,
                      race, or between groups (SARA).
                    </li>
                    <li>
                      Job seekers agree to keep their username and password with
                      care and not to disclose them to any other person. Job
                      seeker is solely responsible for maintaining the
                      confidentiality, safekeeping and security of such username
                      and password and shall notify Linardi Associates
                      immediately of any unauthorized use of the same.
                    </li>
                    <li>
                      All of the recruitment processes provided by Linardi
                      Associates are completely free of charge.
                    </li>
                    <li>
                      By submitting all of the personal information required in
                      the website, job seekers acknowledge that their
                      information will be disclosed to Linardi Associates, the
                      company, and relevant third parties.
                    </li>
                    <li>
                      Job seekers are the only party who is fully responsible
                      for the information included in the resume or profile.
                    </li>
                    <li>
                      Job seekers acknowledge and agree that their resume and
                      profile will be used and displayed to the company for
                      workforce recruitment purposes.
                    </li>
                    <li>
                      Job seekers agree not to sue Linardi Associates and / or
                      all of its employees for any losses incurred as a result
                      of using Linardi Associates services along with all
                      related links.
                    </li>
                    <li>
                      Job seekers agree to other terms and conditions that will
                      be added by Linardi Associates from time to time without
                      prior notification to job seekers.
                    </li>
                    <li>
                      Job seekers are not permitted to use any form of
                      information obtained from Linardi Associates for purposes
                      that violate laws, copyright laws and intellectual rights.
                      Violations of this provision can be brought to court by
                      Linardi Associates with or without other parties being
                      harmed.
                    </li>
                    <li>
                      Job seekers are prohibited from violating or attempting to
                      violate the security of the website.{" "}
                    </li>
                    <li>
                      Linardi Associates reserves the right to edit resumes,
                      block accounts, and refuse to provide services to job
                      seekers who are deemed to violate the Terms and Conditions
                      and Linardi Associates policies, which interpret Linardi
                      Associates‘s full rights. Linardi Associates‘s decision in
                      this matter is final and cannot be contested.
                    </li>
                  </ol>
                </Typography>
                <li className={styles.boldText}>
                  Intellectual Property Rights
                </li>
                <Typography className={styles.contentText}>
                  All of the contents displayed on the website (from photos,
                  graphics, images, layout, designs, pictures, logos, editorial
                  content, HTML and other proprietary materials) are the
                  intellectual property of Linardi Associates or its licensors
                  and are protected by copyright, trademark, patent and other
                  intellectual property laws.
                </Typography>
                <li className={styles.boldText}>Responsibilities</li>
                <Typography className={styles.contentText}>
                  Linardi Associates does not provide any guarantee regarding
                  services, sites, smooth system accessibility, security from
                  technological threats, the accuracy of information obtained
                  from external parties (including job seekers), as well as the
                  success rate of recruitment to anyone, either implied or
                  express.
                </Typography>
                <li className={styles.boldText}>Risks</li>
                <Typography className={styles.contentText}>
                  If job seekers use our website and any other websites accessed
                  through it, it will be entirely at their own risk. All job
                  seekers shall be responsible for their own communications and
                  are responsible for the consequences of their activities on
                  the website. The website may contain links to third-party
                  websites. Linardi Associates has no control over or rights in
                  such third-party websites and is not responsible for their
                  availability or security. If job seekers access any linked
                  third-party websites, they do so entirely at their own risk.
                </Typography>
                <li className={styles.boldText}>Indemnity</li>
                <Typography className={styles.contentText}>
                  Job seekers agree to not ask for compensation from Linardi
                  Associates and its subsidiaries, for any loss, damage, costs,
                  and expenses.
                </Typography>
                <li className={styles.boldText}>Termination</li>
                <Typography className={styles.contentText}>
                  In the event that any information provided by the job seeker
                  is inaccurate, in breach of any laws or contains indecent
                  elements, Linardi Associates shall have the right to terminate
                  the accounts.
                </Typography>
                <li className={styles.boldText}>Force Majeure</li>
                <Typography className={styles.contentText}>
                  In the case of Linardi Associates experiencing force majeure,
                  such as but not limited to:
                </Typography>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    earthquakes, hurricanes, floods, landslides, lightning
                    strikes, fires, explosions, falling celestial bodies, and
                    other disasters;
                  </li>
                  <li>
                    war, riot, terrorism, genocide, rebellion, sabotage,
                    embargo, general strike;
                  </li>
                  <li>
                    disruption of electricity, telecommunications lines, the
                    spread of disease / pandemic outbreaks;
                  </li>
                </ul>
                <Typography className={styles.contentText}>
                  Job seekers are required to free Linardi Associates from all
                  forms of service demands, and Linardi Associates has the right
                  to stop providing / distributing the service to job seekers
                  until the time limit determined by Linardi Associates
                  management.
                </Typography>
              </ol>
            </Typography>
          </div>
          <Typography
            className={styles.contentTitle}
            style={{ marginBottom: "49px", marginTop: "49px" }}
          >
            Terms and Conditions - Company
          </Typography>
          <div className={styles.contentWrapper}>
            <Typography className={styles.contentText}>
              This page states the terms of use (“terms”) under which company
              (“company”) may use the linardiassociates.com website, and your
              relationship with linardiassociates.com (“Linardi Associates”,
              “we” or “us”). Please read them carefully as they affect your
              rights and liabilities under the law. If you do not agree to these
              terms, please do not register for or use the linardiassociates.com
              website.
              <ol>
                <li className={styles.boldText}>Definitions</li>
                <Typography className={styles.contentText}>
                  <ol>
                    <li>
                      In these Terms and Conditions, the following terms shall
                      have the respective meanings specified below unless the
                      context otherwise requires:
                    </li>
                    <ol>
                      <li>
                        &quot;Linardi Associates&quot; means Linardi Associates.
                      </li>
                      <li>
                        &quot;Company(s)&quot; means a company seeking a
                        recruitment service.
                      </li>
                      <li>
                        &quot;Job seeker(s)&quot; means users seeking
                        employment.
                      </li>
                      <li>
                        &quot;Website&quot; means any website, mobile
                        application owned and operated by Linardi Associates and
                        its affiliates/subsidiaries or its service providers.
                      </li>
                    </ol>
                  </ol>
                </Typography>
                <li className={styles.boldText}>
                  Company data and information
                </li>
                <Typography className={styles.contentText}>
                  <ol>
                    <li>
                      The company is obliged to properly inform the real
                      identity of the company for the purpose of account
                      registration and advertising by Linardi Associates. All
                      forms of fraud or removal of the company‘s identity as a
                      user will be followed up by Linardi Associates.
                    </li>
                    <li>
                      Companies are required to send photocopies of SIUP, NPWP,
                      SKDP / SKDU, TDP, SITU, UD, and other business
                      establishments permit documents to the official sales
                      e-mail of Linardi Associates.
                    </li>
                    <li>
                      The company agrees to coincide and comply with other
                      provisions that have been and may be added by Linardi
                      Associates from time to time without prior notification to
                      the Company.
                    </li>
                  </ol>
                </Typography>
                <li className={styles.boldText}>Prohibitions</li>
                <Typography className={styles.contentText}>
                  <ol>
                    <li>
                      The company is not permitted to use any form of
                      intellectual property (including but not limited to name,
                      logo and technology) belonging to Linardi Associates for
                      any purpose and for any reason.
                    </li>
                    <li>
                      Companies are not allowed to charge a dime from job
                      seekers who want to be recruited through Linardi
                      Associates; both before and after the interview process is
                      carried out; and for any reason, whether for
                      transportation costs, consumption, training, uniforms, or
                      other things.
                    </li>
                    <li>
                      Companies are not allowed to commit any form of criminal
                      crime, including but not limited to cyber crimes, either
                      directly or indirectly, through Linardi Associates.The
                      company is obliged to provide compensation and release
                      officers, directors, employees, employees, business
                      owners, agents, partners, representatives, shareholders,
                      associated companies, lawyers, predecessors, successors,
                      and transfer recipients of Linardi Associates from and
                      against any claims, legal actions, claims, injuries,
                      liability, losses, damages, costs and expenses (including
                      legal fees and litigation expenses on a full indemnity
                      basis) arising out of or related to the use of the service
                      (including but not limited to the site and the system
                      contained in it) or the content or violation of these
                      Terms and Conditions by the Company.
                    </li>
                  </ol>
                </Typography>
                <li className={styles.boldText}>
                  Claims Exclusion and Limitation of Liability against Linardi
                  Associates
                </li>
                <Typography className={styles.contentText}>
                  <ol>
                    <li>
                      Linardi Associates does not provide any guarantee
                      regarding services, sites, smooth system accessibility,
                      security from technological threats, the accuracy of
                      information obtained from external parties (including
                      employers and job seekers), as well as the success rate of
                      recruitment to anyone, either implied or express.
                    </li>
                    <li>
                      The company acknowledges and agrees that Linardi
                      Associates only provides a professional recruitment
                      service. Linardi Associates does not have complete control
                      over Users, and therefore Linardi Associates is completely
                      not responsible for the accuracy of the information
                      obtained from these parties.
                    </li>
                    <li>
                      The company is the only party that is fully responsible
                      for all information posted on behalf of the company on
                      Linardi Associates.
                    </li>
                    <li>
                      By using Linardi Associates‘s services, the Company is
                      deemed to have accepted and agreed to all the terms and
                      conditions set by Linardi Associates with full awareness.
                    </li>
                  </ol>
                </Typography>
                <li className={styles.boldText}>Violations</li>
                <Typography className={styles.contentText}>
                  <ol>
                    <li>
                      Linardi Associates reserves the right to refuse and / or
                      stop providing services to the Company, and suspend
                      advertisements that are deemed to violate these Terms and
                      Conditions.
                    </li>
                    <li>
                      Interpretation of violations of the Terms and Conditions
                      by the Company fully belongs to Linardi Associates and
                      cannot be contested.
                    </li>
                    <li>
                      Linardi Associates has the right not to refund payment or
                      credit fees if the Company is proven to have violated the
                      Terms and Conditions set by Linardi Associates.
                    </li>
                    <li>
                      Violations committed by the Company can lead to civil or
                      criminal liability by the company concerned. Linardi
                      Associates reserves the right to carry out investigations
                      or be involved in investigating cases and cooperate with
                      law enforcement authorities if there are indications of
                      something detrimental to Linardi Associates from the
                      violation.
                    </li>
                  </ol>
                </Typography>
                <li className={styles.boldText}>Force Majeure</li>
                <Typography className={styles.contentText}>
                  In the case of Linardi Associates experiencing force majeure,
                  such as but not limited to:
                </Typography>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    earthquakes, hurricanes, floods, landslides, lightning
                    strikes, fires, explosions, falling celestial bodies, and
                    other disasters;
                  </li>
                  <li>
                    war, riot, terrorism, genocide, rebellion, sabotage,
                    embargo, general strike;
                  </li>
                  <li>
                    disruption of electricity, telecommunications lines, the
                    spread of disease / pandemic outbreaks;
                  </li>
                </ul>
                <Typography className={styles.contentText}>
                  Companies are required to free Linardi Associates from all
                  forms of service demands, and Linardi Associates has the right
                  to stop providing / distributing the service to the company
                  until the time limit determined by Linardi Associates
                  management.
                </Typography>
              </ol>
            </Typography>
          </div>
        </Paper>
      </div>
      <Footer />
    </div>
  );
};

export default TermConditionScreen;
