import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  navbar: {
    // backgroundColor: "#ffffff",
    height: "109px",
    padding: "0 73px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      padding: "0 16px !important",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  logo: {
    width: "86.06px",
    height: "43px",
    background: "url('/assets/images/Qareer logo.png')",
  },
  menu: {
    display: "flex",
    flexDirection: "row",
    flex: "0.8",
  },
  link: {
    color: "#808080",
    fontWeight: "500",
    textDecoration: "none",
    margin: "0 30px",
    "@media (max-width: 1009px)": {
      margin: "0 18px !important",
    },
  },
  linkMd: {
    color: "white",
  },
  linkActive: {
    color: "#DE1A1B",
    fontWeight: "500",
    textDecoration: "none",
    margin: "0 30px",
    paddingBottom: "8px",
    borderBottom: "3px solid #DE1A1B",
  },
  authBox: {},
  signInButton: {
    fontSize: "16px",
    fontWeight: "500",
    // "&:hover": {
    //   backgroundColor: "#DE1A1B",
    //   // color: "#FFFFFF",
    // },
  },
  signUpButton: {
    backgroundColor: "#DE1A1B",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "500",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    width: "89px",
    height: "44px",
    marginLeft: "39px",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#1875D1",
    },
  },
  profileBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profileBoxMd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  userIcon: {
    color: "black",
    fontSize: 42,
    "@media (max-width: 768px)": {
      color: "white",
      fontSize: 50,
    },
  },
  userBtn: {
    fontSize: 16,
    color: "black",
    fontWeight: "600",
    marginLeft: 15,
    marginRight: 15,
    "@media (max-width: 768px)": {
      color: "white",
    },
  },
  dropdown: {
    color: "gray",
    fontSize: 32,
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.short,
    }),
  },
  dropdownOpen: {
    transform: "rotate(-180deg)",
  },
  dropdownClosed: {
    transform: "rotate(0)",
  },
  popover: {
    position: "absolute",
  },
  popoverWrapper: {
    margin: "10px 25px",
    display: "flex",
    flexDirection: "column",
  },
  popoverLink: {
    fontSize: 16,
    color: "black",
    fontWeight: "400",
    marginTop: 10,
    marginBottom: 10,
  },
  menuMdContainer: {
    width: "50vw",
    display: "flex",
    flexDirection: "column",
  },
  menuButton: {
    color: Colors.light_gray,
  },
  rootDrawer: {
    backgroundColor: "#DE1A1B !important",
  },
  iconButton: {
    margin: "20px 30px",
    alignSelf: "flex-end",
  },
  closeIcon: {
    fontSize: 42,
    color: "white",
  },
  listItemText: {
    color: "white",
  },
  divider: {
    borderWidth: 1,
    backgroundColor: "white",
    margin: "20px 15px",
  },
  hidden: {
    visibillity: "hidden",
  },
  skipForm: {
    color: Colors.light_gray,
    pointer: "cursor",
  },
}));

export default useStyles;
