import { connect } from "react-redux";
import Screen from "./SetupProfileScreen";
import { autoLogin, doLinkedinLogin } from "../../../actions/AuthActions";
import {
  editProfile,
  getProfile,
  editExperience,
  getExperience,
  editEducations,
  getEducations,
} from "../../../actions/UsersActions";

const mapStateToProps = (state) => ({
  auth_reducer: state.auth_reducer,
  users_reducer: state.users_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  autoLogin: (token) => dispatch(autoLogin(token)),
  getProfile: () => dispatch(getProfile()),
  editProfile: (data) => dispatch(editProfile(data)),
  getExperience: () => dispatch(getExperience()),
  editExperience: (data) => dispatch(editExperience(data)),
  editEducations: (data) => dispatch(editEducations(data)),
  getEducations: () => dispatch(getEducations()),
  doLinkedinLogin: (code) => dispatch(doLinkedinLogin(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
