import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  dialogContainer: {
    padding: "20px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  btn: {
    width: 200,
    height: 44,
    fontSize: 16,
    fontWeight: "bold",
  },
  blueBtn: {
    color: "white",
    backgroundColor: Colors.blue,
    "&:hover": {
      backgroundColor: Colors.blue,
    },
  },
  grayBtn: {
    color: Colors.light_gray,
    borderWidth: 2,
  },
});

export default useStyles;
