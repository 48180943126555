import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    backgroundColor: Colors.lighter_gray,
  },
  bgContainer: {
    display: "flex",
    flexDirection: "column",
    background: "url('/assets/images/img_bg.png') no-repeat center",
    backgroundSize: "cover",
    height: "350px",
    padding: "0 73px",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      padding: "0 20px !important",
    },
  },
  bgContainerMd: {
    height: 500,
  },
  contentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "50px 73px",
    "@media (max-width: 768px)": {
      padding: "20px",
    },
  },
  containerTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "21px",
    lineHeight: "20px",
  },
  sortWrapper: {
    textTransform: "none",
    "@media (max-width: 768px)": {
      padding: "0",
    },
  },
  sortButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  searchTitle: {
    color: "white",
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
  },
  filterContainer: {
    paddingBottom: 20,
  },
  dropdownWrapper: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  dropdownFilter: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
  },
  dropdownTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 15,
    marginTop: 20,
    flexWrap: "wrap",
  },
  chipWrapper: {
    margin: "0 20px",
  },
  chip: {
    borderRadius: 25,
    backgroundColor: Colors.blue,
    fontSize: 16,
    color: "white",
    padding: "25px 10px",
    margin: "5px",
    "&:focus": {
      backgroundColor: Colors.blue,
    },
  },
  jobsContainerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 12px",
    marginBottom: "14px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
  },
  jobList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      justifyContent: "center",
    },
  },
  listWrapper: {
    columnCount: 3,
    columnGap: 20,
    "@media (max-width: 768px)": {
      columnCount: "unset",
      columnGap: 0,
    },
  },
  listItem: {
    color: Colors.blue,
    fontSize: 18,
  },
  listLink: {
    color: Colors.blue,
  },

  paginationWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    padding: "20px 20px 20px 15px",
    gap: "16px",
  },
  paginationBtnPrev: {
    backgroundColor: "white",
    alignSelf: "center",
    color: "#1D62AE",
    fontWeight: "700",
    height: "45px",
    minWidth: "150px",
    borderRadius: "4px !important",
    "&:hover": {
      backgroundColor: "white",
    },
    "@media (max-width: 768px)": {
      height: 60,
    },
    zIndex: 987,
  },
  paginationBtnNext: {
    backgroundColor: "#1875D1",
    alignSelf: "center",
    color: "#FFFFFF",
    fontWeight: "700",
    fontSize: "16px",
    height: "45px",
    minWidth: "150px",
    borderRadius: "4px !important",
    "&:hover": {
      backgroundColor: "#1875D1",
    },
    "@media (max-width: 768px)": {
      height: 60,
    },
    zIndex: 989,
  },
  wrapperSA: {
    textAlign: "center",
  },
  seeAll: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#DE1A1B",
    cursor: "pointer",
  },
  backToTop: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    position: "fixed",
    backgroundColor: "#3C3C3C",
    color: "white",
    width: "172px",
    "&:hover": {
      backgroundColor: "#3C3C3C",
    },
    zIndex: 999,
  },
  textMoreWrapper: {
    marginLeft: "auto",
  },
  textMoreJobListing: {
    color: "#000000",
    fontSize: "18px",
    fontWeight: "400",
  },
  nextButton: {
    color: "#1875D1",
    textDecoration: "underline",
    margin: "0 4px",
    cursor: "pointer",
  },
  listLinkTitle: {
    fontSize: "24px",
    fontWeight: "600",
    color: Colors.blue,
    marginTop: "16px",
  },
});

export default useStyles;
