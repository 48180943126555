import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Paper,
  IconButton,
  InputBase,
} from "@material-ui/core";
import {
  AddCircleRounded,
  Edit as EditIcon,
  CheckCircle as CheckIcon,
} from "@material-ui/icons";
import Alert from "../../../../components/Alert";
import useStyles from "./styles";
import { Images } from "../../../../themes";
import { connect } from "react-redux";
import { editLanguages, getLanguages } from "../../../../actions/UsersActions";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

const LanguagesContent = (props) => {
  const styles = useStyles();
  const [alertMessage, setAlertMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [languageData, setLanguageData] = useState(null);
  const [onAddValue, setOnAddValue] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    props.getLanguages();
  }, []);

  useEffect(() => {
    const _data = props.users_reducer.language;
    if (_data && _data.length > 0) {
      setLanguageData(_data);
    }
  }, [props.users_reducer.language]);

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const onChangeInput = (e) => {
    const value = e.target.value;
    if (value.match("^[A-Za-z ]+$")) setInputValue(value);
  };

  const onSubmitEdit = (i) => {
    if (inputValue) {
      let other_languages = languageData;
      other_languages[i] = inputValue;
      const body = {
        other_languages,
      };
      props.editLanguages(body);
    }
    setInputValue(null);
    setEditValue(null);
  };

  const onSubmitAdd = () => {
    if (inputValue) {
      let other_languages = languageData;
      if (other_languages == null) other_languages = [];
      other_languages.push(inputValue);
      const body = {
        other_languages,
      };
      props.editLanguages(body);
    }
    setInputValue(null);
    setOnAddValue(false);
  };

  const onDeleteValue = (i) => {
    let other_languages = languageData;
    other_languages.splice(i, 1);
    const body = {
      other_languages,
    };
    props.editLanguages(body);
    setDeleteIndex(null);
    if (other_languages && other_languages.length > 0) {
      setLanguageData(other_languages);
    } else {
      setLanguageData(null);
    }
    setIsError(false);
    setAlertMessage("Successfully been deleted");
    setOpenSnackbar(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <Typography variant="h4" className={styles.title}>
          Languages
        </Typography>
        <Button
          onClick={() => setOnAddValue(true)}
          variant="outlined"
          className={styles.addBtn}
        >
          <AddCircleRounded className={styles.addIcon} />
          ADD LANGUAGE
        </Button>
      </div>
      <div className={styles.content}>
        {languageData
          ? languageData.map((item, i) => {
              if (editValue == i) {
                return (
                  <div key={i} className={styles.langGroup}>
                    <Paper elevation={3} className={styles.langItem}>
                      <InputBase
                        autoFocus
                        className={styles.langText}
                        value={inputValue}
                        placeholder={item}
                        onChange={onChangeInput}
                        onKeyDown={(e) => {
                          if (e.keyCode == 13) onSubmitEdit(i);
                        }}
                      />
                      <CheckIcon
                        onClick={() => onSubmitEdit(i)}
                        className={styles.checkIcon}
                      />
                    </Paper>
                  </div>
                );
              } else {
                return (
                  <div key={i} className={styles.langGroup}>
                    <Paper elevation={3} className={styles.langItem}>
                      <Typography className={styles.langText}>
                        {item}
                      </Typography>
                      <EditIcon
                        onClick={() => setEditValue(i)}
                        className={styles.editIcon}
                      />
                    </Paper>
                    <IconButton
                      onClick={() => {
                        setDeleteIndex(i);
                        setDialogOpen(true);
                      }}
                      style={{ marginLeft: 20 }}
                    >
                      <img src={Images.trashIcon} />
                    </IconButton>
                  </div>
                );
              }
            })
          : !onAddValue && (
              <div>
                <p>Language is Empty</p>
              </div>
            )}
        {onAddValue && (
          <div className={styles.langGroup}>
            <Paper elevation={3} className={styles.langItem}>
              <InputBase
                autoFocus
                className={styles.langText}
                value={inputValue}
                placeholder="Languages"
                onChange={onChangeInput}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) onSubmitAdd();
                }}
              />
              <CheckIcon
                onClick={() => onSubmitAdd()}
                className={styles.checkIcon}
              />
            </Paper>
            <IconButton
              onClick={() => setOnAddValue(false)}
              style={{ marginLeft: 20 }}
            >
              <img src={Images.trashIcon} />
            </IconButton>
          </div>
        )}
      </div>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setDeleteIndex(null);
        }}
        onConfirm={() => {
          onDeleteValue(deleteIndex);
          setDialogOpen(false);
        }}
      />
      <Alert
        message={alertMessage}
        isOpen={openSnackbar}
        isError={isError}
        onClose={onCloseSnackBar}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  users_reducer: state.users_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLanguages: () => dispatch(getLanguages()),
  editLanguages: (body) => dispatch(editLanguages(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesContent);
