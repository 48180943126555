import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import { Images } from "../../themes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TextCarousel = ({ items, className }) => {
  const styles = useStyles();

  const NextArrow = ({ className, onClick }) => {
    return (
      <div className={className} style={{ display: "block" }} onClick={onClick}>
        <img src={Images.nextArrow} />
      </div>
    );
  };
  const PrevArrow = ({ className, onClick }) => {
    return (
      <div className={className} style={{ display: "block" }} onClick={onClick}>
        <img src={Images.prevArrow} />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    focusOnSelect: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <Slider className={className} {...settings}>
      {items.map((item, i) => {
        const data = item.value.split(" | ");
        return (
          <div className={styles.itemImageContainer} key={i}>
            <div className={styles.itemContainer} key={i}>
              {data[1] && <img height="auto" width="120px" src={data[1]} />}
              <Typography variant="h5" className={styles.itemTitle}>
                &quot;{data[0]}&quot;
              </Typography>
              <Typography className={styles.itemSubtitle}>
                {item.name}
              </Typography>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default TextCarousel;
