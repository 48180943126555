import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const authHOC = (DestinationScreen) => {
  class AuthMiddleware extends React.Component {
    state = {
      isWaiting: true,
      isLogin: false,
    };

    static getDerivedStateFromProps(props) {
      return { isLogin: props.isLoggedIn, isWaiting: false };
    }

    render() {
      if (this.state.isWaiting) {
        return null;
      } else if (!this.state.isLogin) {
        return <Redirect to={{ pathname: "/" }} />;
      }
      return <DestinationScreen />;
    }
  }

  const mapStateToProps = (state) => ({
    isLoggedIn: state.auth_reducer.isLoggedIn,
  });

  return connect(mapStateToProps)(AuthMiddleware);
};

export default authHOC;

/*
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkCookieValue } from "../utils/cookieConfig";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!checkCookieValue()) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
*/
