import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  btn: {
    width: 200,
    height: 150,
  },
  title: {
    color: "white",
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
    width: "max-content",
  },
  dropdown: {
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.short,
    }),
  },
  dropdownOpen: {
    transform: "rotate(-180deg)",
  },
  dropdownClosed: {
    transform: "rotate(0)",
  },
  popover: {
    position: "absolute",
    width: "100%",
    // maxHeight: 270,
    zIndex: 999,
  },
  popoverMd: {
    // maxHeight: 1000,
  },
  popoverLink: {
    margin: "10px 0",
    color: "black",
    width: 380,
    "&:hover, &:focus": {
      color: Colors.red,
    },
  },
  moreHover: {
    margin: "10px 0",
    color: Colors.blue,
    width: 380,
    "&:hover, &:focus": {
      fontWeight: "bold",
    },
  },
}));

export default useStyles;
