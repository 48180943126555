import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";

export const getPopularJobs =
  (page, page_size) => async (dispatch, getState) => {
    const state = getState().auth_reducer;
    dispatch({ type: ActionType.REQUEST_POPULAR });
    let params = {
      page,
      page_size,
    };
    try {
      const headers = state.token
        ? {
            Authorization: `Bearer ${state.token}`,
          }
        : {};
      client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
      const response = await client.get(Api.popular_job, {
        params: params,
        headers,
      });
      const message = response?.data?.meta?.message;
      const refresh_token = response?.data?.meta?.refresh_token;

      if (response?.status === 200) {
        let data = response?.data?.data;
        dispatch({
          type: ActionType.REQUEST_POPULAR_SUCCESS,
          payload: { data, message, refresh_token },
        });
      } else {
        dispatch({
          type: ActionType.REQUEST_POPULAR_FAILED,
          payload: { message },
        });
      }
    } catch (error) {
      const { response } = error;
      dispatch({
        type: ActionType.REQUEST_POPULAR_FAILED,
        payload: {
          message: "Failed Fetching Popular Jobs",
          status: response?.status,
        },
      });
    }
  };
