import { ActionType } from "../utils/constan";

const initialState = {
  loading: true,
  success: false,
  message: "",
  industry_prefs: [],
  locations: [],
  min_salary: [],
  work_exp: [],
  job_type: [],
};

export const jobMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get Industry Preference Parameter
    case ActionType.REQUEST_INDUSTRY_PREFS:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_INDUSTRY_PREFS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        industry_prefs: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_INDUSTRY_PREFS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        industry_prefs: [],
        message: action.payload.message,
      };

    // Get Job Type Parameter
    case ActionType.REQUEST_JOB_TYPE:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_JOB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        job_type: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_JOB_TYPE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        job_type: [],
        message: action.payload.message,
      };

    // Get Location Parameter
    case ActionType.REQUEST_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        locations: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_LOCATION_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        locations: [],
        message: action.payload.message,
      };

    // Get Minimum Salary Range Parameter
    case ActionType.REQUEST_MIN_SALARY:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_MIN_SALARY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        min_salary: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_MIN_SALARY_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        min_salary: [],
        message: action.payload.message,
      };

    // Get Work Experience Parameter
    case ActionType.REQUEST_WORK_EXP:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_WORK_EXP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        work_exp: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_WORK_EXP_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        work_exp: [],
        message: action.payload.message,
      };

    default:
      return state;
  }
};
