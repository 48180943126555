import { ActionType, Api } from "../utils/constan";
import client from "../utils/service";
import { ApiConfig } from "../utils/constan";

export const register = (data) => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_REGISTER });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/auth`;
    const response = await client.post(`${Api.register}`, data);
    const message = response?.data?.meta?.message;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_REGISTER_SUCCESS,
        payload: { data, message },
      });
    }
  } catch (error) {
    const { response } = error;
    if (response?.status === 409) {
      dispatch({
        type: ActionType.REQUEST_REGISTER_FAILED,
        payload: { message: "Email already registered" },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_REGISTER_FAILED,
        payload: { message: "Register Failed, Please try again" },
      });
    }
  }
};
