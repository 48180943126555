import React from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const MAlert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Alert = ({ message, isOpen, isError, onClose }) => {
  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={onClose}>
      {isError ? (
        <MAlert onClose={onClose} severity="error">
          {message}
        </MAlert>
      ) : (
        <MAlert onClose={onClose} severity="success">
          {message}
        </MAlert>
      )}
    </Snackbar>
  );
};

Alert.propTypes = {
  message: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  isError: false,
};

export default Alert;
