import { ActionType } from "../utils/constan";

const initialState = {
  loading: true,
  success: false,
  error: false,
  message: "",
  data: {},
};

export const jobDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_JOB_DETAIL:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ActionType.REQUEST_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_JOB_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        data: {},
        error: true,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
