import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dialogContainer: {
    padding: "65px 50px",
  },
  dialogContainerMd: {
    padding: "8px !important",
  },
});

export default useStyles;
