import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: 50,
  },
  addBtn: {
    height: 44,
    fontWeight: "bold",
    borderWidth: 2,
    borderRadius: 5,
    color: Colors.blue,
    borderColor: Colors.blue,
  },
  addIcon: {
    fontSize: 24,
    marginRight: 10,
    color: Colors.blue,
  },
  itemWrapper: {
    position: "relative",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 25,
    marginBottom: 50,
  },
  itemIcon: {
    marginRight: 5,
  },
  itemText: {
    display: "flex",
    alignItems: "center",
    margin: "3px 0",
  },
  itemTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  moreIcon: {
    color: Colors.light_gray,
  },

  dialogTitle: {
    fontSize: "25px !important",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 65,
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    margin: "10px auto",
    maxWidth: 630,
  },
  inputLabel: {
    fontSize: 18,
    margin: "10px 0",
    color: "black",
  },
  input: {
    borderWidth: 2,
    borderRadius: 5,
    flex: 1,
  },
  btn: {
    width: 200,
    height: 44,
    fontSize: 16,
    fontWeight: "bold",
  },
  blueBtn: {
    color: "white",
    backgroundColor: Colors.blue,
    "&:hover": {
      backgroundColor: Colors.blue,
    },
  },
  grayBtn: {
    color: Colors.light_gray,
    borderWidth: 2,
  },

  popover: {
    position: "absolute",
  },
  popoverWrapper: {
    margin: "10px 25px",
    display: "flex",
    flexDirection: "column",
  },
  popoverLink: {
    fontSize: 16,
    color: "black",
    fontWeight: "400",
    marginTop: 10,
    marginBottom: 10,
  },
});

export default useStyles;
