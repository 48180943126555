import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  jobsContainer: {
    padding: "0 72px",
    marginTop: "41px",
    marginBottom: "75px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      padding: "0 23px",
    },
  },

  mobileApplyBox: {
    display: "none",
    zIndex: 100,
    "@media (max-width: 768px)": {
      position: "fixed",
      bottom: "0",
      left: "0",
      width: "95%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "14px 22px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  jobActionWrapper: {
    marginBottom: "20px",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  applyButtonDesktop: {
    fontWeight: "600",
    height: "44px",
    color: "#ffffff",
    padding: "13px 39px",
    background: "#1875D1",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
  },
  savedButtonDesktop: {
    marginLeft: "25px",
    height: "44px",
    padding: "13px 28px",
    fontWeight: "600",
    color: "#1875D1",
    border: "1px solid #1875D1",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  unSavedButtonDesktop: {
    marginLeft: "25px",
    height: "44px",
    padding: "13px 28px",
    fontWeight: "600",
    color: "#ffffff",
    backgroundColor: "#1875D1",
    border: "1px solid #1875D1",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  applyButton: {
    background: "#1875D1",
    color: "#FFFFFF",
    fontWeight: "600",
    padding: "7px 27px",
  },
  favoriteButton: {
    minWidth: "auto !important",
    width: "37px",
    height: "37px",
    border: "1px solid rgba(179, 179, 179, 0.25)",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: 0,
  },
  unFavoriteButton: {
    minWidth: "auto !important",
    backgroundColor: "#1875D1",
    width: "37px",
    height: "37px",
    border: "1px solid rgba(179, 179, 179, 0.25)",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: 0,
  },
  modalWrapper: {
    padding: "20px 23px",
    textAlign: "center",
  },
  modalConfirmation: {
    display: "flex",
    flexDirection: "column",
    padding: "53px 22px",
    left: "9% !important",
    maxWidth: "75%",
    maxHeight: "462px",
    "@media (max-width: 768px)": {
      left: "1% !important",
      maxHeight: "400px",
      maxWidth: "100%",
    },
  },
  modalText: {
    fontSize: "18px",
  },
  modalTextBold: {
    fontWeight: "600",
  },
  closeButton: {
    float: "right",
    cursor: "pointer",
  },
  buttonWrapper: {
    marginTop: "35px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  blueButton: {
    width: "208px",
    height: "44px",
    background: "#1875D1",
    borderRadius: "5px",
    color: "#FFFFFF",
  },
  whiteButton: {
    marginTop: "30px",
    width: "208px",
    height: "44px",
    background: "#FFFFFF",
    borderRadius: "5px",
    color: "#1875D1",
    border: "1px solid #1875D1",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: "31px",
    marginLeft: "150px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  contentItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
    fontSize: "16px",
    lineHeight: "15px",
    fontWeight: "400",
    color: "#23201D",
  },
  contentIcon: {
    marginRight: "21px",
    width: "21px",
  },
  detailTitle: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "19px",
    color: "#23201D",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      color: "#23201D",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "19px",
    },
  },
  companyPhoto: {
    [theme.breakpoints.down("md")]: {
      width: "43px",
      height: "43px",
    },
  },
  companyName: {
    marginTop: "10px",
    color: "#1875D1",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      marginTop: "0",
    },
  },
  companyNamePosition: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "35px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  detailJobBox: {
    padding: "64px 70px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      padding: "22px",
    },
  },
  requiredSkills: {
    marginTop: "19px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  chip: {
    margin: "5px 5px",
    maxWidth: "100%",
  },
  wrapperDesc: {
    marginBottom: "10px",
  },
}));

export default useStyles;
