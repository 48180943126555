import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "35px",
    marginBottom: "36px",
    padding: "0 73px",
    [theme.breakpoints.down("md")]: {
      padding: "0 23px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: "100%",
    height: "auto",
    padding: "73px 73px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      padding: "12px 0",
      boxShadow: "none",
      height: "auto",
    },
  },
  contentTitle: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "19px",
    color: "#23201D",
  },
  sectionTitle: {
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "19px",
    color: "#23201D",
  },
  sectionSubTitle: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "19px",
    color: "#23201D",
  },
  contentWrapper: {
    width: "100%",
    textAlign: "left",
  },
  contentText: {
    fontSize: "18px",
    lineHeight: "35px",
    marginTop: "20px",
    textAlign: "justify",
  },
  journeyWrapper: {
    marginTop: "66px",
    display: "flex",
    flexDirection: "row",
  },
  journeyContent: {
    padding: "63px 37px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: Colors.red,
    color: "#ffffff",
  },
  aboutImage: {
    width: "30%",
  },
}));

export default useStyles;
