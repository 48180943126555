import React from "react";
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const CustomLinearProgress = withStyles(() => ({
  root: {
    height: 16,
    borderRadius: 10,
    margin: "5px 0",
  },
  colorPrimary: {
    backgroundColor: Colors.light_gray,
  },
  bar: {
    borderRadius: 10,
    backgroundColor: Colors.red,
  },
}))(LinearProgress);

const ProgressBar = (props) => {
  return <CustomLinearProgress value={props.value} variant="determinate" />;
};

export default ProgressBar;
