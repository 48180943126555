import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";
import jwt_decode from "jwt-decode";
import { deleteAllCookie, setCookieValue } from "../utils/cookieConfig";
import { persistor } from "../store";

export const doLogin = (body) => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_LOGIN });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/auth`;
    const response = await client.post(Api.login, body);
    const message = response?.data?.meta?.message;

    if (response?.status === 200) {
      let data = response?.data?.data;
      let user = jwt_decode(data?.token);
      setCookieValue(data?.token, user, user?.exp);
      dispatch({
        type: ActionType.REQUEST_LOGIN_SUCCESS,
        payload: { data, user, message },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_LOGIN_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.REQUEST_LOGIN_FAILED,
      payload: { message: "Login Failed" },
    });
  }
};

export const doLinkedinLogin = (code) => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_LOGIN });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/auth`;
    const response = await client.get(`${Api.linkedinCallback}?code=${code}`);
    const message = response?.data?.meta?.message;

    if (response?.status === 200) {
      let data = response?.data?.data;
      let user = jwt_decode(data?.token);
      setCookieValue(data?.token, user, user?.exp);
      dispatch({
        type: ActionType.REQUEST_LOGIN_SUCCESS,
        payload: { data, user, message },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_LOGIN_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.REQUEST_LOGIN_FAILED,
      payload: { message: "Login Failed" },
    });
  }
};

export const autoLogin = (token) => (dispatch) => {
  let data = {
    token,
  };
  let user = jwt_decode(token);
  const message = "Success Login";
  setCookieValue(token, user, user?.exp);
  dispatch({
    type: ActionType.REQUEST_LOGIN_SUCCESS,
    payload: { data, user, message },
  });
};

export const doLogout = () => (dispatch) => {
  dispatch({
    type: ActionType.REQUEST_LOGOUT,
  });
  deleteAllCookie();
  persistor.flush();
};
