import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  OutlinedInput,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import useStyles from "../SetupProfileStyles";
import moment from "moment";

const ProfileStepScreen = ({ formik }) => {
  const styles = useStyles();

  const [selectedDOB, setSelectedDOB] = useState(null);

  const handleDOB = (newValue) => {
    let value = moment(newValue);
    let dob = value.format("yyyy-MM-DD");
    console.log(dob, "handle change dob");
    formik.setFieldValue("Date_of_Birth", dob);
  };

  useEffect(() => {
    // onLoad();
  }, []);

  useEffect(() => {
    if (selectedDOB) {
      handleDOB(selectedDOB);
    }
  }, [selectedDOB]);

  useEffect(() => {
    if (formik?.values?.Date_of_Birth) {
      formik.setFieldTouched("Date_of_Birth");
      const dob = moment(formik?.values?.Date_of_Birth);
      console.log(dob, "init dob");
      setSelectedDOB(dob);
    }
  }, [formik.values.Date_of_Birth]);

  return (
    <Paper className={styles.signupBox}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Typography className={styles.signupTitle}>
          Please Provide your Information Here
        </Typography>
        <form
          style={{ width: "100%", marginTop: "47px" }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>First Name</Typography>
              <TextField
                fullWidth
                placeholder="First Name"
                variant="outlined"
                name="First_Name"
                onChange={formik.handleChange}
                value={formik.values["First_Name"]}
                onBlur={() => formik.setFieldTouched("First_Name")}
                inputProps={{
                  className:
                    formik.touched.First_Name && formik.errors.First_Name
                      ? styles.inputError
                      : styles.inputText,
                }}
              />
              {formik.touched.First_Name && formik.errors.First_Name ? (
                <span className={styles.errorMessage}>
                  {formik.errors.First_Name}
                </span>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Last Name</Typography>
              <TextField
                fullWidth
                placeholder="Last Name"
                variant="outlined"
                name="Last_Name"
                onChange={formik.handleChange}
                value={formik.values["Last_Name"]}
                onBlur={() => formik.setFieldTouched("Last_Name")}
                inputProps={{
                  className:
                    formik.touched.Last_Name && formik.errors.Last_Name
                      ? styles.inputError
                      : styles.inputText,
                }}
              />
              {formik.touched.Last_Name && formik.errors.Last_Name ? (
                <span className={styles.errorMessage}>
                  {formik.errors.Last_Name}
                </span>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>Email</Typography>
              <TextField
                fullWidth
                disabled={true}
                placeholder="Email Address"
                variant="outlined"
                name="Email"
                onChange={formik.handleChange}
                value={formik.values["Email"]}
                inputProps={{
                  className: styles.inputText,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Phone Number</Typography>
              <TextField
                fullWidth
                placeholder="Phone Number"
                variant="outlined"
                name="Phone"
                onChange={formik.handleChange}
                value={formik.values["Phone"]}
                onBlur={() => formik.setFieldTouched("Phone")}
                inputProps={{
                  className:
                    formik.touched.Phone && formik.errors.Phone
                      ? styles.inputError
                      : styles.inputText,
                }}
              />
              {formik.touched.Phone && formik.errors.Phone ? (
                <span className={styles.errorMessage}>
                  {formik.errors.Phone}
                </span>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>Address</Typography>
              <TextField
                fullWidth
                placeholder="Street Address"
                variant="outlined"
                name="Street"
                onChange={formik.handleChange}
                value={formik.values["Street"]}
                onBlur={() => formik.setFieldTouched("Street")}
                inputProps={{
                  className:
                    formik.touched.Street && formik.errors.Street
                      ? styles.inputError
                      : styles.inputText,
                }}
              />
              {formik.touched.Street && formik.errors.Street ? (
                <span className={styles.errorMessage}>
                  {formik.errors.Street}
                </span>
              ) : (
                <></>
              )}
              <Grid container spacing={3} style={{ marginTop: "1px" }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="City"
                    variant="outlined"
                    name="City"
                    onChange={formik.handleChange}
                    value={formik.values["City"]}
                    onBlur={() => formik.setFieldTouched("City")}
                    inputProps={{
                      className:
                        formik.touched.City && formik.errors.City
                          ? styles.inputError
                          : styles.inputText,
                    }}
                  />
                  {formik.touched.City && formik.errors.City ? (
                    <span className={styles.errorMessage}>
                      {formik.errors.City}
                    </span>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder="Country"
                    variant="outlined"
                    name="Country"
                    onChange={formik.handleChange}
                    value={formik.values["Country"]}
                    onBlur={() => formik.setFieldTouched("Country")}
                    inputProps={{
                      className:
                        formik.touched.Country && formik.errors.Country
                          ? styles.inputError
                          : styles.inputText,
                    }}
                  />
                  {formik.touched.Country && formik.errors.Country ? (
                    <span className={styles.errorMessage}>
                      {formik.errors.Country}
                    </span>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <Typography>Date of Birth</Typography>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <DatePicker
                    disabled={formik.isSubmitting}
                    autoOk
                    variant="dialog"
                    label="Date of Birth"
                    maxDate={moment().subtract(17, "years")}
                    minDate={new Date("1970-01-01")}
                    format="dd/MM/yyyy"
                    value={selectedDOB}
                    onChange={(newValue) => {
                      setSelectedDOB(newValue);
                    }}
                    TextFieldComponent={(props) => (
                      <OutlinedInput
                        {...props}
                        style={{ marginRight: 10 }}
                        className={styles.input}
                      />
                    )}
                  />
                </div>
              </div>
              {formik.errors.Date_of_Birth ? (
                <span className={styles.errorMessage}>
                  {formik.errors.Date_of_Birth}
                </span>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>Linkedin URL</Typography>
              <TextField
                fullWidth
                placeholder="Linkedin URL"
                variant="outlined"
                name="LinkedIn_URL"
                onChange={formik.handleChange}
                value={formik.values["LinkedIn_URL"]}
                onBlur={() => formik.setFieldTouched("LinkedIn_URL")}
                inputProps={{
                  className:
                    formik.touched.LinkedIn_URL && formik.errors.LinkedIn_URL
                      ? styles.inputError
                      : styles.inputText,
                }}
              />
              {formik.touched.LinkedIn_URL && formik.errors.LinkedIn_URL ? (
                <span className={styles.errorMessage}>
                  {formik.errors.LinkedIn_URL}
                </span>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default ProfileStepScreen;
