import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  signupContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "35px",
    marginBottom: "36px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 23px",
    },
  },
  signupBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: "859px",
    height: "auto",
    padding: "58px 111px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      padding: "12px 0",
      boxShadow: "none",
      height: "auto",
    },
  },
  emailConfirmationBox: {
    width: "859px",
    height: "324px",
    boxSizing: "border-box",
    padding: "62px 65px",
    fontSize: "16px",
    lineHeight: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "12px 0",
      boxShadow: "none",
      height: "auto",
    },
  },
  passwordNotice: {
    marginTop: "5px",
    fontSize: "15px",
    lineHeight: "30px",
    color: "#808080",
  },
  signupTitle: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "19px",
    color: "#23201D",
    textAlign: "center",
  },
  signupLinkedin: {
    marginTop: "69px",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #0A66C2",
    color: "#0A66C2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
    borderRadius: "5px",
    padding: "11px",
  },
  signupDivider: {
    margin: "40px 42px",
  },
  textDivider: {
    fontSize: "14px",
    lineHeight: "13px",
    color: "#808080",
  },
  inputText: {
    height: "44px",
    padding: "0 30px",
  },
  inputError: {
    border: "1px solid red",
    color: "red",
    height: "44px",
    padding: "0 30px",
  },
  signupAgreement: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "15px",
  },
  agreementLink: {
    color: "#1875D1",
    fontWeight: "600",
  },
  signupButton: {
    marginTop: "27px",
    width: "100%",
    background: "#1875D1",
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: "16px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
  },
  signinText: {
    marginTop: "50px",
  },
  errorMessage: {
    color: "red",
  },
}));

export default useStyles;
