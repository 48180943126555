import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@material-ui/core";
import useStyles from "./SetupProfileStyles";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";

import { useFormik } from "formik";
import * as Yup from "yup";

import { QontoConnector, QontoStepIcon } from "./StepperStyles";

import ProfileStep from "./steps/Profile";
import WorkExperienceStep from "./steps/WorkExperience";
import EducationStep from "./steps/Education";
import CvStep from "./steps/Cv";
import FinishScreen from "./steps/Finish";

import Alert from "../../../components/Alert";

const templateDate = [
  {
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
  },
  {
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
  },
];

const SetupProfileScreen = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [user, setUser] = useState(null);

  const profileFormik = useFormik({
    initialValues: {
      First_Name: user?.First_Name || "",
      Last_Name: user?.Last_Name || "",
      Email: user?.Email || "",
      Phone: user?.Phone || "",
      Street: user?.Street || "",
      City: user?.City || "",
      Country: user?.Country || "",
      Date_of_Birth: user?.Year_of_Birth || "",
      LinkedIn_URL: user?.LinkedIn_URL || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      First_Name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, "First Name is only contain alphabets")
        .required("First Name is required"),
      Last_Name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, "Last Name is only contain alphabets")
        .required("Last Name is required"),
      Phone: Yup.string()
        .matches(/^\d+$/, "Phone is only contain numbers")
        .min(10, "Please input at least 10 digits")
        .required("Phone is required"),
      Street: Yup.string().required("Address is required"),
      City: Yup.string().required("City is required"),
      Country: Yup.string().required("Country is required"),
      Date_of_Birth: Yup.string().trim().required("Date of Birth is required"),
      // LinkedIn_URL: Yup.string().required("Linkedin URL is required !"),
    }),
    onSubmit: (values) => {
      props.editProfile(values);
    },
  });

  const getProfile = () => {
    props.getProfile();
  };

  const experienceFormik = useFormik({
    initialValues: {
      work_experiences: [{}],
      formDate: templateDate,
      initialDataLength: 0,
      canRemove: true,
    },
    validationSchema: Yup.object().shape({
      work_experiences: Yup.array().of(
        Yup.object().shape({
          company: Yup.string().required("Company is required"),
          occupation: Yup.string().required("Position is required"),
        })
      ),
      formDate: Yup.array().of(
        Yup.object().shape({
          start_month: Yup.number()
            .min(1)
            .max(12)
            .required("Start date is required"),
          start_year: Yup.string().required("Start year is required"),
          end_month: Yup.number(),
          end_year: Yup.string(),
        })
      ),
    }),
    onSubmit: (values) => {
      let work_experiences = [...values.work_experiences];
      let formDate = [...values.formDate];
      work_experiences = work_experiences.map((exp, index) => {
        let newExp = { ...exp };
        newExp.duration = {
          from: `${formDate[index].start_year}-${formDate[index].start_month}-01`,
          to: exp.is_currently_work
            ? ""
            : `${formDate[index].end_year}-${formDate[index].end_month}-01`,
        };
        return newExp;
      });
      let data = { work_experiences };
      props.editExperience(data);
    },
  });

  const getExperience = () => {
    props.getExperience();
  };

  const educationFormik = useFormik({
    initialValues: {
      educations: [{}],
      formDate: templateDate,
      initialDataLength: 0,
      canRemove: true,
    },
    validationSchema: Yup.object().shape({
      educations: Yup.array().of(
        Yup.object().shape({
          school: Yup.string().required("School / University is required"),
          degree: Yup.string().required("Degree is required"),
          major: Yup.string().required("Major is required"),
        })
      ),
      formDate: Yup.array().of(
        Yup.object().shape({
          start_month: Yup.number()
            .min(1)
            .max(12)
            .required("Start date is required"),
          start_year: Yup.string().required("Start year is required"),
          end_month: Yup.number(),
          end_year: Yup.string(),
        })
      ),
    }),
    onSubmit: (values) => {
      let educations = [...values.educations];
      let formDate = [...values.formDate];
      educations = educations.map((edu, index) => {
        let newEdu = { ...edu };
        newEdu.duration = {
          from: `${formDate[index].start_year}-${formDate[index].start_month}-01`,
          to: edu.is_currently_persuing
            ? ""
            : `${formDate[index].end_year}-${formDate[index].end_month}-01`,
        };
        return newEdu;
      });
      let data = { educations };
      props.editEducations(data);
    },
  });

  const getEducation = () => {
    props.getEducations();
  };

  const cvFormik = useFormik({
    initialValues: {
      cv: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const finishFormik = useFormik({
    initialValues: {
      finish: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const steps = [
    {
      label: "Profile",
      component: ProfileStep,
      formik: profileFormik,
      onLoad: getProfile,
    },
    {
      label: "Work Experience",
      component: WorkExperienceStep,
      formik: experienceFormik,
      onLoad: getExperience,
    },
    {
      label: "Education",
      component: EducationStep,
      formik: educationFormik,
      onLoad: getEducation,
    },
    {
      label: "Upload CV",
      component: CvStep,
      formik: cvFormik,
      onLoad: getProfile,
    },
    {
      label: "Finish",
      component: FinishScreen,
      formik: finishFormik,
      onLoad: getProfile,
    },
  ];

  const disabledSteps = [0, 2];

  const StepPage = steps[currentStep].component;
  const onLoad = steps[currentStep].onLoad;
  const formik = steps[currentStep].formik;

  const disableSkip = disabledSteps.includes(currentStep);

  const goToNextStep = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    formik.setSubmitting(false);
    setSuccessSubmit(false);
  };

  const goToPrevStep = () => {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  };

  const skipForm = () => {
    if (!disableSkip) {
      const nextStep = currentStep + 1;
      if (nextStep == steps.length) {
        history.push("/");
      } else {
        setCurrentStep(nextStep);
      }
    }
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    let loading = props.users_reducer.loading;
    let error = props.users_reducer.error;
    if (formik.isSubmitting && !loading && !error) {
      setSuccessSubmit(true);
    } else if (formik.isSubmitting && !loading && error) {
      console.log("error bos");
      setOpenSnackbar(true);
      formik.setSubmitting(false);
    }
  }, [formik.isSubmitting, props.users_reducer.loading]);

  useEffect(() => {
    if (successSubmit) {
      goToNextStep();
    }
  }, [successSubmit]);

  useEffect(() => {
    props.autoLogin(token);
    getProfile();
    getExperience();
    getEducation();
  }, [token]);

  useEffect(() => {
    const userProfile = props.users_reducer.profile;
    if (userProfile) {
      setUser(userProfile);
    }
  }, [props.users_reducer.profile]);

  useEffect(() => {
    let expDate = [
      {
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      },
    ];
    if (props.users_reducer.experience) {
      experienceFormik.setFieldValue("canRemove", false);
      let newExperience = props.users_reducer?.experience?.splice(0, 2);
      newExperience = newExperience?.map((exp, index) => {
        if (index >= 1) {
          expDate.push({
            start_month: "",
            start_year: "",
            end_month: "",
            end_year: "",
          });
        }
        let objExp = {};
        (objExp.id = exp["id"]), (objExp.company = exp["Company"]);
        objExp.occupation = exp["Occupation_/_Title"];
        objExp.is_currently_work = exp["I_currently_work_here"];
        const duration = exp["Work_Duration"];
        const startDate = duration?.from?.split("-");
        expDate[index].start_month = +startDate[1];
        expDate[index].start_year = startDate[0];
        if (!objExp.is_currently_work) {
          const endDate = duration?.to?.split("-");
          expDate[index].end_month = +endDate[1];
          expDate[index].end_year = endDate[0];
        }
        return objExp;
      });
      newExperience.length &&
        experienceFormik.setFieldValue("work_experiences", newExperience);
      experienceFormik.setFieldValue("initialDataLength", newExperience.length);
      experienceFormik.setFieldValue("formDate", expDate);
    }
  }, [props.users_reducer.experience]);

  useEffect(() => {
    let eduDate = [
      {
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      },
    ];
    if (props.users_reducer.education) {
      educationFormik.setFieldValue("canRemove", false);
      let newEducation = props.users_reducer?.education;
      newEducation = newEducation?.map((edu, index) => {
        if (index >= 1) {
          eduDate.push({
            start_month: "",
            start_year: "",
            end_month: "",
            end_year: "",
          });
        }
        let objEdu = {};
        objEdu.id = edu["id"];
        objEdu.is_currently_persuing = edu["Currently_pursuing"];
        objEdu.degree = edu["Degree"];
        objEdu.school = edu["Institute_/_School"];
        objEdu.major = edu["Major_/_Department"];
        const duration = edu["Duration"];
        const startDate = duration?.from?.split("-");
        eduDate[index].start_month = +startDate[1];
        eduDate[index].start_year = startDate[0];
        if (!objEdu.is_currently_persuing) {
          const endDate = duration?.to?.split("-");
          eduDate[index].end_month = +endDate[1];
          eduDate[index].end_year = endDate[0];
        }
        return objEdu;
      });
      newEducation.length &&
        educationFormik.setFieldValue("educations", newEducation);
      educationFormik.setFieldValue("initialDataLength", newEducation.length);
      educationFormik.setFieldValue("formDate", eduDate);
    }
  }, [props.users_reducer.education]);

  return (
    <div className={styles.container}>
      <Navbar onSkip={skipForm} disableSkip={disableSkip} />
      <div className={styles.signupContainer}>
        <Typography className={styles.skipFormMobile}>
          {"SKIP THIS FORM >"}
        </Typography>
        <Stepper
          alternativeLabel
          activeStep={currentStep}
          style={{ width: "100%", marginBottom: "0px", marginTop: "15px" }}
          connector={<QontoConnector />}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <Typography
                  className={
                    index <= currentStep
                      ? styles.activeStepper
                      : styles.defaultStepper
                  }
                >
                  {step.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <StepPage formik={formik} onLoad={onLoad} />
        <div
          className={
            currentStep == steps.length - 1
              ? styles.hideButton
              : styles.navigationContainer
          }
        >
          <Button
            className={currentStep == 0 ? styles.hideButton : styles.backButton}
            onClick={() => goToPrevStep()}
          >
            Back
          </Button>
          <Button
            className={styles.nextButton}
            disabled={
              formik.isSubmitting ||
              (!Object.keys(formik.touched).length &&
                !Object.keys(formik.errors).length) ||
              Object.keys(formik.errors).length
            }
            onClick={() => {
              setOpenSnackbar(false);
              if (currentStep === 1 || currentStep === 2) {
                if (
                  window.confirm(
                    "Important! All information will only be replaceable. You can never be allowed to delete the data."
                  )
                ) {
                  formik.submitForm();
                }
              } else {
                formik.submitForm();
              }
            }}
          >
            Next Step
          </Button>
        </div>
      </div>
      <Alert
        message={"Failed Saving Data"}
        isOpen={openSnackbar}
        isError={true}
        onClose={onCloseSnackBar}
      />
      <Footer />
    </div>
  );
};

export default SetupProfileScreen;
