import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: 50,
  },
  avatarGroup: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 10,
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  avatar: {
    width: 134,
    height: 134,
    marginRight: 50,
    borderRadius: 5,
    textAlign: "center",
    fontWeight: "lighter",
    color: "black",
    backgroundColor: Colors.lighter_gray,
  },
  avatarBtn: {
    // width: 332,
    height: 44,
    fontSize: 16,
    fontWeight: "bold",
  },
  btn: {
    width: 200,
    height: 44,
    fontSize: 16,
    fontWeight: "bold",
  },
  blueBtn: {
    color: "white",
    textAlign: "center",
    lineHeight: "18px",
    backgroundColor: Colors.blue,
    "&:hover": {
      backgroundColor: Colors.blue,
    },
  },
  grayBtn: {
    color: Colors.light_gray,
    borderWidth: 2,
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
    width: "100%",
  },
  inputLabel: {
    fontSize: 18,
    margin: "10px 0",
    color: "black",
  },
  input: {
    borderWidth: 2,
    borderRadius: 5,
    flex: 1,
  },
  errorMessage: {
    color: Colors.red,
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
});

export default useStyles;
