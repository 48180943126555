import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: 50,
  },
  btn: {
    margin: "30px 0",
    fontWeight: 600,
    color: "white",
    backgroundColor: Colors.blue,
    "&:hover": {
      backgroundColor: Colors.blue,
    },
  },
});

export default useStyles;
