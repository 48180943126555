import React from "react";
import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";

const ConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
  const styles = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className={styles.dialogContainer}>
        <Typography variant="h5">Are you sure to delete this data?</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "20px 0 10px 0",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: 20 }}
            className={clsx(styles.btn, styles.grayBtn)}
          >
            CANCEL
          </Button>
          <Button
            onClick={onConfirm}
            style={{ marginLeft: 20 }}
            className={clsx(styles.btn, styles.blueBtn)}
          >
            OK
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
