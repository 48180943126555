import React from "react";
import PropTypes from "prop-types";

import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/id";

import parse from "html-react-parser";

import useStyles from "./styles";

function FeaturedArticle({ article }) {
  const classes = useStyles();

  return (
    <a href={article?.link}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.image}
          image={article.image_url}
          title={article.title}
        />
        <CardContent className={classes.content}>
          <Typography variant="h5" className={classes.articleTags}>
            {article?.tags ? article.tags.join(", ") : ""}
          </Typography>
          <a href={article.link} className={classes.articleTitle}>
            {article.title}
          </a>
          <Typography className={classes.articleDescription}>
            {parse(article.excerpt)}
          </Typography>
          <div className={classes.footer}>
            <Typography variant="span" className={classes.articleAuthor}>
              {article.author}
            </Typography>
            <Typography variant="span" className={classes.articleDate}>
              <Moment local locale="id" format="MMMM DD, YYYY">
                {article.date}
              </Moment>
            </Typography>
          </div>
        </CardContent>
      </Card>
    </a>
  );
}

FeaturedArticle.propTypes = {
  article: PropTypes.object,
};

export default FeaturedArticle;
