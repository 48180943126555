import { connect } from "react-redux";
import Screen from "./JobDetailScreen";
import { getDetailJob } from "../../actions/JobDetailActions";
import { getRecommendation } from "../../actions/RecommendationActions";
import { doApplyJob, getAppliedJobs } from "../../actions/ApplyJobActions";
import {
  doSavedJob,
  getSavedJobs,
  doUnSavedJob,
} from "../../actions/SavedJobActions";
import { autoLogin } from "../../actions/AuthActions";

const mapStateToProps = (state) => ({
  loading: state.job_master.loading,
  success: state.job_master.success,
  message: state.job_master.message,
  job_detail: state.job_detail,
  recommendation: state.recommendation,
  apply_job: state.apply_jobs,
  saved_job: state.saved_jobs,
  isLoggedIn: state.auth_reducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  getDetailJob: (job_id) => dispatch(getDetailJob(job_id)),
  getRecommendation: (page = 1, page_size = 3, params) =>
    dispatch(getRecommendation(page, page_size, params)),
  doApplyJob: (job_id) => dispatch(doApplyJob(job_id)),
  doSavedJob: (job_id) => dispatch(doSavedJob(job_id)),
  doUnSavedJob: (job_id) => dispatch(doUnSavedJob(job_id)),
  getSavedJobs: () => dispatch(getSavedJobs()),
  getAppliedJobs: () => dispatch(getAppliedJobs()),
  autoLogin: (token) => dispatch(autoLogin(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
