import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";

export const getDetailJob = (job_id) => async (dispatch, getState) => {
  const state = getState().auth_reducer;
  dispatch({ type: ActionType.REQUEST_JOB_DETAIL });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const headers = state.token
      ? {
          Authorization: `Bearer ${state.token}`,
        }
      : {};
    const response = await client.get(`${Api.job_openings}/${job_id}`, {
      headers,
    });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      console.log(data);
      dispatch({
        type: ActionType.REQUEST_JOB_DETAIL_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_JOB_DETAIL_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_POPULAR_FAILED,
      payload: {
        message: "Failed Request Job Detail",
        status: response?.status,
      },
    });
  }
};
