import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrapperBanner: {
    background: "url('/assets/images/placeholder.png') no-repeat center",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    padding: "0 73px",
    "@media (max-width: 415px)": {
      background:
        "url('/assets/images/mobile_placeholder.png') no-repeat center",
      textAlign: "center",
      padding: "0 16px !important",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  wrapperBannerTitle: {
    padding: "169px 0 60px 0",
  },
  bannerTitle: {
    fontSize: "96px",
    fontWeight: "600",
    color: "#FFFFFF",
    "@media (max-width: 640px)": {
      fontSize: "86px",
    },
    "@media (max-width: 450px)": {
      fontSize: "60px",
    },
  },
  bannerSubtitle: {
    fontSize: "38px",
    fontWeight: "500",
    color: "#FFFFFF",
    maxWidth: "480px",
    lineHeight: "42px",
    "@media (max-width: 640px)": {
      fontSize: "28px",
    },
    "@media (max-width: 450px)": {
      fontSize: "24px",
    },
  },
  buttonRS: {
    backgroundColor: "#DE1A1B",
    borderRadius: "2px",
    color: "white",
    fontSize: "20px",
    fontWeight: "900",
    padding: "12px 24px 12px 24px",
    marginTop: "80px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#DE1A1B",
    },
    marginBottom: "100px",
  },
  buttonWrapper: {
    "@media (max-width: 960px)": {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
  buttonWWD: {
    height: "50px",
    width: "213px",
    backgroundColor: "#1875D1",
    borderRadius: "2px",
    color: "white",
    fontSize: "16px",
    fontWeight: "900",
    padding: "",
    margin: "0px 72px 30px 0px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1875D1",
    },
    float: "right",
    "@media (max-width: 960px)": {
      marginRight: "0px",
    },
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  bgContainer: {
    display: "flex",
    flexDirection: "column",
    background: "url('/assets/images/img_bg.png') no-repeat center",
    backgroundSize: "cover",
    height: "350px",
    padding: "0 73px",
    justifyContent: "center",
    "@media (max-width: 769px)": {
      paddingTop: "24px !important",
    },
    "@media (max-width: 641px)": {
      padding: "16px !important",
      height: "auto",
      minHeight: "350px",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  bgHiringInquiry: {
    display: "flex",
    flexDirection: "column",
    background: "url('/assets/images/img_bg.png') no-repeat center",
    backgroundSize: "cover",
    minHeight: "243px",
    height: "100%",
    padding: "0 73px",
    justifyContent: "center",
    "@media (max-width: 641px)": {
      padding: "16px !important",
      height: "auto",
      minHeight: "350px",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  hiringInquiryContent: {
    padding: "33px 0px 32px 0px",
    "@media (max-width: 960px)": {
      textAlign: "center",
    },
    "@media (max-width: 769px)": {
      padding: "24px 100px 0px 100px",
    },
    "@media (max-width: 675px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media (max-width: 595px)": {
      padding: "0px 20px 0px 20px",
    },
    "@media (max-width: 376px)": {
      padding: "0px 5px 0px 5px",
    },
  },
  hpcContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    height: "350px",
    padding: "0 73px",
    marginBottom: "60px",
    justifyContent: "center",
    "@media (max-width: 641px)": {
      padding: "16px !important",
      height: "auto",
      minHeight: "350px",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  bgContainerMd: {
    height: 320,
  },
  containerTitle: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "20px",
  },
  articlesContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "0 67px",
    marginTop: "66px",
    "@media (max-width: 641px)": {
      padding: "0 16px !important",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  articlesContainerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0",
  },
  articleList: {
    display: "flex",
    flexDirection: "row",
    marginTop: "32px",
    marginBottom: "72px",
    "@media (max-width: 768px)": {
      display: "block",
      overflowY: "hidden",
      overflowX: "scroll",
    },
    "@media (max-width: 642px)": {
      display: "block !important",
      overflowY: "hidden !important",
      overflowX: "scroll !important",
    },
  },
  articleWrapper: {
    paddingBottom: "20px",
    "@media (max-width: 642px)": {
      width: "120vw !important",
    },
    "@media (max-width: 416px)": {
      width: "200vw !important",
    },
  },
  articleItem: {
    "@media (max-width: 768px)": {
      maxWidth: "275px",
      maxHeight: "520px",
    },
  },
  jobsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "0 61px",
    marginTop: "60px",
    marginBottom: "60px",
    "@media (max-width: 641px)": {
      padding: "0 16px !important",
      marginTop: "50px !important",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  jobsContainerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 12px",
    marginBottom: "14px",
  },
  jobList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      overflowX: "scroll",
      flexWrap: "inherit",
      justifyContent: "unset !important",
      alignItems: "unset !important",
    },
    "@media (max-width: 1009px)": {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  searchWrapper: {
    width: "100%",
    borderRadius: 5,
    fontStyle: "italic",
    backgroundColor: "white",
  },
  searchForm: {
    display: "flex",
    padding: "15px 30px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  searchTitle: {
    color: "white",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
  },
  testimoniTitle: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#23201D",
    margin: "0 auto",
    marginBottom: "24px",
  },
  carouselContainer: {
    width: "50%",
    margin: "0 auto",
    marginBottom: 20,
    "@media (max-width: 768px)": {
      width: "70%",
    },
  },
  buttonMore: {
    padding: "auto 39px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "700",
    fontSize: "14px",
    color: "#FFFFFF",
    background: "#DE1A1B",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "2px",
    width: "146px",
    height: "44px",
  },
  whatWeDoContainer: {
    background: "url('/assets/images/img_bg.png') no-repeat center",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
  },
  whatWeDoHeader: {
    textAlign: "center",
    padding: "30px 0 60px 0",
    "@media (max-width: 642px)": {
      padding: "24px 16px !important",
    },
    "@media (max-width: 1009px)": {
      padding: "30px 32px 60px 32px",
    },
  },
  whatWeDoTitle: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#FFFFFF",
  },
  whatWeDoContent: {
    padding: "0 76px",
    // paddingBottom: "30px",
    "@media (max-width: 641px)": {
      padding: "16px !important",
      height: "auto",
      minHeight: "350px",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
    position: "relative",
  },
  whatWeDoCard: {
    "@media (max-width: 630px)": {
      marginRight: "20px",
    },
  },
  whatWeDoCardTitle: {
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "26px",
    // textDecoration: "underline",
    marginBottom: "20px",
    // textUnderlineOffset: "10px",
    borderBottom: "3px solid #FFFFFF",
    maxWidth: "275px",
  },
  cardWrapper: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 1px 6px rgba(29, 98, 174, 0.12)",
    borderRadius: "2px",
    padding: "24px",
    marginBottom: "16px",
    width: "85%",
    "@media (max-width: 960px)": {
      width: "93%",
    },
  },
  cardTitle: {
    color: "#DE1A1B",
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "16px",
  },
  cardSubTitle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#23201D",
  },
  benefitsTitle: {
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "26px",
    // textDecoration: "underline",
    marginBottom: "20px",
    // textUnderlineOffset: "10px",
    borderBottom: "3px solid #FFFFFF",
    width: "76px",
  },
  benefitsList: {
    position: "relative",
    paddingBottom: "42px",
    "@media (max-width: 416px)": {
      paddingBottom: "32px !important",
    },
  },
  dot: {
    height: "25px",
    width: "25px",
    backgroundColor: "#1875D1",
    borderRadius: "50%",
    color: "#FFFFFF",
    fontSize: "20px",
    fontWeight: "600",
    display: "inline-block",
    textAlign: "center",
    padding: "5px",
    position: "absolute",
    top: "8px",
    "@media (max-width: 416px)": {
      top: "25px !important",
    },
  },
  listText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#FFFFFF",
    marginLeft: "40px",
  },
  verticalLine: {
    height: "160px",
    width: "2px",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "120px",
    left: "48%",
    "@media (max-width: 1009px)": {
      left: "49%",
    },
    "@media (max-width: 960px)": {
      display: "none",
    },
  },
  hiringInquiryTitle: {
    lineHeight: "42px",
    fontSize: "38px",
    fontWeight: "700",
    color: "#FFFFFF",
    marginBottom: "16px",
  },
  hiringInquirySubtitle: {
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "500",
    color: "#FFFFFF",
    "@media (max-width: 1009px)": {
      paddingRight: "20px",
    },
    "@media (max-width: 769px)": {
      padding: "0px 50px 0px 50px !important",
    },
    "@media (max-width: 416px)": {
      padding: "0px 15px 0px 15px !important",
    },
  },
  buttonContent: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 960px)": {
      justifyContent: "center",
      margin: "32px 0px 24px 0px",
    },
  },
  contactUs: {
    alignSelf: "center",
    height: "55px !important",
    fontSize: "24px",
    fontWeight: "900",
    color: "#FFFFFF",
    backgroundColor: "#1875D1",
    "&:hover": {
      backgroundColor: "#1875D1",
    },
  },
});

export default useStyles;
