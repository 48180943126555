import React, { useEffect, useState } from "react";
import { Typography, Paper, Button, useMediaQuery } from "@material-ui/core";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import useStyles from "./styles";
import { connect } from "react-redux";
import { getAppliedJobs } from "../../../../actions/ApplyJobActions";
import { Images } from "../../../../themes";
import moment from "moment";
import { useHistory } from "react-router";
import Navbar from "../../../../components/Navbar";
import Footer from "../../../../components/Footer";
import JobCard from "../../../../components/JobCard";
import "moment/locale/en-gb";
moment.locale("en-gb");

const ApplicationsContent = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const [appliedJobs, setAppliedJobs] = useState(null);
  const md = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    props.getAppliedJobs();
  }, []);

  useEffect(() => {
    const jobs = props.applied_jobs.data;
    if (jobs && jobs.length > 0) {
      setAppliedJobs(jobs);
    }
  }, [props.applied_jobs.data]);
  console.log(props.applied_jobs.data);
  return (
    <>
      {location.pathname == "/profile" ? null : <Navbar />}
      <div
        className={styles.container}
        style={{
          marginLeft: md == true ? 20 : 0,
          marginTop: md == true ? 20 : 0,
        }}
      >
        <Typography variant="h4" className={styles.title}>
          Applied Jobs
        </Typography>
        <div>
          {props.applied_jobs.loading ? (
            <>
              <JobCard loading />
            </>
          ) : appliedJobs && appliedJobs.length > 0 ? (
            appliedJobs
              .map((item) => (
                <Paper
                  key={item.id}
                  elevation={2}
                  className={styles.jobContainer}
                >
                  <div className={styles.header}>
                    <img src={Images.dummyCompany} className={styles.logo} />
                    <div className={styles.jobHeader}>
                      <h2 className={styles.jobTitle}>
                        {item.Job_Opening_Name}
                      </h2>
                      <p className={styles.company}>{item.Client_Name?.name}</p>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <span className={styles.contentItem}>
                      <img
                        src="/assets/icons/location.png"
                        className={styles.contentIcon}
                      />
                      {item.Location}
                    </span>
                    <span className={styles.contentItem}>
                      <MonetizationOnOutlinedIcon
                        className={styles.contentIcon}
                      />
                      {item.Minimum_Salary_Range}
                    </span>
                    <Button
                      variant="contained"
                      disabled
                      className={styles.appliedBtn}
                    >
                      Applied
                    </Button>
                  </div>
                  <div className={styles.footer}>
                    <p className={styles.footnote}>
                      Applied on {moment(item.created_at).format("D MMMM YYYY")}
                    </p>
                  </div>
                </Paper>
              ))
              .reverse()
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => history.push("/jobs")}
                className={styles.btn}
              >
                <Typography>See Other Job Vacancy</Typography>
              </Button>
            </div>
          )}
        </div>
      </div>
      {location.pathname == "/profile" ? null : <Footer />}
    </>
  );
};

const mapStateToProps = (state) => ({
  applied_jobs: state.apply_jobs,
});

const mapDispatchToProps = (dispatch) => ({
  getAppliedJobs: () => dispatch(getAppliedJobs()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsContent);
