import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  popoverLink: {
    margin: "10px 0",
    color: "black",
    "&:hover, &:focus": {
      color: Colors.red,
    },
  },
});

export default useStyles;
