import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  hideButton: {
    visibility: "hidden",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  },
  selectInput: {
    borderWidth: 2,
    borderRadius: 5,
    width: "100%",
    height: "44px",
  },
  skipFormMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginTop: "30px",
      alignSelf: "flex-end",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "13px",
      textTransform: "uppercase",
      color: "#808080",
    },
  },
  defaultStepper: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "15px",
    color: "#808080",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  activeStepper: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "15px",
    color: "#CC0000",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  signupContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "35px",
    marginBottom: "36px",
    padding: "0 72px",
    [theme.breakpoints.down("md")]: {
      padding: "0 23px",
      marginTop: "10px",
    },
  },
  signupBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: "100%",
    height: "auto",
    padding: "55px 24%",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      padding: "12px 0",
      boxShadow: "none",
    },
  },
  iconButton: {
    marginRight: "13px",
  },
  signupTitle: {
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "40px",
    color: "#23201D",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineHeight: "20px",
    },
  },
  input: {
    height: "44px",
    borderWidth: 2,
    borderRadius: 5,
    flex: 1,
  },
  inputText: {
    height: "44px",
    padding: "0 30px",
    fontStyle: "italic",
  },
  inputError: {
    border: "1px solid red",
    color: "red",
    height: "44px",
    padding: "0 30px",
  },
  errorMessage: {
    color: "red",
  },
  blueButton: {
    marginTop: "35px",
    marginBottom: "45px",
    width: "196px",
    background: "#1875D1",
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: "16px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  nextButton: {
    marginTop: "27px",
    width: "120px",
    background: "#1875D1",
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: "16px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "15px",
    textTransform: "uppercase",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    "&:active": {
      background: "#1875D1 !important",
    },
    "&:hover": {
      background: "#1875D1 !important",
    },
  },
  backButton: {
    marginTop: "27px",
    width: "120px",
    background: "#FFFFFF",
    borderRadius: "5px",
    color: "#808080",
    padding: "14px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  addButton: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #1875D1",
    borderRadius: "5px",
    color: "#1875D1",
  },
  removeButton: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #808080",
    borderRadius: "5px",
    color: "#808080",
  },
  navigationContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  checkbox: {
    marginTop: "1px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "15px",
    marginLeft: "-10px",
  },
  pdfContent: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  textGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconGroup: {
    display: "flex",
  },
  pdfIcon: {
    width: 60,
    height: 60,
    marginRight: 20,
  },
}));

export default useStyles;
