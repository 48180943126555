import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  containerTitle: {
    fontWeight: 600,
    fontSize: 21,
    marginBottom: 50,
  },
  searchTitle: {
    color: "white",
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
  },
  contentContainer: {
    padding: "73px 80px",
    backgroundColor: Colors.lighter_gray,
    "@media (max-width: 641px)": {
      padding: "73px 16px",
    },
    "@media (max-width: 1009px)": {
      padding: "0px 32px 0px 32px",
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    margin: "20px 0",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  addressContainer: {
    flex: 0.5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    fontWeight: 500,
    fontSize: 21,
    margin: "10px 0",
  },
  email: {
    fontWeight: 500,
    fontSize: 21,
    margin: "10px 0",
    display: "inline-block",
  },
  map: {
    border: 0,
    display: "flex",
    width: "100%",
    height: 400,
    "@media (max-width: 768px)": {
      height: 250,
    },
  },
});

export default useStyles;
