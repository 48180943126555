import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";

import useStyles from "./AboutStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { Images } from "../../themes";

const AboutScreen = () => {
  const styles = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.contentContainer}>
        <Paper className={styles.contentBox}>
          <div className={styles.contentWrapper}>
            <Typography className={styles.sectionTitle}>About Us</Typography>
            <Typography className={styles.contentText}>
              At Linardi Associates, we offer full-fledged, personalized
              corporate recruitment. Clients find our attentiveness refreshing
              and effective in streamlining the hiring process. Industries
              served include, but not limited to banking, telecommunications,
              mining, oil and gas, information technology, consumer goods,
              retail, service and hospitality. Our success is defined by the
              quality of professionals we recommend and by the contributions
              they make to the clients we serve.
            </Typography>
            <div className={styles.journeyWrapper}>
              <img src={Images.About_Image} className={styles.aboutImage} />
              <div className={styles.journeyContent}>
                <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                  Our Journey
                </Typography>
                <Typography className={styles.contentText}>
                  Established in the early 2000s. We started our business by
                  giving services to FMCG Clients and Local Conglomerates. Our
                  proven track record later led us to provide our expertise for
                  Clients from Investment Banking industries. Our company also
                  played a significant role in the rise of E-Commerce business
                  in Indonesia by partnering with some of the very first
                  pioneers including Rocket Internet. A decade later, our
                  business has grown significantly by offering full-fledged,
                  personalized executive recruitment from Manager to C-Level
                  positions for various Clients globally.
                </Typography>
              </div>
            </div>
            <Typography
              className={styles.sectionTitle}
              style={{ marginTop: "73px" }}
            >
              Why You Should Hire Us
            </Typography>
            <Typography className={styles.contentText}>
              <Typography className={styles.sectionSubTitle}>
                Integrated Human Resource Platforms
              </Typography>
              We offer unique and one of a kind method in candidate sourcing. By
              using our integrated products, we can provide candidates by
              optimizing our internal system and integrated database. By August
              2020, we have more than 8+ millions quality members databases to
              fulfill our Client&apos;s needs.
            </Typography>
            <Typography className={styles.contentText}>
              <Typography className={styles.sectionSubTitle}>
                Access to Top Local and Multinational Candidates
              </Typography>
              Having more than 8+ million databases of quality members, we offer
              the best local and multinational candidates. This advantage gives
              you a wider chance to select perfect match candidates to join your
              company.
            </Typography>
            <Typography className={styles.contentText}>
              <Typography className={styles.sectionSubTitle}>
                Experienced Recruiters & Expertise in Various Industries
              </Typography>
              Having more than 19 years of serving clients from various
              industries, our Recruiters are having a proven track record in
              giving the best recruitment solutions for companies.
            </Typography>
            <Typography className={styles.contentText}>
              <Typography className={styles.sectionSubTitle}>
                Competitive Rate
              </Typography>
              Competitive Rate We provide the best solutions for your
              recruitment process with competitive rate.
            </Typography>
          </div>
        </Paper>
      </div>
      <Footer />
    </div>
  );
};

export default AboutScreen;
