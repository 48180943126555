import { connect } from "react-redux";
import Screen from "./SearchScreen";
import {
  getIndustryPrefs,
  getJobTypes,
  getLocations,
  getSalaryRange,
  getWorkExperience,
} from "../../actions/JobMasterActions";
import { searchJobs } from "../../actions/SearchActions";

const mapStateToProps = (state) => ({
  loading: state.job_master.loading,
  success: state.job_master.success,
  message: state.job_master.message,
  industries: state.job_master.industry_prefs,
  salaries: state.job_master.min_salary,
  work_exp: state.job_master.work_exp,
  locations: state.job_master.locations,
  job_type: state.job_master.job_type,
  search: state.search_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getIndustryPrefs: () => dispatch(getIndustryPrefs()),
  getJobType: () => dispatch(getJobTypes()),
  getWorkExperience: () => dispatch(getWorkExperience()),
  getSalaryRange: () => dispatch(getSalaryRange()),
  getLocations: () => dispatch(getLocations()),
  searchJobs: (page = 1, page_size = 12, params) =>
    dispatch(searchJobs(page, page_size, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
