import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Paper,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";
import DefaultDialog from "../../../../components/DefaultDialog";
import { getPortfolio } from "../../../../actions/UsersActions";
import { connect } from "react-redux";
import { Images, Colors } from "../../../../themes";
import { Api, ApiConfig } from "../../../../utils/constan";
import client from "../../../../utils/service";
import DropboxPicker from "../../../../components/DropboxPicker";
import Alert from "../../../../components/Alert";

const PortfolioForm = (props) => {
  const styles = useStyles();
  const [alertMessage, setAlertMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [portfolioData, setPortfolioData] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dataGoogleDrive, setDataGoogleDrive] = useState(null);
  const [googleDriveToken, setGoogleDriveToken] = useState(null);

  useEffect(() => {
    if (props?.user_reducer?.portfolio) {
      setPortfolioData(props?.user_reducer?.portfolio);
    } else {
      props?.getPortfolio();
    }
  }, [props?.user_reducer]);

  useEffect(() => {
    if (dataGoogleDrive) {
      uploadFromGoogleDrive(dataGoogleDrive);
    }
  }, [dataGoogleDrive]);

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const GoogleDriveFunction = {
    setDataGoogleDrive,
    setGoogleDriveToken,
  };

  const handleClose = () => {
    setUploadDialog(false);
    props?.closeDialog();
  };

  const deletePortfolio = () => {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .delete(Api.portfolio, {
        headers: {
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
      })
      .then(() => {
        setPortfolioData(null);
        props?.getPortfolio();
      })
      .catch((err) => {
        console.log(err.response);
        setIsError(true);
        setAlertMessage("Failed To Delete File");
        setOpenSnackbar(false);
      });
  };

  const uploadFromGoogleDrive = async (file) => {
    let postData = { ...file, token: googleDriveToken };
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .post(Api.portfolio_gdrive, postData, {
        headers: {
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(() => {
        setAlertMessage("Upload CV Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        props?.getPortfolio();
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setAlertMessage("Failed To Upload File");
        setOpenSnackbar(false);
      });
  };

  const uploadFromDropbox = async (file) => {
    let formData = new FormData();
    formData.append("ext_url", file.link);
    formData.append("ext_filename", file.name);
    formData.append("ext_filesize", file.bytes);
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .post(Api.portfolio, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(() => {
        setAlertMessage("Upload CV Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        props?.getPortfolio();
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setAlertMessage(
          "Max file size is 10 MB & Acceptable file types are .pdf, .jpg, .jpeg, .png, .docx"
        );
        setOpenSnackbar(true);
        setIsUploading(false);
      });
  };

  const getFileFromDropbox = (file) => {
    uploadFromDropbox(file);
    setUploadDialog(false);
    setIsUploading(true);
    setOpenSnackbar(false);
    setIsError(false);
  };

  useEffect(() => {
    if (props?.data) {
      setPortfolioData(props?.data);
    }
  }, [props.data]);

  const onFileChange = (f) => {
    console.log(f.target);
    uploadHandler(f);
    setIsUploading(true);
    setOpenSnackbar(false);
    setIsError(false);
    setUploadDialog(false);
  };

  const uploadHandler = async (file) => {
    let formData = new FormData();
    formData.append("file", file[0]);
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .post(Api.portfolio, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(() => {
        setAlertMessage("Upload CV Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        setUploadProgress(0);
        props?.getPortfolio();
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setAlertMessage(
          "Max file size is 10 MB & Acceptable file types are .pdf, .jpg, .jpeg, .png, .docx"
        );
        setOpenSnackbar(true);
        setUploadProgress(0);
        setIsUploading(false);
      });
  };

  const renderContent = () => (
    <>
      <div className={styles.content}>
        <Paper elevation={3} style={{ width: "100%" }}>
          <div className={styles.pdfContent}>
            <div className={styles.textGroup}>
              <img
                src={Images.pdfIcon}
                className={styles.pdfIcon}
                style={{ width: 32, height: 32 }}
              />
              <div>
                <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                  {portfolioData?.filename}
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 11,
                    color: Colors.light_gray,
                  }}
                >
                  {(Math.round(portfolioData?.size) / 1000000).toFixed(2) +
                    " MB"}
                </Typography>
              </div>
            </div>
            <div className={styles.iconGroup}>
              <IconButton onClick={() => window.open(portfolioData?.url)}>
                <img
                  src={Images.downloadIcon}
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
              <IconButton onClick={() => deletePortfolio()}>
                <img src={Images.trashIcon} style={{ width: 24, height: 24 }} />
              </IconButton>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );

  const renderUploadContent = () => (
    <div className={styles.content}>
      <Button
        className={styles.btn}
        component="span"
        onClick={() => setUploadDialog(true)}
      >
        UPLOAD CV
      </Button>
      <Typography style={{ textAlign: "center" }}>
        *Max file size is 10 MB.
        <br />
        Acceptable file types are .pdf, .jpg, .jpeg, .png, .docx
      </Typography>
    </div>
  );

  return (
    <DefaultDialog md={true} isOpen={props?.dialogOpen} onClose={handleClose}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        Edit CV/Portfolio
      </Typography>
      {isUploading ? (
        <LinearProgress variant="determinate" value={uploadProgress} />
      ) : portfolioData ? (
        renderContent()
      ) : uploadDialog ? (
        <DropboxPicker
          onSuccess={getFileFromDropbox}
          onFile={onFileChange}
          googleDrive={GoogleDriveFunction}
          hideDialog={handleClose}
        />
      ) : (
        renderUploadContent()
      )}
      {/* </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "20px 0",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          disabled={isUploading}
          onClick={() => handleClose()}
          style={{ lineHeight: "18px", width: "fit-content" }}
          className={clsx(styles.btn, styles.blueBtn)}
        >
          SAVE CHANGES
        </Button>
      </div>

      <Alert
        message={alertMessage}
        isOpen={openSnackbar}
        isError={isError}
        onClose={onCloseSnackBar}
      />
    </DefaultDialog>
  );
};

const mapStateToProps = (state) => ({
  auth_reducer: state.auth_reducer,
  user_reducer: state.user_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPortfolio: () => dispatch(getPortfolio()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioForm);
