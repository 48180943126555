import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  Divider as MuiDivider,
  Checkbox,
} from "@material-ui/core";
import Alert from "../../components/Alert";
import { useHistory } from "react-router-dom";

import { LinkedIn } from "react-linkedin-login-oauth2";
import { Formik } from "formik";
import * as Yup from "yup";
import useStyles from "./SignupStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const signupValidation = Yup.object().shape({
  First_Name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "First Name is only contain alphabets")
    .required("First Name is required"),
  Last_Name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Last Name is only contain alphabets")
    .required("Last Name is required"),
  Email: Yup.string()
    .email("Please input valid email address")
    .required("Please input valid email address"),
  Phone: Yup.string()
    .matches(/^\d+$/, "Phone is only contain numbers")
    .min(10, "Please input at least 10 digits")
    .required("Phone is required"),
  Password: Yup.string()
    .matches(
      /^.*(?=.{8,})((?=.*[[\]\\!@#$%^&*()\-_=+{};:,<.>'"/?`|~]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("Password is required"),
  Confirm_Password: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("Password"), null], "Passwords don't match."),
});
const Divider = ({ children, ...props }) => (
  <Grid container alignItems="center" spacing={2} {...props}>
    <Grid item xs>
      <MuiDivider style={{ backgroundColor: "#808080" }} />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <MuiDivider style={{ backgroundColor: "#808080" }} />
    </Grid>
  </Grid>
);

const SignupScreen = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);

  const submitSignup = (values, setSubmitting) => {
    setHasRendered(true);
    setOpenSnackbar(false);
    if (values.Password == values.Confirm_Password) {
      props.register(values);
      setSubmitting(false);
    }
  };

  const handleLinkedinSuccess = (data) => {
    console.log(data);
    props.doLinkedinLogin(data?.code);
  };

  const handleLinkedinFailure = (error) => {
    console.log(error);
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (props.auth_reducer.token) {
      history.push(`/signup/profile/${props.auth_reducer.token}`);
    }
  }, [props.auth_reducer]);

  useEffect(() => {
    const { loading, error, success } = props.register_reducer;
    if (!loading && !error && success) {
      history.replace({
        pathname: "/signup/success",
        state: {
          success: true,
        },
      });
      props.resetState();
    } else if (!loading && error && !success && hasRendered) {
      setOpenSnackbar(true);
      setHasRendered(false);
    }
  }, [props.register_reducer.loading]);

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.signupContainer}>
        <Paper className={styles.signupBox}>
          <Typography className={styles.signupTitle}>
            Sign Up to Linardi Associates
          </Typography>
          <LinkedIn
            // eslint-disable-next-line no-undef
            clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
            onFailure={handleLinkedinFailure}
            onSuccess={handleLinkedinSuccess}
            redirectUri={`${window.location.origin}/signin/linkedin`}
            scope="r_emailaddress r_liteprofile"
            renderElement={({ onClick, disabled }) => (
              <Button
                onClick={onClick}
                disabled={disabled}
                className={styles.signupLinkedin}
              >
                <img
                  src="/assets/icons/linkedin.png"
                  style={{ marginRight: "13px" }}
                />{" "}
                SIGN UP WITH LINKEDIN
              </Button>
            )}
          />
          <Divider className={styles.signupDivider}>
            <Typography className={styles.textDivider}>
              or sign up using email
            </Typography>
          </Divider>
          <Formik
            initialValues={{
              First_Name: "",
              Last_Name: "",
              Email: "",
              Phone: "",
              Password: "",
              Confirm_Password: "",
              Agreement: false,
            }}
            validationSchema={signupValidation}
            onSubmit={(values, { setSubmitting }) => {
              submitSignup(values, setSubmitting);
            }}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldTouched,
              isSubmitting,
              errors,
              touched,
            }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="First Name"
                      variant="outlined"
                      name="First_Name"
                      value={values.First_Name}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("First_Name")}
                      inputProps={{
                        className:
                          touched.First_Name && errors.First_Name
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.First_Name && errors.First_Name ? (
                      <span className={styles.errorMessage}>
                        {errors.First_Name}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="Last Name"
                      variant="outlined"
                      name="Last_Name"
                      value={values.Last_Name}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("Last_Name")}
                      inputProps={{
                        className:
                          touched.Last_Name && errors.Last_Name
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.Last_Name && errors.Last_Name ? (
                      <span className={styles.errorMessage}>
                        {errors.Last_Name}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="Email"
                      variant="outlined"
                      name="Email"
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("Email")}
                      inputProps={{
                        className:
                          touched.Email && errors.Email
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.Email && errors.Email ? (
                      <span className={styles.errorMessage}>
                        {errors.Email}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="Phone Number"
                      variant="outlined"
                      name="Phone"
                      value={values.Phone}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("Phone")}
                      inputProps={{
                        className:
                          touched.Phone && errors.Phone
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.Phone && errors.Phone ? (
                      <span className={styles.errorMessage}>
                        {errors.Phone}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="Password"
                      type="password"
                      variant="outlined"
                      name="Password"
                      value={values.Password}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("Password")}
                      inputProps={{
                        className:
                          touched.Password && errors.Password
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.Password && errors.Password ? (
                      <span className={styles.errorMessage}>
                        {errors.Password}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="Confirm Password"
                      type="password"
                      variant="outlined"
                      name="Confirm_Password"
                      value={values.Confirm_Password}
                      onChange={handleChange}
                      onBlur={() => setFieldTouched("Confirm_Password")}
                      inputProps={{
                        className:
                          touched.Confirm_Password && errors.Confirm_Password
                            ? styles.inputError
                            : styles.inputText,
                      }}
                    />
                    {touched.Confirm_Password && errors.Confirm_Password ? (
                      <span className={styles.errorMessage}>
                        {errors.Confirm_Password}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Typography className={styles.passwordNotice}>
                  Your password must contain the following: an uppercase letter,
                  a number, and a special character.
                </Typography>
                <div className={styles.signupAgreement}>
                  <Checkbox
                    size="medium"
                    color="primary"
                    name="Agreement"
                    value={values.Agreement}
                    onChange={handleChange}
                  />
                  <Typography>
                    By signing up, you agree to Linardi’s{" "}
                    <Link to="/privacy-policy" className={styles.agreementLink}>
                      Terms Conditions
                    </Link>{" "}
                    and&nbsp;
                    <Link
                      to="/terms-conditions"
                      className={styles.agreementLink}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </div>
                <Button
                  type="submit"
                  disabled={!values.Agreement || isSubmitting || hasRendered}
                  className={styles.signupButton}
                >
                  SIGN UP
                </Button>
                <div className={styles.signinText}>
                  <Typography>
                    Already have an account?&nbsp;
                    <Link to="/signin" className={styles.agreementLink}>
                      Sign in
                    </Link>
                  </Typography>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
      <Alert
        message={props.register_reducer.message}
        isOpen={openSnackbar}
        isError={true}
        onClose={onCloseSnackBar}
      />
      <Footer />
    </div>
  );
};

export default SignupScreen;
