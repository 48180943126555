import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { AddCircleRounded, Edit as EditIcon } from "@material-ui/icons";
import DefaultDialog from "../../../../components/DefaultDialog";
import { useFormik } from "formik";
import clsx from "clsx";
import useStyles from "./styles";

import { connect } from "react-redux";
import {
  getExperience,
  editExperience,
  addExperience,
} from "../../../../actions/UsersActions";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { Images } from "../../../../themes";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const template = {
  company: "",
  occupation: "",
  is_currently_work: false,
  summary: "",
};

const ExperienceContent = (props) => {
  const styles = useStyles();
  const [inputValues, setInputValues] = useState(template);
  const [selectedValues, setSelectedValues] = useState({
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
  });
  const [experienceData, setExperienceData] = useState([]);
  const [postSubmit, setPostSubmit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const formik = useFormik({
    initialValues: inputValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      let duration = {
        from: `${selectedValues["start_year"]}-${selectedValues["start_month"]}-01`,
        to: values["is_currently_work"]
          ? ""
          : `${selectedValues["end_year"]}-${selectedValues["end_month"]}-01`,
      };
      let data = {
        work_experiences: [
          {
            ...values,
            duration,
          },
        ],
      };

      if (postSubmit) {
        props.addExperience(data);
      } else {
        props.editExperience(data);
      }
    },
  });

  useEffect(() => {
    props.getExperience();
  }, []);

  useEffect(() => {
    const success = props.users_reducer.success;
    if (success) {
      handleClose();
    }
  }, [props.users_reducer.success]);

  useEffect(() => {
    const exp = props.users_reducer.experience;
    if (exp && exp.length > 0) {
      const data = [];
      exp.map((item) => {
        let d = {
          id: item.id,
          company: item.Company,
          is_currently_work: item.I_currently_work_here,
          occupation: item["Occupation_/_Title"],
          duration: {
            from: item.Work_Duration?.from,
            to: item.Work_Duration?.to,
          },
          summary: item.Summary,
        };
        data.push(d);
      });
      setExperienceData(data);
    }
  }, [props.users_reducer.experience]);

  const handleClose = () => {
    setInputValues(template);
    setSelectedValues({
      start_month: "",
      start_year: "",
      end_month: "",
      end_year: "",
    });
    setDialogOpen(false);
    formik.setSubmitting(false);
    formik.resetForm();
  };

  const handleClickItem = (id, data) => {
    const _data = data;

    if (_data) {
      const newInput = {
        id: _data?.id,
        company: _data?.company,
        is_currently_work: _data?.is_currently_work,
        occupation: _data?.occupation,
        summary: _data?.summary,
      };

      let fromValue = moment(_data.duration?.from);
      let toValue = moment(_data.duration?.to);
      const newDate = {
        start_year: fromValue ? fromValue.year() : null,
        start_month: fromValue ? fromValue.month() + 1 : null,
        end_year: toValue ? toValue.year() : null,
        end_month: toValue ? toValue.month() + 1 : null,
      };
      setSelectedStartDate(fromValue);
      setSelectedEndDate(toValue);
      setInputValues(newInput);
      setSelectedValues(newDate);
      setPostSubmit(false);
    }

    if (id == 1) {
      setDialogOpen(true);
    }
  };

  const handleDateChange = (e, val) => {
    const newDate = selectedValues;
    let value = moment(val);
    switch (e) {
      case "start_date":
        newDate["start_month"] = value.month() + 1;
        newDate["start_year"] = value.year();
        newDate["end_month"] = "";
        newDate["end_year"] = "";
        setSelectedEndDate(null);
        break;
      case "end_date":
        if (val > selectedStartDate) {
          newDate["end_month"] = value.month() + 1;
          newDate["end_year"] = value.year();
        } else {
          window.alert("Please input valid end date");
          newDate["end_month"] = "";
          newDate["end_year"] = "";
          setSelectedEndDate(null);
        }
        break;
    }
    setSelectedValues({ ...newDate });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={styles.container}>
        <div className={styles.head}>
          <Typography variant="h4" className={styles.title}>
            Work Experience
          </Typography>
          <Button
            disabled={experienceData.length > 1}
            variant="outlined"
            className={styles.addBtn}
            onClick={() => {
              setInputValues(template);
              setDialogOpen(true);
              setPostSubmit(true);
            }}
          >
            <AddCircleRounded className={styles.addIcon} />
            ADD WORK EXPERIENCE
          </Button>
        </div>
        <div className={styles.content}>
          {experienceData.length < 1 ? (
            <div>
              <p>
                No Work Experience Yet, Add your work experience to get more job
                opportunity
              </p>
            </div>
          ) : (
            experienceData.map((data, i) => (
              <div key={i} className={styles.itemWrapper}>
                <div className={styles.itemTitle}>
                  <Typography className={styles.itemText}>
                    <b style={{ marginRight: 5 }}>{data.company}</b>
                    {" - " + data.occupation}
                  </Typography>
                  <IconButton
                    disableRipple
                    onClick={() => handleClickItem(1, data)}
                    className={styles.moreIcon}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
                <Typography className={styles.itemText}>
                  <img src={Images.clock_icon} style={{ marginRight: "5px" }} />
                  {moment(data?.duration?.from)
                    .locale("en")
                    .format("MMMM yyyy")}
                  {data.is_currently_work
                    ? " - Currently work here"
                    : " - " +
                      moment(data?.duration?.to)
                        .locale("en")
                        .format("MMMM yyyy")}
                </Typography>
                <Typography className={styles.itemText}>
                  <img src={Images.file_icon} style={{ marginRight: "5px" }} />
                  {data.summary.substring(0, 150) || "-"}
                </Typography>
              </div>
            ))
          )}
        </div>
        <DefaultDialog isOpen={dialogOpen} onClose={handleClose}>
          <Typography className={styles.dialogTitle}>
            {postSubmit ? "Add" : "Edit"} Work Experience
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.formGroup}>
              <InputLabel htmlFor="company" className={styles.inputLabel}>
                Company
              </InputLabel>
              <OutlinedInput
                required
                disabled={formik.isSubmitting}
                className={styles.input}
                id="company"
                placeholder="Company"
                value={formik.values["company"]}
                onChange={formik.handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <InputLabel htmlFor="occupation" className={styles.inputLabel}>
                Position
              </InputLabel>
              <OutlinedInput
                required
                disabled={formik.isSubmitting}
                className={styles.input}
                id="occupation"
                placeholder="Position"
                value={formik.values["occupation"]}
                onChange={formik.handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <InputLabel className={styles.inputLabel}>Start Date</InputLabel>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <DatePicker
                  autoOk
                  minDate={moment("1970-01-01")}
                  maxDate={moment()}
                  variant="dialog"
                  id="start_month"
                  placeholder="Month"
                  disabled={formik.isSubmitting}
                  value={selectedStartDate}
                  format="MM/yyyy"
                  required
                  views={["year", "month"]}
                  onChange={(val) => {
                    setSelectedStartDate(val);
                    handleDateChange("start_date", val);
                  }}
                  TextFieldComponent={(props) => (
                    <OutlinedInput
                      {...props}
                      style={{ marginRight: 10 }}
                      className={styles.input}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <InputLabel className={styles.inputLabel}>End Date</InputLabel>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <DatePicker
                  autoOk
                  maxDate={moment()}
                  minDate={moment(selectedStartDate).add(1, "month")}
                  variant="dialog"
                  id="end_month"
                  placeholder="Month"
                  disabled={
                    formik.values["is_currently_work"] || formik.isSubmitting
                  }
                  required={!formik.values["is_currently_work"]}
                  views={["year", "month"]}
                  value={selectedEndDate}
                  format="MM/yyyy"
                  onChange={(val) => {
                    setSelectedEndDate(val);
                    handleDateChange("end_date", val);
                  }}
                  TextFieldComponent={(props) => (
                    <OutlinedInput
                      {...props}
                      style={{ marginRight: 10 }}
                      className={styles.input}
                    />
                  )}
                />
              </div>
              <FormControlLabel
                disabled={formik.isSubmitting}
                control={
                  <Checkbox
                    checked={formik.values["is_currently_work"]}
                    onChange={() =>
                      formik.setFieldValue(
                        "is_currently_work",
                        !formik.values["is_currently_work"]
                      )
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="I currently work here"
              />
            </div>
            <div className={styles.formGroup}>
              <InputLabel htmlFor="summary" className={styles.inputLabel}>
                Summary
              </InputLabel>
              <OutlinedInput
                disabled={formik.isSubmitting}
                style={{ height: 400 }}
                className={styles.input}
                id="summary"
                placeholder="Summary"
                multiline
                rows={10}
                value={formik.values["summary"]}
                onChange={formik.handleChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "65px 0 10px 0",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={formik.isSubmitting}
                onClick={handleClose}
                variant="outlined"
                style={{ marginRight: 20 }}
                className={clsx(styles.btn, styles.grayBtn)}
              >
                CANCEL
              </Button>
              <Button
                disabled={formik.isSubmitting}
                type="submit"
                style={{ marginLeft: 20 }}
                className={clsx(styles.btn, styles.blueBtn)}
              >
                SAVE CHANGES
              </Button>
            </div>
          </form>
        </DefaultDialog>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  users_reducer: state.users_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getExperience: () => dispatch(getExperience()),
  addExperience: (body) => dispatch(addExperience(body)),
  editExperience: (body) => dispatch(editExperience(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceContent);
