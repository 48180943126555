import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "50px 73px",
    "@media (max-width: 768px)": {
      padding: 20,
    },
    backgroundColor: "white",
  },
  section: {
    flex: 1,
    margin: "10px 0",
  },
  firstSection: {
    marginRight: 50,
    "@media (max-width: 768px)": {
      marginRight: 0,
      marginBottom: 50,
      textAlign: "start",
    },
  },
  footerSection: {
    display: "flex",
    flexDirection: "row",
    // marginLeft: 50,
    // "@media (max-width: 768px)": {
    //   margin: "0 25px",
    // },
  },
  industryWrapper: {
    "@media (max-width: 415px)": {
      columnCount: 2,
      columnGap: 20,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  center: {
    display: "flex",
    margin: "0 auto",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      margin: 0,
    },
  },
  boldCopy: {
    fontWeight: "bold",
  },
  mainCopy: {
    margin: "20px 50px 50px 0",
    "@media (max-width: 768px)": {
      margin: 0,
    },
  },
  linkGroup: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "0 50px",
    "@media (max-width: 768px)": {
      padding: "0",
      marginRight: "10px",
    },
  },
  redTitle: {
    color: Colors.red,
    fontWeight: 600,
    fontSize: "15px",
  },
  link: {
    color: Colors.footer_link,
    fontSize: "13px",
    margin: "10px 0",
  },
  footnote: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 462px)": {
      flexDirection: "row",
      alignItems: "flex-start !important",
    },
  },
  footnoteCopyWrapper: {
    "@media (max-width: 461px)": {
      flex: "center",
    },
  },
  footnoteCopy: {
    color: Colors.light_gray,
    fontSize: "13px",
    margin: 0,
    textAlign: "center",
    "@media (max-width: 768px)": {
      textAlign: "left",
      margin: "10px 0 !important",
    },
  },
  footnoteLink: {
    margin: "0 20px !important",
    "@media (max-width: 768px)": {
      margin: "10px 0 !important",
    },
  },
  socmedWrapper: {
    "@media (max-width: 416px)": {
      textAlign: "center",
    },
  },
});

export default useStyles;
