import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";

export const getIndustryPrefs = () => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_INDUSTRY_PREFS });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const response = await client.get(
      `${Api.job_master}?type=Industry_Preference`
    );
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_INDUSTRY_PREFS_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_INDUSTRY_PREFS_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_INDUSTRY_PREFS_FAILED,
      payload: {
        message: "Failed Request Industry Preference",
        status: response?.status,
      },
    });
  }
};

export const getJobTypes = () => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_JOB_TYPE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const response = await client.get(`${Api.job_master}?type=Job_Type`);
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_JOB_TYPE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_JOB_TYPE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_JOB_TYPE_FAILED,
      payload: {
        message: "Failed Request Job Types",
        status: response?.status,
      },
    });
  }
};

export const getLocations = () => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_LOCATION });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const response = await client.get(`${Api.job_master}?type=Location`);
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_LOCATION_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_LOCATION_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_LOCATION_FAILED,
      payload: { message: "Failed Request Location", status: response?.status },
    });
  }
};

export const getWorkExperience = () => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_WORK_EXP });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const response = await client.get(`${Api.job_master}?type=Work_Experience`);
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_WORK_EXP_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_WORK_EXP_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_WORK_EXP_FAILED,
      payload: {
        message: "Failed Request Work Experience",
        status: response?.status,
      },
    });
  }
};

export const getSalaryRange = () => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_MIN_SALARY });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/jobs`;
    const response = await client.get(
      `${Api.job_master}?type=Minimum_Salary_Range`
    );
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_MIN_SALARY_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_MIN_SALARY_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_MIN_SALARY_FAILED,
      payload: { message: "Failed Request Salary Range" },
      status: response?.status,
    });
  }
};
