import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  forgotContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "35px",
    marginBottom: "36px",
    [theme.breakpoints.down("md")]: {
      padding: "0 23px",
    },
  },
  forgotBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: "859px",
    height: "432px",
    padding: "58px 111px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      padding: "12px 0",
      boxShadow: "none",
      height: "auto",
    },
  },
  forgotTitle: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "19px",
    color: "#23201D",
  },
  inputText: {
    height: "44px",
    padding: "0 30px",
    fontStyle: "italic",
  },
  forgotButton: {
    marginTop: "20px",
    width: "100%",
    background: "#1875D1",
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: "16px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
  },
}));

export default useStyles;
