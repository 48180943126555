/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import {
  Button,
  LinearProgress,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";
import Alert from "../../../../components/Alert";
import useStyles from "./styles";
import { Colors, Images } from "../../../../themes";
import client from "../../../../utils/service";
import { connect } from "react-redux";
import { Api, ApiConfig } from "../../../../utils/constan";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import DefaultDialog from "../../../../components/DefaultDialog";
import DropboxPicker from "../../../../components/DropboxPicker";

const fileAccept =
  "image/jpg,image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx";

const PortfolioContent = (props) => {
  const styles = useStyles();
  const [alertMessage, setAlertMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [portfolioData, setPortfolioData] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dataGoogleDrive, setDataGoogleDrive] = useState(null);
  const [googleDriveToken, setGoogleDriveToken] = useState(null);

  useEffect(() => {
    if (dataGoogleDrive) {
      uploadFromGoogleDrive(dataGoogleDrive);
    }
  }, [dataGoogleDrive]);

  useEffect(() => {
    getPortfolio();
  }, []);

  const GoogleDriveFunction = {
    setDataGoogleDrive,
    setGoogleDriveToken,
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const onFileChange = (f) => {
    console.log(f.target);
    uploadHandler(f);
    setIsUploading(true);
    setOpenSnackbar(false);
    setIsError(false);
    setUploadDialog(false);
  };

  const deletePortfolio = () => {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .delete(Api.portfolio, {
        headers: {
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
      })
      .then(() => {
        setPortfolioData(null);
      })
      .catch((err) => {
        console.log(err.response);
        setIsError(true);
        setAlertMessage("Failed To Delete File");
        setOpenSnackbar(false);
      });
  };

  const getFileFromDropbox = (file) => {
    uploadFromDropbox(file);
    setUploadDialog(false);
    setIsUploading(true);
    setOpenSnackbar(false);
    setIsError(false);
  };

  const getPortfolio = () => {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .get(Api.portfolio, {
        headers: {
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
      })
      .then((res) => {
        setPortfolioData(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setAlertMessage("Failed To Get File");
        setOpenSnackbar(false);
      });
  };

  const uploadFromGoogleDrive = async (file) => {
    let postData = { ...file, token: googleDriveToken };
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .post(Api.portfolio_gdrive, postData, {
        headers: {
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(() => {
        setAlertMessage("Upload CV Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        getPortfolio();
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setAlertMessage("Failed To Upload File");
        setOpenSnackbar(false);
      });
  };

  const uploadFromDropbox = async (file) => {
    let formData = new FormData();
    formData.append("ext_url", file.link);
    formData.append("ext_filename", file.name);
    formData.append("ext_filesize", file.bytes);
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .post(Api.portfolio, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(() => {
        setAlertMessage("Upload CV Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        getPortfolio();
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setAlertMessage(
          "Max file size is 10 MB & Acceptable file types are .pdf, .jpg, .jpeg, .png, .docx"
        );
        setOpenSnackbar(true);
        setIsUploading(false);
      });
  };

  const uploadHandler = async (file) => {
    let formData = new FormData();
    formData.append("file", file[0]);
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    client
      .post(Api.portfolio, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.auth_reducer.token}`,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(() => {
        setAlertMessage("Upload CV Success");
        setOpenSnackbar(true);
        setIsUploading(false);
        setUploadProgress(0);
        getPortfolio();
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setAlertMessage(
          "Max file size is 10 MB & Acceptable file types are .pdf, .jpg, .jpeg, .png, .docx"
        );
        setOpenSnackbar(true);
        setUploadProgress(0);
        setIsUploading(false);
      });
  };

  const handleClose = () => {
    setUploadDialog(false);
  };

  const renderUploadContent = () => (
    <div className={styles.content}>
      <Dropzone onDrop={(acceptedFiles) => onFileChange(acceptedFiles)}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section>
            <div
              {...getRootProps()}
              onClick={(e) => {
                e.stopPropagation();
                setUploadDialog(true);
              }}
              style={{
                backgroundColor: isDragActive ? Colors.blue : "white",
              }}
            >
              <input {...getInputProps()} accept={fileAccept} />
              <img src={Images.uploadFile} />
            </div>
          </section>
        )}
      </Dropzone>
      <Button
        className={styles.btn}
        component="span"
        onClick={() => setUploadDialog(true)}
      >
        UPLOAD CV
      </Button>
      <Typography style={{ textAlign: "center" }}>
        *Max file size is 10 MB.
        <br />
        Acceptable file types are .pdf, .jpg, .jpeg, .png, .docx
      </Typography>
    </div>
  );

  const renderContent = (data) => (
    <div className={styles.content}>
      <Paper elevation={3} style={{ width: "100%" }}>
        <div className={styles.pdfContent}>
          <div className={styles.textGroup}>
            <img src={Images.pdfIcon} className={styles.pdfIcon} />
            <div>
              <Typography style={{ fontWeight: 600 }}>
                {data.filename}
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: Colors.light_gray,
                }}
              >
                {(Math.round(data.size) / 1000000).toFixed(2) + " MB"}
              </Typography>
            </div>
          </div>
          <div className={styles.iconGroup}>
            <IconButton onClick={() => window.open(data.url)}>
              <img src={Images.downloadIcon} />
            </IconButton>
            <IconButton onClick={() => setDialogOpen(true)}>
              <img src={Images.trashIcon} style={{ width: 40 }} />
            </IconButton>
          </div>
        </div>
      </Paper>
    </div>
  );

  return (
    <div className={styles.container}>
      <Typography variant="h4" className={styles.title}>
        CV / Portfolio
      </Typography>
      {isUploading ? (
        <LinearProgress variant="determinate" value={uploadProgress} />
      ) : portfolioData ? (
        renderContent(portfolioData)
      ) : (
        renderUploadContent()
      )}
      <Alert
        message={alertMessage}
        isOpen={openSnackbar}
        isError={isError}
        onClose={onCloseSnackBar}
      />
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          deletePortfolio();
          setDialogOpen(false);
        }}
      />
      <DefaultDialog isOpen={uploadDialog} onClose={handleClose}>
        <DropboxPicker
          onSuccess={getFileFromDropbox}
          onFile={onFileChange}
          googleDrive={GoogleDriveFunction}
          hideDialog={handleClose}
        />
      </DefaultDialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth_reducer: state.auth_reducer,
});

export default connect(mapStateToProps, null)(PortfolioContent);
