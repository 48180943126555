import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";
// import { getAllCookies } from "../utils/cookieConfig";
// const cookieData = getAllCookies();

// PROFILE PROGRESS
export const getProfileProgress = () => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.REQUEST_PROGRESS });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.get(Api.profileProgress, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data?.profile_completeness;
      dispatch({
        type: ActionType.REQUEST_PROGRESS_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_PROGRESS_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_PROGRESS_FAILED,
      payload: {
        message: "Failed Request Progress Data",
        status: response?.status,
      },
    });
  }
};

// PROFILE
export const getProfile = () => async (dispatch, getState) => {
  const state = getState().auth_reducer;
  dispatch({ type: ActionType.REQUEST_PROFILE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    // const headers = {
    //   Authorization: `Bearer ${cookieData.qareer_token}`,
    // };
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.get(Api.profile, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_PROFILE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_PROFILE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_PROFILE_FAILED,
      payload: {
        message: "Failed Request Profile Data",
        status: response?.status,
      },
    });
  }
};

export const editProfile = (body) => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.EDIT_PROFILE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.put(Api.profile, body, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.EDIT_PROFILE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.EDIT_PROFILE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.EDIT_PROFILE_FAILED,
      payload: { message: "Failed Edit Profile", status: response?.status },
    });
  }
};

export const uploadAvatar = (body) => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.UPLOAD_AVATAR });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.put(Api.uploadAvatar, body, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.UPLOAD_AVATAR_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.UPLOAD_AVATAR_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.UPLOAD_AVATAR_FAILED,
      payload: { message: "Failed Edit Profile", status: response?.status },
    });
  }
};

// EXPERIENCE
export const getExperience = () => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.REQUEST_EXPERIENCE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.get(Api.work_experiences, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data[0]?.Experience_Details;
      dispatch({
        type: ActionType.REQUEST_EXPERIENCE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_EXPERIENCE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_EXPERIENCE_FAILED,
      payload: {
        message: "Failed Request Experience Data",
        status: response?.status,
      },
    });
  }
};

export const editExperience = (body) => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.EDIT_EXPERIENCE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.put(Api.work_experiences, body, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data[0]?.Experience_Details;
      dispatch({
        type: ActionType.EDIT_EXPERIENCE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.EDIT_EXPERIENCE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.EDIT_EXPERIENCE_FAILED,
      payload: { message: "Failed Edit Experience", status: response?.status },
    });
  }
};

export const addExperience = (body) => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.ADD_EXPERIENCE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.post(Api.work_experiences, body, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data[0]?.Experience_Details;
      dispatch({
        type: ActionType.ADD_EXPERIENCE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.ADD_EXPERIENCE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.ADD_EXPERIENCE_FAILED,
      payload: { message: "Failed Add Experience", status: response?.status },
    });
  }
};

// EDUCATIONS
export const getEducations = () => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.REQUEST_EDUCATION });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.get(Api.educations, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data[0]?.Educational_Details;
      dispatch({
        type: ActionType.REQUEST_EDUCATION_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_EDUCATION_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_EDUCATION_FAILED,
      payload: {
        message: "Failed Request Educations Data",
        status: response?.status,
      },
    });
  }
};

export const editEducations = (body) => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.EDIT_EDUCATION });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.put(Api.educations, body, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data[0]?.Educational_Details;
      dispatch({
        type: ActionType.EDIT_EDUCATION_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.EDIT_EDUCATION_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.EDIT_EDUCATION_FAILED,
      payload: { message: "Failed Edit Education", status: response?.status },
    });
  }
};

export const addEducations = (body) => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.ADD_EDUCATION });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.post(Api.educations, body, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data[0]?.Educational_Details;
      dispatch({
        type: ActionType.ADD_EDUCATION_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.ADD_EDUCATION_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.ADD_EDUCATION_FAILED,
      payload: { message: "Failed Add Educations", status: response?.status },
    });
  }
};

// LANGUAGES
export const getLanguages = () => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.REQUEST_LANGUAGE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.get(Api.languages, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data?.languages;
      dispatch({
        type: ActionType.REQUEST_LANGUAGE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_LANGUAGE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_LANGUAGE_FAILED,
      payload: {
        message: "Failed Request Languages Data",
        status: response?.status,
      },
    });
  }
};

export const editLanguages = (body) => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.EDIT_LANGUAGE });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.put(Api.languages, body, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data?.languages;
      dispatch({
        type: ActionType.EDIT_LANGUAGE_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.EDIT_LANGUAGE_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.EDIT_LANGUAGE_FAILED,
      payload: {
        message: "Failed Edit Languages Data",
        status: response?.status,
      },
    });
  }
};

// PORTFOLIO
export const getPortfolio = () => async (dispatch, getState) => {
  const state = getState().auth_reducer;

  dispatch({ type: ActionType.REQUEST_PORTFOLIO });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/users`;
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };
    const response = await client.get(Api.portfolio, { headers });
    const message = response?.data?.meta?.message;
    const refresh_token = response?.data?.meta?.refresh_token;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_PORTFOLIO_SUCCESS,
        payload: { data, message, refresh_token },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_PORTFOLIO_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    const { response } = error;
    dispatch({
      type: ActionType.REQUEST_PORTFOLIO_FAILED,
      payload: {
        message: "Failed Request Portfolio Data",
        status: response?.status,
      },
    });
  }
};

export const clearData = () => (dispatch) => {
  dispatch({
    type: ActionType.CLEAR_USER_DATA,
  });
};
