import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import useStyles from "./SignupStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useLocation, useHistory } from "react-router-dom";

const EmailConfirmation = () => {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.success) {
      history.replace("/signup");
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.signupContainer}>
        <Paper className={styles.emailConfirmationBox}>
          <Typography>Your registration has been successful.</Typography>
          <Typography style={{ marginTop: "20px" }}>
            We have sent an activation email to the address that you gave us.
          </Typography>
          <Typography style={{ marginTop: "20px" }}>
            Click on the link in the email to activate your account, so you can
            apply for our latest roles.
          </Typography>
          <Typography style={{ marginTop: "40px" }}>
            Thank you, The Linardi Associates Team
          </Typography>
        </Paper>
      </div>
      <Footer />
    </div>
  );
};

export default EmailConfirmation;
