import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  Divider as MuiDivider,
  Checkbox,
} from "@material-ui/core";
import { LinkedIn } from "react-linkedin-login-oauth2";
import Alert from "../../components/Alert";

import { useHistory, Link } from "react-router-dom";

import { useFormik } from "formik";
import useStyles from "./SigninStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Divider = ({ children, ...props }) => (
  <Grid container alignItems="center" spacing={2} {...props}>
    <Grid item xs>
      <MuiDivider style={{ backgroundColor: "#808080" }} />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <MuiDivider style={{ backgroundColor: "#808080" }} />
    </Grid>
  </Grid>
);

const SigninScreen = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    setOpenSnackbar(false);
    setErrorMessage("Incorrect email or password");
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      setHasRendered(true);
      setOpenSnackbar(false);
      props.doLogin(values);
    },
  });

  useEffect(() => {
    if (props.auth_reducer.token) {
      history.replace("/");
    }
  }, [props.auth_reducer]);

  useEffect(() => {
    let loading = props.auth_reducer.loading;
    let error = props.auth_reducer.error;

    if (!loading && error & hasRendered) {
      setErrorMessage("Incorrect email or password");
      setOpenSnackbar(true);
      formik.setSubmitting(false);
    }
  }, [props.auth_reducer.loading]);

  const handleSuccess = (data) => {
    props.doLinkedinLogin(data?.code);
    formik.setSubmitting(true);
    setErrorMessage(null);
  };

  const handleFailure = (error) => {
    setErrorMessage("Login With Linkedin Failed, Please try again");
    setOpenSnackbar(true);
    console.log(error);
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.signinContainer}>
        <Paper className={styles.signinBox}>
          <Typography className={styles.signinTitle}>
            Sign In to Linardi Associates
          </Typography>
          <LinkedIn
            // eslint-disable-next-line no-undef
            clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
            disabled={formik.isSubmitting}
            onFailure={handleFailure}
            onSuccess={handleSuccess}
            redirectUri={`${window.location.origin}/signin/linkedin`}
            scope="r_emailaddress r_liteprofile"
            renderElement={({ onClick, disabled }) => (
              <Button
                onClick={onClick}
                disabled={disabled}
                className={styles.signinLinkedin}
              >
                <img
                  src="/assets/icons/linkedin.png"
                  style={{ marginRight: "13px" }}
                />{" "}
                Sign IN with LinkedIn
              </Button>
            )}
          />
          <Divider className={styles.signinDivider}>
            <Typography className={styles.textDivider}>
              or sign in using email
            </Typography>
          </Divider>
          <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  placeholder="Email"
                  variant="outlined"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  inputProps={{
                    className: styles.inputText,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password"
                  placeholder="Password"
                  type="password"
                  variant="outlined"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  inputProps={{
                    className: styles.inputText,
                  }}
                />
              </Grid>
            </Grid>
            <div className={styles.signinAgreement}>
              <Checkbox size="medium" color="primary" />
              <Typography>Stay signed in</Typography>
            </div>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              className={styles.signinButton}
            >
              SIGN IN
            </Button>
            <div className={styles.signinText}>
              <Link to="/forgot-password" className={styles.agreementLink}>
                Forgot password?
              </Link>
              <Typography style={{ marginTop: "22px" }}>
                Don&apos;t have an account?&nbsp;
                <Link to="/signup" className={styles.agreementLink}>
                  Sign Up
                </Link>
              </Typography>
            </div>
          </form>
        </Paper>
      </div>

      <Alert
        message={errorMessage}
        isOpen={openSnackbar}
        isError={true}
        onClose={onCloseSnackBar}
      />

      <Footer />
    </div>
  );
};

export default SigninScreen;
