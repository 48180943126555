import React from "react";
import PropTypes from "prop-types";

import Moment from "react-moment";
import "moment-timezone";

import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

import parse from "html-react-parser";

import { makeStyles } from "@material-ui/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    // margin: "20px 12px",
    borderRadius: "5px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  content: {
    padding: "33px 28px 0px 28px",
  },
  image: {
    width: "100%",
    height: "277px",
    borderRadius: "5px",
  },
  articleTitle: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#23201D",
    wordSpacing: "0.15em",
    // maxWidth: "100%",
    // display: "-webkit-box",
    // WebkitBoxOrient: "vertical",
    // WebkitLineClamp: 3,
    // overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  articleDate: {
    display: "flex",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
    color: "#808080",
    marginTop: "28px",
    marginBottom: "20px",
  },
  articleDescription: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#23201D",
    textAlign: "justify",
    wordBreak: "break-all",
    "@media (max-width: 416px)": {
      maxWidth: "100%",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});

function ArticleCard({ article, loading = false }) {
  const classes = useStyles();

  return (
    <a href={article?.link}>
      <Card className={classes.root}>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={277}
          />
        ) : (
          <CardMedia
            className={classes.image}
            image={article.image_url}
            title={article.title}
          />
        )}
        <CardContent className={classes.content}>
          {loading ? (
            <>
              <Skeleton animation="wave" height={30} width={"100%"} />
              <Skeleton animation="wave" height={30} width={"80%"} />
            </>
          ) : (
            <a href={article.link} className={classes.articleTitle}>
              {article.title}
            </a>
          )}
          <Typography variant="span" className={classes.articleDate}>
            {loading ? (
              <Skeleton animation="wave" height={25} width={100} />
            ) : (
              <Moment format="DD MMMM YYYY" locale="en">
                {article.date}
              </Moment>
            )}
          </Typography>
          <Typography variant="p" className={classes.articleDescription}>
            {loading ? (
              <>
                <Skeleton animation="wave" height={15} width={"100%"} />
                <Skeleton animation="wave" height={15} width={"100%"} />
                <Skeleton animation="wave" height={15} width={"100%"} />
                <Skeleton animation="wave" height={15} width={"100%"} />
                <Skeleton animation="wave" height={15} width={"80%"} />
              </>
            ) : (
              parse(article.excerpt)
            )}
          </Typography>
        </CardContent>
      </Card>
    </a>
  );
}

ArticleCard.propTypes = {
  article: PropTypes.object,
  loading: PropTypes.bool,
};

export default ArticleCard;
