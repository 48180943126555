import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import authHOC from "./utils/authHOC";
import Home from "./pages/home/container";
import BlogScreen from "./pages/blogs/BlogScreen";
import Signin from "./pages/signin/container";
import ForgotPassword from "./pages/forgotPassword/container";
import ResetPassword from "./pages/resetPassword/container";
import Signup from "./pages/signup/container";
import EmailConfirmation from "./pages/signup/EmailConfirmationScreen";
import SetupProfile from "./pages/signup/profile/container";
import ProfileScreen from "./pages/profile/ProfileScreen";
import InquiryScreen from "./pages/inquiry/container";
import JobScreen from "./pages/jobs/container";
import SearchScreen from "./pages/search/container";
import ContactScreen from "./pages/contact/container";
import JobDetail from "./pages/JobDetail/container";
import TermConditionScreen from "./pages/termCondition/TermConditionScreen";
import PrivacyPolicyScreen from "./pages/privacyPolicy/PrivacyPolicyScreen";
import AboutScreen from "./pages/about/AboutScreen";
import SettingsScreen from "./pages/settings/SettingsScreen";
import LinkedInPopUp from "react-linkedin-login-oauth2/lib/LinkedInPopUp";
import Applications from "./pages/profile/menu/Applications";
import SavedJobs from "./pages/profile/menu/SavedJobs";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/aboutus" component={AboutScreen} />
        <Route path="/terms-conditions" component={TermConditionScreen} />
        <Route path="/privacy-policy" component={PrivacyPolicyScreen} />
        <Route path="/contact" component={ContactScreen} />
        <Route path="/inquiry" component={InquiryScreen} />
        <Route path="/reset-password/:key" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/blogs" component={BlogScreen} />
        <Route path="/signin" exact component={Signin} />
        <Route path="/signin/linkedin" exact component={LinkedInPopUp} />
        <Route path="/search" component={SearchScreen} />
        <Route path="/jobs/detail/:job_id" component={JobDetail} />
        <Route path="/jobs" component={JobScreen} />
        <Route path="/search" component={SearchScreen} />
        <Route path="/profile" component={authHOC(ProfileScreen)} />
        <Route path="/settings" component={authHOC(SettingsScreen)} />
        <Route path="/signup/profile/:token" exact component={SetupProfile} />
        <Route path="/signup/success" component={EmailConfirmation} />
        <Route path="/signup" component={Signup} />
        <Route path="/savedjobs" component={SavedJobs} />
        <Route path="/appliedjobs" component={Applications} />

        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
