import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: 50,
  },
  addBtn: {
    height: 44,
    fontWeight: "bold",
    borderWidth: 2,
    borderRadius: 5,
    color: Colors.blue,
    borderColor: Colors.blue,
  },
  addIcon: {
    fontSize: 24,
    marginRight: 10,
    color: Colors.blue,
  },
  langGroup: {
    display: "flex",
    flexDirection: "row",
    margin: "20px 0",
  },
  langItem: {
    width: 470,
    height: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  langText: {
    marginLeft: 40,
    fontSize: 18,
  },
  editIcon: {
    color: Colors.light_gray,
    fontSize: 24,
    marginRight: 20,
  },
  checkIcon: {
    color: Colors.blue,
    marginRight: 20,
  },
  btn: {
    width: 200,
    height: 44,
    fontSize: 16,
    fontWeight: "bold",
  },
  blueBtn: {
    color: "white",
    backgroundColor: Colors.blue,
    "&:hover": {
      backgroundColor: Colors.blue,
    },
  },
});

export default useStyles;
