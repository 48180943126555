import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: 50,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  pdfContent: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  textGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconGroup: {
    display: "flex",
  },
  pdfIcon: {
    width: 60,
    height: 60,
    marginRight: 20,
  },
  btn: {
    margin: "30px 0",
    width: 200,
    fontWeight: 600,
    color: "white",
    backgroundColor: Colors.blue,
    "&:hover": {
      backgroundColor: Colors.blue,
    },
  },
});

export default useStyles;
