import React, { useEffect, useState } from "react";
import { Typography, Button, useMediaQuery } from "@material-ui/core";
import useStyles from "./styles";
import JobCard from "../../../../components/JobCard";
import { connect } from "react-redux";
import { getSavedJobs } from "../../../../actions/SavedJobActions";
import { useHistory } from "react-router";

import Navbar from "../../../../components/Navbar";
import Footer from "../../../../components/Footer";

const SavedJobsContent = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const [savedJobs, setSavedJobs] = useState(null);
  const md = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    props.getSavedJobs();
  }, []);

  useEffect(() => {
    const jobs = props.saved_jobs.data;
    if (jobs && jobs.length > 0) {
      const newJobs = jobs.map((job) => {
        const newJob = { ...job, Is_Saved: true };
        return newJob;
      });
      setSavedJobs(newJobs);
    } else {
      setSavedJobs(null);
    }
  }, [props.saved_jobs.data]);

  console.log(location.pathname);

  return (
    <>
      {location.pathname == "/profile" ? null : <Navbar />}
      <div
        className={styles.container}
        style={{
          marginLeft: md == true ? 20 : 0,
          marginTop: md == true ? 20 : 0,
          width: md == true ? "87%" : null,
        }}
      >
        <Typography variant="h4" className={styles.title}>
          Saved Jobs
        </Typography>
        {savedJobs && savedJobs.length > 0 ? (
          savedJobs.map((item) => (
            <>
              <JobCard
                style={{ width: 0 }}
                fullwidth={true}
                key={item.id}
                job={item}
                onDelete={() => props.getSavedJobs()}
              />
            </>
          ))
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => history.push("/jobs")}
              className={styles.btn}
            >
              <Typography>See Other Job Vacancy</Typography>
            </Button>
          </div>
        )}
      </div>
      {location.pathname == "/profile" ? null : <Footer />}
    </>
  );
};

const mapStateToProps = (state) => ({
  saved_jobs: state.saved_jobs,
});

const mapDispatchToProps = (dispatch) => ({
  getSavedJobs: () => dispatch(getSavedJobs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedJobsContent);
