import Cookies from "universal-cookie";

var cookies = new Cookies();

const cookieName = {
  qareer_token: "qareer_token",
  qareer_user: "qareer_user",
};

export function setCookieValue(token, data, expDate) {
  var expires = new Date(expDate * 1000);
  cookies.set(cookieName.qareer_token, token, { expires: expires });
  cookies.set(cookieName.qareer_user, data, { expires: expires });
}

export function getAllCookies() {
  return cookies.getAll();
}

export function checkCookie() {
  let data = cookies.getAll();
  return data !== undefined ? true : false;
}

export function deleteAllCookie() {
  cookies.remove(cookieName.qareer_token);
  cookies.remove(cookieName.qareer_user);
}
