import React, { useEffect, useState } from "react";
import { Typography, Grid, Link } from "@material-ui/core";
// import FAB from "../../components/FAB";
import useStyles from "./ContactStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const ContactScreen = (props) => {
  const styles = useStyles();
  const [data, setData] = useState(null);

  useEffect(() => {
    props.getParameters();
  }, []);

  // const sendMail = () => {
  //   var link = "mailto:admin@qareer.com";
  //   window.location.href = link;
  // };

  useEffect(() => {
    if (props.parameters.data.length > 0) {
      let _data = [];
      props.parameters.data.map((item) => {
        if (item?.is_active && item?.code == "CU") {
          _data.push(item);
        }
      });

      if (_data.length > 0) {
        setData(_data.sort((a, b) => a?.seq_no - b?.seq_no));
      }
    }
  }, [props.parameters]);

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.contentContainer}>
        <Typography variant="h5" className={styles.containerTitle}>
          Contact Us - Jakarta
        </Typography>
        <div className={styles.contentWrapper}>
          {data && (
            <div
              className={styles.addressContainer}
              style={{ marginBottom: 40 }}
            >
              {data.map((item, i) => (
                <Grid key={i} container spacing={3}>
                  <Grid item xs={4}>
                    <Typography className={styles.text}>
                      {item.name === "Email" ? null : item.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {item.value.split(`,`).map((x, y) => (
                      <Typography className={styles.text} key={y}>
                        {x === "admin@qareer.com" ? null : x}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography className={styles.text}>Email</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    className={styles.email}
                    href={"mailto:admin@qareer.com"}
                    target={"_blank"}
                    underline={"hover"}
                  >
                    admin@qareer.com
                  </Link>
                </Grid>
              </Grid>
            </div>
          )}

          <div
            className={styles.addressContainer}
            style={{ alignItems: "flex-end" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.3205183694704!2d106.81262131536994!3d-6.221399162666764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f653c3cfb30b%3A0xe2b5bebdb5cfd82b!2sPT.%20Qerja%20Manfaat%20Bangsa!5e0!3m2!1sen!2sid!4v1615188546919!5m2!1sen!2sid"
              className={styles.map}
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      {/* <FAB /> */}
      <Footer />
    </div>
  );
};

export default ContactScreen;
