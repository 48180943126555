import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "468px",
    boxSizing: "border-box",
    padding: "36px",
    // margin: "20px 12px",
    borderRadius: "5px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: "0",
      height: "315px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
    flex: "0.5",
    padding: "0 !important",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
      padding: "18px 21px !important",
    },
  },
  image: {
    flex: "0.5",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      // height: "158px",
    },
  },
  articleTags: {
    color: "#1875D1",
    fontSize: "18px",
    lineHeight: "20px",
    marginBottom: "28px",
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  articleTitle: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#23201D",
    wordSpacing: "0.15em",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
    },
  },
  articleDate: {
    display: "flex",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
    color: "#808080",
  },
  articleDescription: {
    flexGrow: 1,
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "justify",
    color: "#23201D",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export default useStyles;
