import { combineReducers } from "redux";
import { jobMasterReducer } from "./JobMasterReducer";
import { parametersReducer } from "./ParameterReducer";
import { authReducer } from "./AuthReducer";
import { searchReducer } from "./SearchReducer";
import { registerReducer } from "./RegisterReducer";
import { usersReducer } from "./UsersReducer";
import { jobDetailReducer } from "./JobDetailReducer";
import { recommendationReducer } from "./RecommendationReducer";
import { applyJobReducer } from "./ApplyJobReducer";
import { savedJobReducer } from "./SavedJobReducer";
import { popularReducer } from "./PopularJobReducer";
import { forgotPasswordReducer } from "./ForgotPasswordReducer";
import { resetPasswordReducer } from "./ResetPasswordReducer";

export default combineReducers({
  parameters: parametersReducer,
  job_master: jobMasterReducer,
  job_detail: jobDetailReducer,
  auth_reducer: authReducer,
  search_reducer: searchReducer,
  recommendation: recommendationReducer,
  register_reducer: registerReducer,
  users_reducer: usersReducer,
  apply_jobs: applyJobReducer,
  saved_jobs: savedJobReducer,
  popular_jobs: popularReducer,
  forgot_password: forgotPasswordReducer,
  reset_password: resetPasswordReducer,
});
