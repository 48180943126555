import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  itemContainer: {
    display: "flex !important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  itemImageContainer: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 12,
    "@media (max-width: 768px)": {
      flexDirection: "column !important",
      alignItems: "space-evenly",
    },
  },
  itemTitle: {
    color: "#23201D",
    margin: "20px 0",
    fontStyle: "italic",
    fontSize: "16px",
    fontWeight: "300",
  },
  itemSubtitle: {
    color: "#23201D",
    fontSize: "14px",
    fontWeight: "300",
  },
});

export default useStyles;
