import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";

export const doResetPassword = (body) => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_RESET_PASSWORD });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/auth`;
    const response = await client.post(Api.reset_password, body);
    const message = response?.data?.meta?.message;

    if (response?.status === 200) {
      dispatch({
        type: ActionType.REQUEST_RESET_PASSWORD_SUCCESS,
        payload: { message },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_RESET_PASSWORD_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.REQUEST_RESET_PASSWORD_FAILED,
      payload: { message: "Failed Request Reset Password" },
    });
  }
};
