import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  searchBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    width: "60%",
    height: "72px",
    padding: "14px 24px",
    marginTop: "21px",
    [theme.breakpoints.down("md")]: {
      background: "transparent",
      boxShadow: "none",
      border: "none",
      flexDirection: "column",
      width: "100%",
      height: "auto",
    },
  },
  moreContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonMore: {
    padding: "auto 39px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "500",
    color: "#FFFFFF",
    background: "#1875D1",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    width: "146px",
    height: "44px",
  },
  searchButton: {
    color: "#FFFFFF",
    background: "#1875D1",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    padding: "12px 49px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "15px",
    },
  },
  searchArticle: {
    display: "flex",
    flexDirection: "column",
    background: "url('/assets/images/img_bg.png') no-repeat center",
    backgroundSize: "cover",
    height: "324px",
    padding: "0 73px",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      background: "#DE1A1B",
      padding: "20px 22px",
    },
  },
  searchTitle: {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "21px",
    lineHeight: "20px",
  },
  containerTitle: {
    marginLeft: "36px",
    marginBottom: "20px",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "20px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  blogsContainer: {
    padding: "85px 73px",
    [theme.breakpoints.down("md")]: {
      padding: "24px 23px",
    },
  },
}));

export default useStyles;
