import React from "react";
import { Paper, Typography, Button } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import CheckRoundedIcon from "@material-ui/icons/CheckRounded";

import useStyles from "../SetupProfileStyles";

const FinishScreen = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Paper className={styles.signupBox}>
      <Typography className={styles.signupTitle}>Finish</Typography>
      <Typography className={styles.signupTitle}>
        Thank You for Signing Up
      </Typography>
      <CheckRoundedIcon style={{ width: "109px", height: "82px" }} />
      <Button className={styles.blueButton} onClick={() => history.push("/")}>
        Continue
      </Button>
    </Paper>
  );
};

export default FinishScreen;
