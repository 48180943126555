import React from "react";
import { Button } from "@material-ui/core";
import DropboxChooser from "react-dropbox-chooser";
import GooglePicker from "react-google-picker";

import { AppKeys } from "../../utils/constan";
import useStyles from "./styles";

const allowedExtensions = [".pdf", ".jpg", ".jpeg", ".png", ".doc", ".docx"];
const fileAccept =
  "image/jpg,image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx";

const DropboxPicker = (props) => {
  const styles = useStyles();

  const handleChangeDrivePicker = (data) => {
    switch (data?.action) {
      case "loaded": {
        props.hideDialog();
        break;
      }
      case "picked": {
        const doc = data?.docs[0];
        const fileData = {
          url: `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`,
          filename: doc.name,
          size: doc.sizeBytes,
        };
        props.googleDrive.setDataGoogleDrive(fileData);
        break;
      }
    }
  };

  const handleAuthDrivePicker = (token) => {
    console.log(token);
    // props.googleDrive.setGoogleDriveToken(token);
  };

  const onCancel = () => {
    console.log("canceled");
  };

  const onSuccess = (files) => {
    const file = files[0];
    props.onSuccess(file);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <DropboxChooser
        appKey={AppKeys.dropboxAppKey}
        success={(files) => onSuccess(files)}
        cancel={() => onCancel()}
        multiselect={false}
        linkType="direct"
        extensions={allowedExtensions}
      >
        <div className={styles.pickerButton}>CHOOSE FROM DROPBOX</div>
      </DropboxChooser>
      <input
        onChange={(e) => props.onFile(e.target.files)}
        accept={fileAccept}
        type="file"
        id="file-input"
        style={{ display: "none" }}
      />
      <label htmlFor="file-input">
        <Button disableRipple className={styles.pickerButton} component="span">
          CHOOSE FROM COMPUTER
        </Button>
      </label>
      <GooglePicker
        clientId={AppKeys.gDriveClientId}
        developerKey={AppKeys.gDriveDeveloperKey}
        scope={["https://www.googleapis.com/auth/drive.readonly"]}
        onChange={(data) => handleChangeDrivePicker(data)}
        onAuthenticate={(token) => handleAuthDrivePicker(token)}
        onAuthFailed={(data) => console.log("on auth failed:", data)}
        multiselect={false}
        navHidden={true}
        authImmediate={false}
        mimeTypes={[
          "image/png",
          "image/jpeg",
          "image/jpg",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
        query={""}
        viewId={"DOCS"}
      >
        <Button disableRipple className={styles.pickerButton} component="span">
          CHOOSE FROM GOOGLE DRIVE
        </Button>
      </GooglePicker>
    </div>
  );
};

export default DropboxPicker;
