import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import useStyles from "./styles";
import { Images } from "../../themes";
import { Typography, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router";
import { getIndustryPrefs } from "../../actions/JobMasterActions";

const Footer = () => {
  const styles = useStyles();
  // const history = useHistory();
  const dispatch = useDispatch();
  const { industry_prefs } = useSelector((state) => state.job_master);
  const [listIndustry, setListIndustry] = useState([]);
  const mobile = useMediaQuery("(max-width:460px)");
  useEffect(() => {
    if (!industry_prefs.length) {
      dispatch(getIndustryPrefs());
    }
  }, []);

  useEffect(() => {
    const filterIndustry = industry_prefs
      .filter((industry) => industry.display_value !== "-None-")
      .slice(0, 8);
    setListIndustry(filterIndustry);
  }, [industry_prefs]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={clsx(styles.section, styles.firstSection)}>
          <img
            src={Images.linardi_logo}
            style={{ width: "144px", height: "72px", marginBottom: "30px" }}
          />
          <Typography
            className={clsx(styles.boldCopy, styles.mainCopy)}
            style={{ fontSize: "16px" }}
          >
            Linardi Associates is a part of PT Qerja Manfaat Bangsa, a startup
            providing end-to-end recruitment solutions for companies and job
            seekers. Linardi Associates provides professional recruitment
            solutions for various functions, industries, and levels,
            specializing in Managers and up to C Levels. Linardi Associates help
            you finds your best talents and dream jobs.
          </Typography>
        </div>

        <div className={clsx(styles.section, styles.footerSection)}>
          <div className={styles.linkGroup}>
            <Typography className={styles.redTitle}>SITE MAP</Typography>
            <Link to="/" className={styles.link}>
              Home
            </Link>
            <Link to="/jobs" className={styles.link}>
              Job
            </Link>
            <Link to="/blogs" className={styles.link}>
              Blog
            </Link>
          </div>

          <div className={styles.linkGroup}>
            <Typography className={styles.redTitle}>COMPANY</Typography>
            <Link to="/aboutus" className={styles.link}>
              About Us
            </Link>
            <Link to="/contact" className={styles.link}>
              Contact Us
            </Link>
            <Link to="/inquiry" className={styles.link}>
              Hiring Inquiry
            </Link>
          </div>
          {!mobile ? (
            <div className={styles.linkGroup}>
              <Typography className={styles.redTitle}>INDUSTRY</Typography>
              {listIndustry.map((industry, index) => (
                <Link
                  key={index}
                  to={{
                    pathname: "/search",
                    state: { industry: industry.display_value },
                  }}
                  className={styles.link}
                >
                  {industry.display_value}
                </Link>
              ))}
            </div>
          ) : null}
        </div>
        {mobile ? (
          <div>
            <Typography className={styles.redTitle}>INDUSTRY</Typography>
            <div className={clsx(styles.section, styles.industryWrapper)}>
              <div className={styles.linkGroup}>
                {listIndustry.map((industry, index) => (
                  <Link
                    key={index}
                    to={{
                      pathname: "/search",
                      state: { industry: industry.display_value },
                    }}
                    className={styles.link}
                  >
                    {industry.display_value}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.socmedWrapper}>
        <Typography style={{ margin: "20px 0" }} className={styles.boldCopy}>
          Connect with us
        </Typography>
        <a
          href="https://www.linkedin.com/company/linardi-associates/"
          target={"_blank"}
          rel="noreferrer"
        >
          <img src={Images.linkedin_logo} style={{ cursor: "pointer" }} />
        </a>
        <a
          href="https://instagram.com/linardiassociates?igshid=MDE2OWE1N2Q="
          target={"_blank"}
          rel="noreferrer"
        >
          <img
            src={Images.instagram}
            style={{
              cursor: "pointer",
              width: "38px",
              height: "38px",
              marginLeft: "11px",
            }}
          />
        </a>
      </div>
      <div>
        <div className={styles.footnote}>
          {mobile ? (
            <>
              <Link
                to="/privacy-policy"
                className={clsx(styles.footnoteCopy, styles.footnoteLink)}
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-conditions"
                className={clsx(styles.footnoteCopy, styles.footnoteLink)}
              >
                Terms and Conditions
              </Link>
            </>
          ) : (
            <>
              <p className={styles.footnoteCopy}>
                © 2022 PT. Qerja Manfaat Bangsa
              </p>

              <Link
                to="/privacy-policy"
                className={clsx(styles.footnoteCopy, styles.footnoteLink)}
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-conditions"
                className={clsx(styles.footnoteCopy, styles.footnoteLink)}
              >
                Terms and Conditions
              </Link>
            </>
          )}
        </div>
        {mobile ? (
          <p className={styles.footnoteCopy}>© 2022 PT. Qerja Manfaat Bangsa</p>
        ) : null}
      </div>
    </div>
  );
};

export default Footer;
