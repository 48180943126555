/* eslint-disable no-undef */
export class ApiConfig {
  static baseUrl = process.env.REACT_APP_BASE_URL;
  static timeOut = process.env.REACT_APP_TIME_OUT;
  static wordpressURL = process.env.REACT_APP_WORDPRESS_URL;
}

export class AppKeys {
  static dropboxAppKey = process.env.REACT_APP_DROPBOX_APP_KEY;
  static gDriveDeveloperKey = process.env.REACT_APP_GDRIVE_DEVELOPER_KEY;
  static gDriveClientId = process.env.REACT_APP_GDRIVE_CLIENT_ID;
}

export class Api {
  static register = "/register";
  static parameters = "/parameters";
  static blogs = "/blogs";
  static login = "/login";
  static register = "/register";
  static forgot_password = "/forgot-password";
  static reset_password = "/reset-password";
  static job_master = "/masters";
  static job_openings = "/openings";
  static jobs = "/jobs";
  static popular_job = "/popular-jobs";
  static applied_jobs = "/applied-jobs";
  static saved_jobs = "/saved-jobs";
  static search = "/search";
  static profile = "/profile";
  static avatar = "/avatar";
  static portfolio = "/portfolio";
  static portfolio_gdrive = "/portfolio-gdrive";
  static educations = "/educations";
  static languages = "/languages";
  static work_experiences = "/work_experiences";
  static logs = "/logs";
  static linkedinCallback = "/linkedin/callback";
  static changePassword = "/change-password";
  static hiringInquiry = "/inquiry";
  static profileProgress = "/profile-completeness";
}

export class JobMasterVariables {
  static min_salary = "Minimum_Salary_Range";
  static job_type = "Job_Type";
  static industry_prefs = "Industry_Preference";
  static location = "Location";
  static work_exp = "Work_Experience";
}

export class ActionType {
  // PARAMETERS
  static REQUEST_PARAMETERS = "REQUEST_PARAMETERS";
  static REQUEST_PARAMETERS_SUCCESS = "REQUEST_PARAMETERS_SUCCESS";
  static REQUEST_PARAMETERS_FAILED = "REQUEST_PARAMETERS_FAILED";

  // AUTH
  static RESET_LOGIN_STATE = "RESET_LOGIN_STATE";
  static REQUEST_LOGIN = "REQUEST_LOGIN";
  static REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS";
  static REQUEST_LOGIN_FAILED = "REQUEST_LOGIN_FAILED";
  static REQUEST_REGISTER = "REQUEST_REGISTER";
  static RESET_REGISTER_STATE = "RESET_REGISTER_STATE";
  static REQUEST_REGISTER_SUCCESS = "REQUEST_REGISTER_SUCCESS";
  static REQUEST_REGISTER_FAILED = "REQUEST_REGISTER_FAILED";
  static REQUEST_LOGOUT = "REQUEST_LOGOUT";
  static RESET_FORGOT_PASSWORD_STATE = "RESET_FORGOT_PASSWORD_STATE";
  static REQUEST_FORGOT_PASSWORD = "REQUEST_FORGOT_PASSWORD";
  static REQUEST_FORGOT_PASSWORD_SUCCESS = "REQUEST_FORGOT_PASSWORD_SUCCESS";
  static REQUEST_FORGOT_PASSWORD_FAILED = "REQUEST_FORGOT_PASSWORD_FAILED";
  static REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
  static REQUEST_RESET_PASSWORD_SUCCESS = "REQUEST_RESET_PASSWORD_SUCCESS";
  static REQUEST_RESET_PASSWORD_FAILED = "REQUEST_RESET_PASSWORD_FAILED";

  // progress
  static REQUEST_PROGRESS = "REQUEST_PROGRESS";
  static REQUEST_PROGRESS_SUCCESS = "REQUEST_PROGRESS_SUCCESS";
  static REQUEST_PROGRESS_FAILED = "REQUEST_PROGRESS_FAILED";

  // User Profile
  static REQUEST_PROFILE = "REQUEST_PROFILE";
  static REQUEST_PROFILE_SUCCESS = "REQUEST_PROFILE_SUCCESS";
  static REQUEST_PROFILE_FAILED = "REQUEST_PROFILE_FAILED";
  static EDIT_PROFILE = "EDIT_PROFILE";
  static EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
  static EDIT_PROFILE_FAILED = "EDIT_PROFILE_FAILED";

  // User Experience
  static REQUEST_EXPERIENCE = "REQUEST_EXPERIENCE";
  static REQUEST_EXPERIENCE_SUCCESS = "REQUEST_EXPERIENCE_SUCCESS";
  static REQUEST_EXPERIENCE_FAILED = "REQUEST_EXPERIENCE_FAILED";
  static EDIT_EXPERIENCE = "EDIT_EXPERIENCE";
  static EDIT_EXPERIENCE_SUCCESS = "EDIT_EXPERIENCE_SUCCESS";
  static EDIT_EXPERIENCE_FAILED = "EDIT_EXPERIENCE_FAILED";
  static ADD_EXPERIENCE = "ADD_EXPERIENCE";
  static ADD_EXPERIENCE_SUCCESS = "ADD_EXPERIENCE_SUCCESS";
  static ADD_EXPERIENCE_FAILED = "ADD_EXPERIENCE_FAILED";
  // User Education
  static REQUEST_EDUCATION = "REQUEST_EDUCATION";
  static REQUEST_EDUCATION_SUCCESS = "REQUEST_EDUCATION_SUCCESS";
  static REQUEST_EDUCATION_FAILED = "REQUEST_EDUCATION_FAILED";
  static EDIT_EDUCATION = "EDIT_EDUCATION";
  static EDIT_EDUCATION_SUCCESS = "EDIT_EDUCATION_SUCCESS";
  static EDIT_EDUCATION_FAILED = "EDIT_EDUCATION_FAILED";
  static ADD_EDUCATION = "ADD_EDUCATION";
  static ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS";
  static ADD_EDUCATION_FAILED = "ADD_EDUCATION_FAILED";
  // User Languages
  static REQUEST_LANGUAGE = "REQUEST_LANGUAGE";
  static REQUEST_LANGUAGE_SUCCESS = "REQUEST_LANGUAGE_SUCCESS";
  static REQUEST_LANGUAGE_FAILED = "REQUEST_LANGUAGE_FAILED";
  static EDIT_LANGUAGE = "EDIT_LANGUAGE";
  static EDIT_LANGUAGE_SUCCESS = "EDIT_LANGUAGE_SUCCESS";
  static EDIT_LANGUAGE_FAILED = "EDIT_LANGUAGE_FAILED";
  // User Portfolio
  static REQUEST_PORTFOLIO = "REQUEST_PORTFOLIO";
  static REQUEST_PORTFOLIO_SUCCESS = "REQUEST_PORTFOLIO_SUCCESS";
  static REQUEST_PORTFOLIO_FAILED = "REQUEST_PORTFOLIO_FAILED";

  // Clear All User Data
  static CLEAR_USER_DATA = "CLEAR_USER_DATA";

  // SEARCH
  static REQUEST_SEARCH = "REQUEST_SEARCH";
  static REQUEST_SEARCH_SUCCESS = "REQUEST_SEARCH_SUCCESS";
  static REQUEST_SEARCH_FAILED = "REQUEST_SEARCH_FAILED";

  // POPULAR
  static REQUEST_POPULAR = "REQUEST_POPULAR";
  static REQUEST_POPULAR_SUCCESS = "REQUEST_POPULAR_SUCCESS";
  static REQUEST_POPULAR_FAILED = "REQUEST_POPULAR_FAILED";

  // RECOMMENDATION
  static REQUEST_RECOMMENDATION_JOB = "REQUEST_RECOMMENDATION_JOB";
  static REQUEST_RECOMMENDATION_JOB_SUCCESS =
    "REQUEST_RECOMMENDATION_JOB_SUCCESS";
  static REQUEST_RECOMMENDATION_JOB_FAILED =
    "REQUEST_RECOMMENDATION_JOB_FAILED";

  // JOB DETAIL
  static REQUEST_JOB_DETAIL = "REQUEST_JOB_DETAIL";
  static REQUEST_JOB_DETAIL_SUCCESS = "REQUEST_JOB_DETAIL_SUCCESS";
  static REQUEST_JOB_DETAIL_FAILED = "REQUEST_JOB_DETAIL_FAILED";

  // JOB DETAIL ACTION (APPLY)
  static REQUEST_APPLY_JOB = "REQUEST_APPLY_JOB";
  static REQUEST_APPLY_JOB_SUCCESS = "REQUEST_APPLY_JOB_SUCCESS";
  static REQUEST_APPLY_JOB_FAILED = "REQUEST_APPLY_JOB_FAILED";

  static REQUEST_LIST_APPLY_JOB = "REQUEST_LIST_APPLY_JOB";
  static REQUEST_LIST_APPLY_JOB_SUCCESS = "REQUEST_LIST_APPLY_JOB_SUCCESS";
  static REQUEST_LIST_APPLY_JOB_FAILED = "REQUEST_LIST_APPLY_JOB_FAILED";

  // JOB DETAIL ACTION (SAVED)
  static REQUEST_SAVED_JOB = "REQUEST_SAVED_JOB";
  static REQUEST_SAVED_JOB_SUCCESS = "REQUEST_SAVED_JOB_SUCCESS";
  static REQUEST_SAVED_JOB_FAILED = "REQUEST_SAVED_JOB_FAILED";

  static REQUEST_LIST_SAVED_JOB = "REQUEST_LIST_SAVED_JOB";
  static REQUEST_LIST_SAVED_JOB_SUCCESS = "REQUEST_LIST_SAVED_JOB_SUCCESS";
  static REQUEST_LIST_SAVED_JOB_FAILED = "REQUEST_LIST_SAVED_JOB_FAILED";

  static REQUEST_UNSAVED_JOB = "REQUEST_UNSAVED_JOB";
  static REQUEST_UNSAVED_JOB_SUCCESS = "REQUEST_UNSAVED_JOB_SUCCESS";
  static REQUEST_UNSAVED_JOB_FAILED = "REQUEST_UNSAVED_JOB_FAILED";

  // JOB MASTER
  static REQUEST_INDUSTRY_PREFS = "REQUEST_INDUSTRY_PREFS";
  static REQUEST_INDUSTRY_PREFS_SUCCESS = "REQUEST_INDUSTRY_PREFS_SUCCESS";
  static REQUEST_INDUSTRY_PREFS_FAILED = "REQUEST_INDUSTRY_PREFS_FAILED";
  static REQUEST_MIN_SALARY = "REQUEST_MIN_SALARY";
  static REQUEST_MIN_SALARY_SUCCESS = "REQUEST_MIN_SALARY_SUCCESS";
  static REQUEST_MIN_SALARY_FAILED = "REQUEST_MIN_SALARY_FAILED";
  static REQUEST_LOCATION = "REQUEST_LOCATION";
  static REQUEST_LOCATION_SUCCESS = "REQUEST_LOCATION_SUCCESS";
  static REQUEST_LOCATION_FAILED = "REQUEST_LOCATION_FAILED";
  static REQUEST_WORK_EXP = "REQUEST_WORK_EXP";
  static REQUEST_WORK_EXP_SUCCESS = "REQUEST_WORK_EXP_SUCCESS";
  static REQUEST_WORK_EXP_FAILED = "REQUEST_WORK_EXP_FAILED";
  static REQUEST_JOB_TYPE = "REQUEST_JOB_TYPE";
  static REQUEST_JOB_TYPE_SUCCESS = "REQUEST_JOB_TYPE_SUCCESS";
  static REQUEST_JOB_TYPE_FAILED = "REQUEST_JOB_TYPE_FAILED";
}
