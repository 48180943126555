import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery, Typography, Grid, Button } from "@material-ui/core";
// import FAB from "../../components/FAB";
import useStyles from "./HomeStyles";
import JobSearch from "../../components/JobSearch";
import BrowseJob from "../../components/BrowseJob";
import Navbar from "../../components/Navbar";
import JobCard from "../../components/JobCard";
import ArticleCard from "../../components/ArticleCard";
import TextCarousel from "../../components/TextCarousel";
import Footer from "../../components/Footer";
import clsx from "clsx";

// import fetchAPI from "../../config/api";
import { ApiConfig } from "../../utils/constan";
import client from "../../utils/service";
import { Images } from "../../themes";

const HomeScreen = (props) => {
  const styles = useStyles();
  const md = useMediaQuery("(max-width:768px)");
  const mobile = useMediaQuery("(max-width:416px)");
  const [articles, setArticles] = useState(null);
  const [HPC, setHPC] = useState(null);
  const [industries, setIndustries] = useState(null);
  const popularJobs = props.popular_jobs?.data;
  const history = useHistory();

  const fetchArticles = async () => {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/blogs`;
    const {
      data: { data: response },
    } = await client.get("/qareer-blog");
    const listArticle = response.splice(0, 3);
    setArticles(listArticle);
    console.log(articles);
  };

  useEffect(() => {
    localStorage.setItem("lastKeyword", "");
    setArticles(null);
    fetchArticles();
    props.getPopularJobs();
    props.getParameters();

    if (props.job_master.industry_prefs.length < 1) {
      props.getIndustryPrefs();
    }
  }, []);

  useEffect(() => {
    if (props.parameters.data.length > 0) {
      let data = [];
      props.parameters.data.map((item) => {
        if (item.code == "HPC" && item.is_active) {
          data.push(item);
        }
      });
      setHPC(data);
    }
  }, [props.parameters]);

  useEffect(() => {
    if (props.job_master.industry_prefs.length > 0) {
      let data = props.job_master.industry_prefs.filter(
        (item) => item.display_value !== "-None-"
      );
      setIndustries(data);
    }
  }, [props.job_master]);

  return (
    <div className={styles.container}>
      <Navbar />
      <Grid container className={styles.wrapperBanner}>
        <Grid item md={7} xs={12}>
          <div className={styles.wrapperBannerTitle}>
            <Typography className={styles.bannerTitle} variant="h1">
              Top Talent.
            </Typography>
            <Typography className={styles.bannerTitle} variant="h1">
              Less Hassle.
            </Typography>
          </div>
          <Typography className={styles.bannerSubtitle}>
            Serves between employers and a professional talents pool in the
            easiest way.
          </Typography>

          <Button
            className={styles.buttonRS}
            variant="contained"
            endIcon={
              <img
                src={Images.next}
                height="14px"
                width="8px"
                style={{ paddingLeft: "5px" }}
              />
            }
            onClick={() => history.push("/inquiry")}
          >
            Partner with us
          </Button>
        </Grid>
      </Grid>
      <div
        className={clsx(styles.bgContainer, md ? styles.bgContainerMd : null)}
      >
        <Typography variant="h5" className={styles.searchTitle}>
          Find your dream job immediately
        </Typography>
        <JobSearch />
        {industries && (
          <BrowseJob
            title={"Browse Jobs By Industry"}
            items={industries.slice(0, 14)}
          />
        )}
      </div>
      <div className={styles.jobsContainer}>
        <div className={styles.jobsContainerHeader}>
          <Typography variant="h5" className={styles.containerTitle}>
            Popular Jobs
          </Typography>
        </div>
        <div className={styles.jobList}>
          {props.popular_jobs.loading ? (
            <>
              <JobCard loading />
              <JobCard loading />
              <JobCard loading />
            </>
          ) : (
            popularJobs.map((job) => (
              <JobCard job={job} key={job.id} showIndustry={true} />
            ))
          )}
        </div>
      </div>
      <div className={styles.whatWeDoContainer}>
        <div className={styles.whatWeDoHeader}>
          {!md ? (
            <Typography className={styles.whatWeDoTitle}>
              At Linardi Associate, our mission is to find your dream job in the
              shortest time possible.
            </Typography>
          ) : mobile ? (
            <Typography className={styles.whatWeDoTitle}>
              At Linardi Associate,
              <br /> our mission is to find your <br /> dream job in the
              shortest time possible.
            </Typography>
          ) : (
            <Typography className={styles.whatWeDoTitle}>
              At Linardi Associate, our mission is <br /> to find your dream job
              in the shortest time possible.
            </Typography>
          )}
        </div>
        <Grid container className={styles.whatWeDoContent}>
          <Grid md={6} xs={12} className={styles.whatWeDoCard}>
            <Typography className={styles.whatWeDoCardTitle}>
              What We Do as Headhunters
            </Typography>
            <div className={styles.cardWrapper}>
              <Typography className={styles.cardTitle}>
                Talent Acquisition
              </Typography>
              <Typography className={styles.cardSubTitle}>
                Our team of experts will help companies attract and placing
                talents in the most effective way possible using research, tools
                and resources enable organizations to build and optimize their
                people-management processes.
              </Typography>
            </div>
            <div className={styles.cardWrapper}>
              <Typography className={styles.cardTitle}>
                Interim Executives & Professionals
              </Typography>
              <Typography className={styles.cardSubTitle}>
                We enhance your business with high-performing individuals from
                Managers up to C-levels. Our aim is to ensure that your team has
                the necessary talent, knowledge and skillset to achieve your
                business goals.
              </Typography>
            </div>
          </Grid>
          <div className={styles.verticalLine} />
          <Grid md={6} xs={12} className={styles.whatWeDoBenefits}>
            <Typography className={styles.benefitsTitle}>Benefits</Typography>
            <div className={styles.benefitsWrapper}>
              <div className={styles.benefitsList}>
                <span className={styles.dot}>1</span>
                <Typography className={styles.listText}>
                  Find the most suitable candidates for your company’s
                  particular roles or jobs, based on the results of our
                  assessment & preliminary interview.
                </Typography>
              </div>
              <div className={styles.benefitsList}>
                <span className={styles.dot}>2</span>
                <Typography className={styles.listText}>
                  Having vast experience in the market makes us well prepared to
                  select and attract ideal, also reliable people for your
                  company.
                </Typography>
              </div>
              <div className={styles.benefitsList}>
                <span className={styles.dot}>3</span>
                <Typography className={styles.listText}>
                  Improve the efficiency of your recruitment process by taking
                  over the responsibility of locating and approaching talent.
                </Typography>
              </div>
              <div className={styles.benefitsList}>
                <span className={styles.dot}>4</span>
                <Typography className={styles.listText}>
                  Get confidentiality for recruitment process, especially for
                  key position that might not want to make public, like an
                  executive leader.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.buttonWWD}
            variant="contained"
            endIcon={
              <img
                src={Images.next}
                height="14px"
                width="8px"
                style={{ paddingLeft: "5px" }}
              />
            }
            onClick={() => history.push("/inquiry")}
          >
            Partner with us
          </Button>
        </div>
      </div>
      <div className={styles.articlesContainer}>
        <div className={styles.articlesContainerHeader}>
          <Typography className={styles.containerTitle}>Our Advise</Typography>
          <Link to="/blogs" className={styles.buttonMore}>
            VIEW ALL
          </Link>
        </div>
        {!articles ? (
          <div className={styles.articleList}>
            <Grid container spacing={3} className={styles.articleWrapper}>
              <Grid item xs={4}>
                <ArticleCard loading />
              </Grid>
              <Grid item xs={4}>
                <ArticleCard loading />
              </Grid>
              <Grid item xs={4}>
                <ArticleCard loading />
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={styles.articleList}>
            <Grid container spacing={3} className={styles.articleWrapper}>
              {articles.map((article) => {
                return (
                  <Grid
                    className={styles.articleItem}
                    item
                    xs={4}
                    key={article.id}
                  >
                    <ArticleCard article={article} key={article.id} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </div>
      <div className={styles.hpcContainer}>
        <Typography className={styles.testimoniTitle}>Happy Clients</Typography>
        {!props.parameters.loading && props.parameters.success && HPC && (
          <TextCarousel items={HPC} className={styles.carouselContainer} />
        )}
      </div>
      <div className={styles.bgHiringInquiry}>
        <Grid container className={styles.hiringInquiryContent}>
          <Grid className={styles.textContent} md={8} xs={12}>
            <Typography className={styles.hiringInquiryTitle}>
              Find the talent your business needs to grow.
            </Typography>
            <Typography className={styles.hiringInquirySubtitle}>
              Our professional services consulting can help you achieve your
              business goals. <br /> Message us today to learn more.
            </Typography>
          </Grid>
          <Grid className={styles.buttonContent} md={4} xs={12}>
            <Button
              variant="contained"
              className={styles.contactUs}
              onClick={() => history.push("/inquiry")}
            >
              MESSAGE US
            </Button>
          </Grid>
        </Grid>
      </div>
      {/* <FAB /> */}
      <Footer />
    </div>
  );
};

export default HomeScreen;
