import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Checkbox,
  OutlinedInput,
} from "@material-ui/core";
import { FormikProvider, FieldArray } from "formik";

import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import useStyles from "../SetupProfileStyles";

const WorkExperienceStepScreen = ({ formik }) => {
  const styles = useStyles();

  const handleDateChange = (e, index, val) => {
    const newDate = formik.values.formDate;
    const startDate = moment(
      `${newDate[index].start_year}-${
        newDate[index].start_month
      }-${moment().date()}`,
      "yyyy-M-D",
      true
    );
    let value = moment(val);
    switch (e) {
      case "start_date":
        newDate[index]["start_month"] = value.month() + 1;
        newDate[index]["start_year"] = value.year();
        newDate[index]["end_month"] = "";
        newDate[index]["end_year"] = "";
        break;
      case "end_date":
        if (value.isSameOrAfter(startDate)) {
          newDate[index]["end_month"] = value.month() + 1;
          newDate[index]["end_year"] = value.year();
        } else {
          window.alert("Please input valid end date");
          newDate[index]["end_month"] = "";
          newDate[index]["end_year"] = "";
        }
        break;
    }
    formik.setFieldValue("formDate", newDate);
  };

  useEffect(() => {
    formik.setFieldTouched("work_experiences");
    // onLoad();
  }, []);

  return (
    <Paper className={styles.signupBox}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Typography className={styles.signupTitle}>
          Please Provide your Work Experience
        </Typography>
        <FormikProvider value={formik}>
          <form
            style={{ width: "100%", marginTop: "47px" }}
            onSubmit={formik.handleSubmit}
          >
            <FieldArray
              name="work_experiences"
              render={(arrayHelpers) => (
                <>
                  {formik.values.work_experiences.map((experience, index) => (
                    <Grid container spacing={3} key={index}>
                      <Grid item xs={12}>
                        <Typography>Company</Typography>
                        <TextField
                          fullWidth
                          placeholder="Company"
                          variant="outlined"
                          name={`work_experiences.${index}.company`}
                          value={formik.values.work_experiences[index]?.company}
                          onChange={formik.handleChange}
                          onBlur={() =>
                            formik.setFieldTouched(
                              `work_experiences.${index}.company`
                            )
                          }
                          inputProps={{
                            className: styles.inputText,
                          }}
                        />
                        {formik.errors?.work_experiences?.length &&
                        formik.errors?.work_experiences[index]?.company ? (
                          <span className={styles.errorMessage}>
                            {formik.errors?.work_experiences[index]?.company}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Position</Typography>
                        <TextField
                          fullWidth
                          placeholder="Position"
                          variant="outlined"
                          name={`work_experiences.${index}.occupation`}
                          value={
                            formik.values.work_experiences[index]?.occupation
                          }
                          onChange={formik.handleChange}
                          onBlur={() =>
                            formik.setFieldTouched(
                              `work_experiences.${index}.occupation`
                            )
                          }
                          inputProps={{
                            className: styles.inputText,
                          }}
                        />
                        {formik.errors?.work_experiences?.length &&
                        formik.errors?.work_experiences[index]?.occupation ? (
                          <span className={styles.errorMessage}>
                            {formik.errors?.work_experiences[index]?.occupation}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Start Date</Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <DatePicker
                              autoOk
                              maxDate={moment()}
                              minDate={moment("1970-01-01")}
                              variant="dialog"
                              id={`start_month.${index}`}
                              placeholder="Start Date"
                              disabled={formik.isSubmitting}
                              value={
                                formik.values?.formDate[index]?.start_month
                                  ? moment(
                                      `${formik?.values?.formDate[index]?.start_year}-${formik?.values?.formDate[index]?.start_month}-01`,
                                      "yyyy-M-D",
                                      true
                                    )
                                  : null
                              }
                              format="MM/yyyy"
                              views={["year", "month"]}
                              onChange={(val) =>
                                handleDateChange("start_date", index, val)
                              }
                              TextFieldComponent={(props) => (
                                <OutlinedInput
                                  {...props}
                                  style={{ marginRight: 10 }}
                                  className={styles.input}
                                />
                              )}
                            />
                            {formik.errors?.formDate?.length &&
                            formik.errors?.formDate[index]?.start_month ? (
                              <span className={styles.errorMessage}>
                                <br />
                                {formik.errors?.formDate[index]?.start_month}
                              </span>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>End Date</Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <DatePicker
                              autoOk
                              maxDate={moment()}
                              minDate={moment(
                                `${
                                  formik.values?.formDate[index]?.start_month <
                                  12
                                    ? formik.values?.formDate[index]?.start_year
                                    : formik.values?.formDate[index]
                                        ?.start_year + 1
                                }-${
                                  formik.values?.formDate[index]?.start_month <
                                  12
                                    ? formik.values?.formDate[index]
                                        ?.start_month + 1
                                    : 1
                                }-01`,
                                "yyyy-M-D",
                                true
                              )}
                              variant="dialog"
                              id={`end_month.${index}`}
                              placeholder="End Date"
                              disabled={
                                formik.values?.work_experiences[index][
                                  "is_currently_work"
                                ] || formik.isSubmitting
                              }
                              required={
                                !formik.values?.work_experiences[index][
                                  "is_currently_work"
                                ]
                              }
                              value={
                                formik.values?.formDate[index]?.end_month
                                  ? moment(
                                      `${formik.values?.formDate[index]?.end_year}-${formik.values?.formDate[index]?.end_month}-01`,
                                      "yyyy-M-D",
                                      true
                                    )
                                  : null
                              }
                              format="MM/yyyy"
                              views={["year", "month"]}
                              onChange={(val) =>
                                handleDateChange("end_date", index, val)
                              }
                              TextFieldComponent={(props) => (
                                <OutlinedInput
                                  {...props}
                                  style={{ marginRight: 10 }}
                                  className={styles.input}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <div className={styles.checkbox}>
                          <Checkbox
                            size="medium"
                            color="primary"
                            name={`work_experiences.${index}.is_currently_work`}
                            checked={
                              formik.values.work_experiences[index]
                                ?.is_currently_work || false
                            }
                            defaultValue={false}
                            onChange={formik.handleChange}
                          />
                          <Typography>I currently work here</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {console.log(formik.values.work_experiences)}
                        {formik.values.work_experiences.length <= 1 ? (
                          <Button
                            className={styles.addButton}
                            onClick={() => {
                              if (formik.values.work_experiences.length >= 1) {
                                let newFormatDate = [...formik.values.formDate];
                                newFormatDate.push({
                                  start_month: "",
                                  start_year: "",
                                  end_month: "",
                                  end_year: "",
                                });
                                formik.setFieldValue("formDate", newFormatDate);
                              }
                              arrayHelpers.push({});
                            }}
                          >
                            <AddCircleRoundedIcon
                              className={styles.iconButton}
                            />{" "}
                            Add Work Experience
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {(formik.values.work_experiences.length > 1 &&
                          formik.values.canRemove) ||
                        (!formik.values.canRemove &&
                          index >= formik.values.initialDataLength) ? (
                          <Button
                            className={styles.removeButton}
                            onClick={() => {
                              if (index < 1) {
                                let newFormatDate = [...formik.values.formDate];
                                newFormatDate[index] = {
                                  start_month: "",
                                  start_year: "",
                                  end_month: "",
                                  end_year: "",
                                };
                                formik.setFieldValue("formDate", newFormatDate);

                                let newExp = [
                                  ...formik.values.work_experiences,
                                ];
                                newExp[index] = {
                                  company: "",
                                  occupation: "",
                                  is_currently_work: "",
                                };
                                formik.setFieldValue(
                                  "work_experiences",
                                  newExp
                                );
                              } else {
                                let newFormatDate = [...formik.values.formDate];
                                newFormatDate.pop();
                                formik.setFieldValue("formDate", newFormatDate);
                                arrayHelpers.remove(index);
                              }
                            }}
                          >
                            <RemoveCircleRoundedIcon
                              className={styles.iconButton}
                            />
                            Remove Work Experience
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            ></FieldArray>
          </form>
        </FormikProvider>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default WorkExperienceStepScreen;
