import { ActionType } from "../utils/constan";

const initialState = {
  loading: true,
  success: false,
  error: false,
  isLoggedIn: false,
  message: "",
  token: null,
  data: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.RESET_LOGIN_STATE:
      return {
        loading: false,
        success: false,
        error: false,
        message: "",
        data: null,
      };
    case ActionType.REPLACE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case ActionType.REQUEST_LOGIN:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
      };
    case ActionType.REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        isLoggedIn: true,
        token: action.payload.data.token,
        data: action.payload.user,
        message: action.payload.message,
      };
    case ActionType.REQUEST_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        isLoggedIn: false,
        token: null,
        data: null,
        message: action.payload.message,
      };
    case ActionType.REQUEST_LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
