import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  useMediaQuery,
  Typography,
  Button,
  Chip,
  Zoom,
} from "@material-ui/core";
// import FAB from "../../components/FAB";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import useStyles from "./SearchStyles";
import { Colors } from "../../themes";
import { ExpandMore, Close as CloseIcon } from "@material-ui/icons";
import JobSearch from "../../components/JobSearch";
import JobCard from "../../components/JobCard";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import clsx from "clsx";
import Dropdown from "../../components/Dropdown";
import { Images } from "../../themes";

const template = {
  Job_Opening_Name: null,
  Location: null,
  Minimum_Salary_Range: null,
  Job_Type: null,
  Industry_Preference: null,
  Work_Experience: null,
};

const SearchScreen = (props) => {
  const styles = useStyles();
  const location = useLocation();
  const md = useMediaQuery("(max-width:768px)");
  // const titleRef = useRef(null);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [jobList, setJobList] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  const [filterValues, setFilterValues] = useState(template);
  const [industries, setIndustries] = useState(null);
  const [jobTypes, setJobTypes] = useState(null);
  const [experiences, setExperiences] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const jobTypesRef = useRef(null);
  const industryRef = useRef(null);
  const experienceRef = useRef(null);
  // const [fabSA, setFabSA] = useState(false);

  const handleChangeValue = (key, value, request = true) => {
    const newInput = filterValues;
    newInput[key] = value;
    setFilterValues({ ...newInput });
    setPage(1);
    handleClose();

    if (request) props.searchJobs(1, 18, filterValues);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 700,
  });

  const backToTop = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const scrollToNext = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#to-next-button"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    getAllVariables();

    return () => {
      setPage(1);
    };
  }, []);

  // handle new search
  useEffect(() => {
    if (location.state) {
      !location.state.keyword && localStorage.setItem("lastKeyword", "");
      handleChangeValue("Job_Opening_Name", location.state.keyword, false);
      handleChangeValue("Location", location.state.region, false);
      handleChangeValue("Minimum_Salary_Range", location.state.salary, false);
      handleChangeValue("Industry", location.state.industry, false);
    }
    props.searchJobs(page, 18, filterValues);
  }, [location]);

  useEffect(() => {
    const _data = props.search?.data?.data;
    setSearchLoading(props.search?.loading);
    if (_data && _data.length > 0) {
      setJobList(_data);
      setJobCount(_data.length);
    } else {
      setJobList([]);
      setJobCount(0);
    }

    setMeta(props.search?.data?.meta?.info);
  }, [props.search]);

  useEffect(() => {
    if (props.industries.length > 0) {
      let _data = props.industries.filter(
        (item) => item.display_value !== "-None-"
      );
      setIndustries(_data);
    }
  }, [props.industries]);

  useEffect(() => {
    if (props.job_type.length > 0) {
      let _data = props.job_type.filter(
        (item) => item.display_value !== "-None-"
      );
      setJobTypes(_data);
    }
  }, [props.job_type]);

  useEffect(() => {
    if (props.work_exp.length > 0) {
      let _data = props.work_exp.filter(
        (item) => item.display_value !== "-None-"
      );
      setExperiences(_data);
    }
  }, [props.work_exp]);

  const getAllVariables = async () => {
    try {
      props.getIndustryPrefs();
      props.getJobType();
      props.getWorkExperience();
      props.getSalaryRange();
      props.getLocations();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (e) => setIsOpen(e);
  const handleClose = () => setIsOpen(null);

  const onPrev = () => {
    let p = page - 1;
    if (p < 1) return;

    setPage(p);
    props.searchJobs(p, 18, filterValues);
    // titleRef.current.scrollIntoView();
  };

  const onNext = () => {
    let p = page + 1;
    if (!meta?.more_records) return;

    setPage(p);
    props.searchJobs(p, 18, filterValues);
    // titleRef.current.scrollIntoView();
  };

  // const seeAll = () => {
  //   setFabSA(true);
  //   props.searchJobs(1, 12, filterValues);
  // };

  const renderChips = () => {
    let chips = [];
    for (let [key, value] of Object.entries(filterValues)) {
      if (value) {
        chips.push(
          <Chip
            className={styles.chip}
            label={value}
            onDelete={() => handleChangeValue(key, null)}
            deleteIcon={<CloseIcon style={{ color: "white" }} />}
          />
        );
      }
    }
    return chips.length > 0 ? (
      chips
    ) : (
      <Chip
        disabled
        className={styles.chip}
        label={"Empty"}
        deleteIcon={<CloseIcon style={{ color: "white" }} />}
      />
    );
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div
        className={clsx(styles.bgContainer, md ? styles.bgContainerMd : null)}
      >
        <Typography variant="h5" className={styles.searchTitle}>
          Find your dream job immediately
        </Typography>
        <JobSearch />
      </div>
      <div
        className={clsx(styles.contentContainer, styles.filterContainer)}
        id="back-to-top-anchor"
      >
        <div className={styles.dropdownWrapper}>
          <div className={styles.dropdownFilter}>
            <Button
              id="job-type"
              ref={jobTypesRef}
              style={{ textTransform: "none" }}
              onClick={() => handleClick("job-type")}
            >
              <Typography
                variant="h5"
                className={styles.dropdownTitle}
                style={{ color: Colors.light_gray, margin: "0 6px" }}
              >
                Type of Work
              </Typography>
              <ExpandMore style={{ color: Colors.light_gray, fontSize: 32 }} />
            </Button>
            {jobTypes && (
              <Dropdown
                id="job-type"
                isOpen={isOpen == "job-type"}
                items={jobTypes}
                anchor={jobTypesRef.current}
                clickItem={(item) =>
                  handleChangeValue("Job_Type", item.display_value)
                }
                handleClose={handleClose}
              />
            )}
          </div>
          <div className={styles.dropdownFilter}>
            <Button
              id="industry"
              ref={industryRef}
              style={{ textTransform: "none" }}
              onClick={() => handleClick("industry")}
            >
              <Typography
                variant="h5"
                className={styles.dropdownTitle}
                style={{ color: Colors.light_gray, margin: "0 6px" }}
              >
                Industry
              </Typography>
              <ExpandMore style={{ color: Colors.light_gray, fontSize: 32 }} />
            </Button>
            {industries && (
              <Dropdown
                id="industry"
                isOpen={isOpen == "industry"}
                items={industries}
                anchor={industryRef.current}
                clickItem={(item) =>
                  handleChangeValue("Industry_Preference", item.display_value)
                }
                handleClose={handleClose}
                sizeProps={{
                  style: {
                    minWidth: 200,
                    maxHeight: 400,
                  },
                }}
              />
            )}
          </div>
          <div className={styles.dropdownFilter}>
            <Button
              id="experience"
              ref={experienceRef}
              style={{ textTransform: "none" }}
              onClick={() => handleClick("experience")}
            >
              <Typography
                variant="h5"
                className={styles.dropdownTitle}
                style={{ color: Colors.light_gray, margin: "0 6px" }}
              >
                Years Of Experience
              </Typography>
              <ExpandMore style={{ color: Colors.light_gray, fontSize: 32 }} />
            </Button>
            {experiences && (
              <Dropdown
                id="experience"
                isOpen={isOpen == "experience"}
                items={experiences}
                anchor={experienceRef.current}
                clickItem={(item) =>
                  handleChangeValue("Work_Experience", item.display_value)
                }
                handleClose={handleClose}
                sizeProps={{
                  style: {
                    minWidth: 200,
                    maxHeight: 400,
                  },
                }}
              />
            )}
          </div>
          {jobCount > 12 ? (
            <div className={styles.textMoreWrapper}>
              <Typography variant="h5" className={styles.textMoreJobListing}>
                Click the
                <span className={styles.nextButton} onClick={scrollToNext}>
                  Next
                </span>
                button below to see more job listings.
              </Typography>
            </div>
          ) : null}
        </div>

        <div className={styles.filterWrapper}>
          <Typography
            variant="h5"
            className={styles.dropdownTitle}
            style={{ marginRight: 10 }}
          >
            Filter :
          </Typography>
          {renderChips()}
        </div>
      </div>
      {searchLoading ? (
        <div className={styles.contentContainer}>
          <div className={styles.jobList}>
            <JobCard loading />
            <JobCard loading />
            <JobCard loading />
          </div>
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles.jobsContainerHeader}>
            {/* <Typography
              ref={titleRef}
              variant="h5"
              className={styles.containerTitle}
              style={{
                marginBottom: 10,
                fontWeight: "700",
                fontSize: "25px",
                lineHeight: "20px",
                textTransform: "capitalize",
              }}
            >
              {!jobCount
                ? `${location?.state?.keyword || "Data"} not found`
                : `${jobCount} Jobs Found`}
            </Typography> */}
          </div>
          {!jobCount && (
            <ul className={styles.listWrapper}>
              {/* {industries?.map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <Link
                    href="#"
                    to={{
                      pathname: "/search",
                      state: { industry: item.display_value },
                    }}
                    className={styles.listLink}
                  >
                    {item.display_value}
                  </Link>
                </li>
              ))} */}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("A")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>A</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("A"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("B")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>B</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("B"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("C")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>C</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("C"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("D")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>D</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("D"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("E")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>E</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("E"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("F")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>F</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("F"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("G")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>G</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("G"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("H")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>H</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("H"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}

              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("I")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>I</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("I"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("J")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>J</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("J"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("K")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>K</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("K"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("L")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>L</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("L"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("M")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>M</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("M"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("N")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>N</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("N"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("O")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>O</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("O"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("P")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>P</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("P"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("Q")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>Q</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("Q"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("R")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>R</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("R"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("S")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>S</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("S"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("T")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>T</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("T"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("U")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>U</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("U"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("V")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>V</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("V"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("W")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>W</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("W"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("X")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>X</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("X"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("Y")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>Y</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("Y"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
              {industries &&
              industries.filter((industry) =>
                industry.display_value.startsWith("Z")
              ).length > 0 ? (
                <Typography className={styles.listLinkTitle}>Z</Typography>
              ) : null}
              {industries &&
                industries
                  .filter((industry) => industry.display_value.startsWith("Z"))
                  .map((item, i) => (
                    <li key={i} className={styles.listItem}>
                      <Link
                        href="#"
                        to={{
                          pathname: "/search",
                          state: { industry: item.display_value },
                        }}
                        className={styles.listLink}
                      >
                        {item.display_value}
                      </Link>
                    </li>
                  ))}
            </ul>
          )}
          <div className={styles.listContainer}>
            <div className={styles.jobList}>
              {jobList.length > 0 &&
                jobList.map((job, index) => {
                  return (
                    <JobCard
                      job={job}
                      key={index}
                      // industry={filterValues.Industry_Preference}
                    />
                  );
                })}
            </div>
            {/* {jobCount <= 6 ? (
              <div className={styles.wrapperSA}>
                <Typography
                  onClick={seeAll}
                  className={styles.seeAll}
                  variant="h5"
                >
                  See All
                </Typography>
              </div>
            ) : (
              <>
  
              </>
            )} */}
            <div className={styles.paginationWrapper} id="to-next-button">
              <Button
                disabled={searchLoading || page == 1}
                onClick={onPrev}
                className={styles.paginationBtnPrev}
                variant="contained"
                style={
                  searchLoading
                    ? { border: "2px solid #1875D1" }
                    : jobCount < 12
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Previous
              </Button>

              <Button
                disabled={searchLoading || !meta?.more_records}
                onClick={onNext}
                className={styles.paginationBtnNext}
                variant="contained"
                style={
                  !meta?.more_records
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
      {/* <FAB /> */}
      <Footer />
      {/* {fabSA ? (
        <>

        </>
      ) : null} */}

      <Zoom in={trigger}>
        <Button
          className={styles.backToTop}
          onClick={backToTop}
          endIcon={
            <img
              src={Images.up}
              height="16px"
              width="16px"
              style={{ paddingLeft: "5px" }}
            />
          }
        >
          BACK TO TOP
        </Button>
      </Zoom>
    </div>
  );
};

export default SearchScreen;
