import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../../themes";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: 50,
  },
  jobContainer: {
    padding: 20,
    margin: "10px 0 30px 0",
    "@media (max-width: 768px)": {
      width: "87%",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: "31px",
  },
  footer: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: "58px",
    height: "58px",
    marginRight: "21px",
  },
  jobHeader: {
    display: "flex",
    flexDirection: "column",
    height: "40px",
    justifyContent: "space-between",
  },
  jobTitle: {
    color: "#23201D",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "17px",
    margin: 0,
  },
  company: {
    color: "#1875D1",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "15px",
    margin: 0,
  },
  footnote: {
    color: "#808080",
    fontSize: "15px",
    fontWeight: "400",
    margin: 0,
  },
  contentItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
    fontSize: "16px",
    lineHeight: "15px",
    fontWeight: "400",
    color: "#23201D",
  },
  contentIcon: {
    marginRight: "21px",
    width: "21px",
  },
  appliedBtn: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 20,
    width: 144,
    height: 44,
    color: "white",
    borderRadius: 20,
    backgroundColor: Colors.light_gray,
  },
  btn: {
    margin: "30px 0",
    fontWeight: 600,
    color: "white",
    backgroundColor: Colors.blue,
    "&:hover": {
      backgroundColor: Colors.blue,
    },
  },
});

export default useStyles;
