import { connect } from "react-redux";
import Screen from "./ResetPassword";
import { doResetPassword } from "../../actions/ResetPasswordActions";

const mapStateToProps = (state) => ({
  reset_password: state.reset_password,
});

const mapDispatchToProps = (dispatch) => ({
  doResetPassword: (body) => dispatch(doResetPassword(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
