import { ActionType } from "../utils/constan";

const initialState = {
  loading: true,
  success: false,
  error: false,
  message: null,
  avatar: null,
  profile: null,
  experience: null,
  education: null,
  language: null,
  portfolio: null,
  progress: null,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    // PROGRESS
    case ActionType.REQUEST_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        progress: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_PROGRESS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        progress: null,
        message: action.payload.message,
      };

    // PROFILE
    case ActionType.REQUEST_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        profile: action.payload.data,
        avatar: action.payload.data?.Photo_Link,
        message: action.payload.message,
      };
    case ActionType.REQUEST_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        profile: null,
        message: action.payload.message,
      };
    // Edit Profile
    case ActionType.EDIT_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case ActionType.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        profile: action.payload.data,
        avatar: action.payload.data?.Photo_Link,
        message: action.payload.message,
      };
    case ActionType.EDIT_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };
    // Upload Avatar
    case ActionType.UPLOAD_AVATAR:
      return {
        ...state,
        loading: true,
      };
    case ActionType.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        avatar: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.UPLOAD_AVATAR_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };

    // EXPERIENCES
    case ActionType.REQUEST_EXPERIENCE:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        experience: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_EXPERIENCE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        experience: null,
        message: action.payload.message,
      };
    // Edit Experience
    case ActionType.EDIT_EXPERIENCE:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case ActionType.EDIT_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        experience: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.EDIT_EXPERIENCE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };
    // Add Experience
    case ActionType.ADD_EXPERIENCE:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case ActionType.ADD_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        experience: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.ADD_EXPERIENCE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };

    // EDUCATIONS
    case ActionType.REQUEST_EDUCATION:
      return {
        ...state,
        loading: true,
      };
    case ActionType.REQUEST_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        education: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_EDUCATION_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        education: null,
        message: action.payload.message,
      };
    // Edit Education
    case ActionType.EDIT_EDUCATION:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case ActionType.EDIT_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        education: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.EDIT_EDUCATION_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };
    // Add Education
    case ActionType.ADD_EDUCATION:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case ActionType.ADD_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        education: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.ADD_EDUCATION_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };

    // Get Languages
    case ActionType.REQUEST_LANGUAGE:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case ActionType.REQUEST_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        language: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_LANGUAGE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };
    // Edit Languages
    case ActionType.EDIT_LANGUAGE:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case ActionType.EDIT_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        language: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.EDIT_LANGUAGE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload.message,
      };

    // Get Portfolio
    case ActionType.REQUEST_PORTFOLIO:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case ActionType.REQUEST_PORTFOLIO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        portfolio: action.payload.data,
        message: action.payload.message,
      };
    case ActionType.REQUEST_PORTFOLIO_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        portfolio: null,
        message: action.payload.message,
      };

    // Clear All Data
    case ActionType.CLEAR_USER_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
