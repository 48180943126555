import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useMediaQuery, Paper, InputBase, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useStyles from "./styles";

import { getLocations, getSalaryRange } from "../../actions/JobMasterActions";

const Divs = () => {
  return (
    <div style={{ background: "rgba(179,179,179,0.5)", width: "2px" }}></div>
  );
};

const JobSearch = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const md = useMediaQuery("(max-width:768px)");
  // const [salaries, setSalaries] = useState([]);
  const [locations, setLocations] = useState([]);
  const [keywordState, setKeyword] = useState(null);
  // const [selectedSalary, setSelectedSalary] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [locationOpen, setLocationOpen] = useState(false);
  // const [salaryOpen, setSalaryOpen] = useState(false);
  const [locationFetching, setLocationFetching] = useState(false);
  // const [salaryFetching, setSalaryFetching] = useState(false);
  const loadingLocation = locationOpen && locations.length === 0;
  // const loadingSalary = salaryOpen && salaries.length === 0;

  useEffect(() => {
    if (props.salaries.length > 0) {
      const data = [];
      props.salaries.map((item) => {
        if (item.actual_value == "-None-") return;
        data.push(item.actual_value);
      });
      // setSalaries(data);
      // setSalaryFetching(false);
    }
  }, [props.salaries]);

  useEffect(() => {
    if (props.locations.length > 0) {
      const data = [];
      props.locations.map((item) => {
        if (item.actual_value == "-None-") return;
        data.push(item.actual_value);
      });
      setLocations(data);
      setLocationFetching(false);
    }
  }, [props.locations]);

  useEffect(() => {
    if (locationOpen) {
      if (!locationFetching && locations.length < 1) {
        props.getLocations();
        setLocationFetching(true);
      }
    }
  }, [locationOpen]);

  // useEffect(() => {
  //   if (salaryOpen) {
  //     if (!salaryFetching && salaries.length < 1) {
  //       props.getSalaryRange();
  //       setSalaryFetching(true);
  //     }
  //   }
  // }, [salaryOpen]);

  const handleSearch = (e) => {
    if (e) e.preventDefault();

    history.push({
      pathname: "/search",
      state: {
        keyword: keywordState,
        region: selectedLocation,
        salary: null,
      },
    });
  };

  return (
    <>
      {md ? (
        <div className={styles.searchWrapperMd}>
          <form onSubmit={handleSearch} className={styles.searchFormMd}>
            <Autocomplete
              id="region-autocomplete"
              open={locationOpen}
              onOpen={() => setLocationOpen(true)}
              onClose={() => setLocationOpen(false)}
              loading={loadingLocation}
              options={locations}
              getOptionLabel={(option) => option}
              style={{ background: `white` }}
              onChange={(_event, newValue) => setSelectedLocation(newValue)}
              renderInput={(params) => (
                <InputBase
                  {...params}
                  ref={params.InputProps.ref}
                  className={styles.inputForm}
                  id="region"
                  placeholder="Region or City"
                />
              )}
            />
            <InputBase
              // className={styles.inputForm}
              id="keyword"
              placeholder="Job title or keywords"
              onChange={(event) => setKeyword(event.target.value)}
              style={{
                background: `white`,
                height: 65,
                padding: 10,
                borderTop: "1px solid #808080",
              }}
            />
            {/* <Autocomplete
              id="salary-autocomplete"
              open={salaryOpen}
              onOpen={() => setSalaryOpen(true)}
              onClose={() => setSalaryOpen(false)}
              loading={loadingSalary}
              options={salaries}
              getOptionLabel={(option) => option}
              style={{ background: `white` }}
              onChange={(_event, newValue) => setSelectedSalary(newValue)}
              renderInput={(params) => (
                <InputBase
                  {...params}
                  ref={params.InputProps.ref}
                  className={styles.inputForm}
                  id="salary"
                  placeholder="Salary min"
                />
              )}
            /> */}
            <Button
              type="submit"
              style={{ flex: 1, marginTop: 10 }}
              className={styles.searchBtn}
            >
              SEARCH
            </Button>
          </form>
        </div>
      ) : (
        <Paper elevation={3} className={styles.searchWrapper}>
          <form onSubmit={handleSearch} className={styles.searchForm}>
            <InputBase
              style={{ flex: 3 }}
              id="keyword"
              type="text"
              placeholder="Job title or keywords"
              onChange={(event) => setKeyword(event.target.value)}
            />
            <Divs />
            <Autocomplete
              id="region-autocomplete"
              type="text"
              open={locationOpen}
              onOpen={() => setLocationOpen(true)}
              onClose={() => setLocationOpen(false)}
              loading={loadingLocation}
              options={locations}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(_event, newValue) => setSelectedLocation(newValue)}
              renderInput={(params) => (
                <InputBase
                  {...params}
                  ref={params.InputProps.ref}
                  className={styles.inputForm}
                  id="region"
                  placeholder="Region or City"
                />
              )}
            />
            {/* <Divs /> */}
            {/* <Autocomplete
              id="salary-autocomplete"
              type="text"
              open={salaryOpen}
              onOpen={() => setSalaryOpen(true)}
              onClose={() => setSalaryOpen(false)}
              loading={loadingSalary}
              options={salaries}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(_event, newValue) => setSelectedSalary(newValue)}
              renderInput={(params) => (
                <InputBase
                  {...params}
                  ref={params.InputProps.ref}
                  className={styles.inputForm}
                  id="salary"
                  placeholder="Salary min"
                />
              )}
            /> */}
            <Button
              type="submit"
              style={{ flex: 1 }}
              className={styles.searchBtn}
            >
              SEARCH
            </Button>
          </form>
        </Paper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.job_master.loading,
  success: state.job_master.success,
  message: state.job_master.message,
  salaries: state.job_master.min_salary,
  locations: state.job_master.locations,
});

const mapDispatchToProps = (dispatch) => ({
  getSalaryRange: () => dispatch(getSalaryRange()),
  getLocations: () => dispatch(getLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSearch);
