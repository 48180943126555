import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  pickerButton: {
    background: "#FFFFFF",
    color: "#1875D1",
    border: "1px solid #1875D1",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "16px 50px",
    cursor: "pointer",
    margin: 10,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
});

export default useStyles;
