import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Button } from "@material-ui/core";
// import { AccessTime } from "@material-ui/icons";
import useStyles from "./styles";
import { Images } from "../../themes";
// import { getIndustryLogo } from "../../utils/helper";
// import moment from "moment";
import clsx from "clsx";
import { doSavedJob, doUnSavedJob } from "../../actions/SavedJobActions";
import { Skeleton } from "@material-ui/lab";

function JobCard({ job, fullwidth, onDelete, loading = false }) {
  const [isSaved, setIsSaved] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // const saved_job = useSelector((state) => state.saved_jobs);
  const { isLoggedIn, token } = useSelector((state) => state.auth_reducer);

  const submitSavedJob = (job_id) => {
    if (isLoggedIn) {
      dispatch(doSavedJob(job_id));
      setIsSaved(true);
    } else {
      history.push("/signin");
    }
  };

  const openJobDetail = (e) => {
    if (e.target.id !== "favoriteButton" && e.target.id !== "imgStar") {
      window.open(
        `/jobs/detail/${job.id}${token ? `?_tk=${token}` : ""}`,
        "_blank"
      );
    }
  };

  const submitUnSavedJob = (job_id) => {
    dispatch(doUnSavedJob(job_id));
    onDelete && setTimeout(() => onDelete(), 1000);
    setIsSaved(false);
  };

  // const getTimeStamp = (date) => {
  //   let now = moment(),
  //     days = now.diff(date, "days"),
  //     months = now.diff(date, "months"),
  //     years = now.diff(date, "years"),
  //     result = "Today";

  //   if (days) {
  //     result = days + (days === 1 ? " day ago" : " days ago");
  //     if (months) {
  //       result = months + (months === 1 ? " month ago" : " month ago");
  //       if (years) {
  //         result = years + (years === 1 ? " year ago" : " years ago");
  //       }
  //     }
  //   }

  //   return result;
  // };

  useEffect(() => {
    if (job) setIsSaved(job.Is_Saved);
  }, [job]);

  const classes = useStyles();
  return (
    <Card
      className={
        fullwidth ? clsx(classes.root, classes.rootFullwidth) : classes.root
      }
      onClick={openJobDetail}
      style={{ cursor: "pointer" }}
    >
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              width={58}
              height={58}
              style={{
                marginRight: "21px",
              }}
            />
          ) : (
            <img
              onError={(e) => (e.target.src = Images.othersCompany)}
              src={job.Industry_Logo ? job.Industry_Logo : Images.othersCompany}
              className={classes.logo}
            />
          )}
          <div className={classes.jobHeader}>
            {loading ? (
              <>
                <Skeleton animation="wave" height={15} width={200} />
                <Skeleton animation="wave" height={15} width={100} />
              </>
            ) : (
              <>
                <h2 className={classes.jobTitle}>{job.Job_Opening_Name}</h2>

                <p className={classes.company}>
                  {job?.Confidential
                    ? "Confidential Company"
                    : job.Client_Name?.name}
                </p>
              </>
            )}
          </div>
        </div>
        <div className={classes.content}>
          <span className={classes.contentItem}>
            {loading ? (
              <Skeleton animation="wave" height={15} width={300} />
            ) : (
              <>
                <img
                  src="/assets/icons/location.png"
                  className={classes.contentIcon}
                />
                {job.Location}
              </>
            )}
          </span>
          <span className={classes.contentItem}>
            {loading ? (
              <Skeleton animation="wave" height={15} width={250} />
            ) : (
              <>
                <img
                  src="/assets/icons/job_type.png"
                  className={classes.contentIcon}
                />
                {job.Job_Type}
              </>
            )}
          </span>
        </div>
        <div className={classes.footer}>
          {loading ? (
            <Skeleton animation="wave" height={15} width={100} />
          ) : (
            <>
              <p className={classes.footnote}>{job?.Industry}</p>
              <Button
                id={"favoriteButton"}
                className={classes.favoriteButton}
                onClick={() =>
                  isSaved ? submitUnSavedJob(job?.id) : submitSavedJob(job?.id)
                }
              >
                <img
                  id={"imgStar"}
                  src={isSaved ? Images.starBlueIcon : Images.starIcon}
                  className={"imgStar"}
                  width="20"
                  height="20"
                />
              </Button>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

JobCard.propTypes = {
  job: PropTypes.object,
  fullwidth: PropTypes.bool,
  loading: PropTypes.bool,
};

export default JobCard;
