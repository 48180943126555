import { connect } from "react-redux";
import Screen from "./JobScreen";
import { getIndustryPrefs } from "../../actions/JobMasterActions";

const mapStateToProps = (state) => ({
  job_master: state.job_master,
});

const mapDispatchToProps = (dispatch) => ({
  getIndustryPrefs: () => dispatch(getIndustryPrefs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
