import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  AddCircleRounded,
  Edit as EditIcon,
  ImportContacts,
  HistoryOutlined,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import DefaultDialog from "../../../../components/DefaultDialog";
import { useFormik } from "formik";
import clsx from "clsx";
import useStyles from "./styles";

import { connect } from "react-redux";
import {
  getEducations,
  editEducations,
  addEducations,
} from "../../../../actions/UsersActions";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const template = {
  degree: "",
  school: "",
  major: "",
  is_currently_persuing: false,
};

const degreeOptions = [
  "High School",
  "Diploma (D3)",
  "Bachelor (S1)",
  "Master (S2)",
  "Doctor (S3)",
];

const EducationContent = (props) => {
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inputValues, setInputValues] = useState(template);
  const [selectedValues, setSelectedValues] = useState({
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
  });
  const [educationData, setEducationData] = useState([]);
  const [postSubmit, setPostSubmit] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const formik = useFormik({
    initialValues: inputValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      let duration = {
        from: `${selectedValues["start_year"]}-${selectedValues["start_month"]}-01`,
        to: values["is_currently_persuing"]
          ? ""
          : `${selectedValues["end_year"]}-${selectedValues["end_month"]}-01`,
      };
      let data = {
        educations: [
          {
            ...values,
            duration,
          },
        ],
      };

      if (postSubmit) {
        props.addEducations(data);
      } else {
        props.editEducations(data);
      }
    },
  });

  useEffect(() => {
    props.getEducations();
  }, []);

  useEffect(() => {
    const success = props.users_reducer.success;
    if (!success) {
      handleClose();
    }
  }, [props.users_reducer.success]);

  useEffect(() => {
    const ed = props.users_reducer.education;
    if (ed && ed.length > 0) {
      const data = [];
      ed.map((item) => {
        let d = {
          id: item.id,
          is_currently_persuing: item.Currently_pursuing,
          degree: item.Degree,
          school: item["Institute_/_School"],
          major: item["Major_/_Department"],
          duration: {
            from: item.Duration?.from,
            to: item.Duration?.to,
          },
        };
        data.push(d);
      });
      setEducationData(data);
    }
  }, [props.users_reducer.education]);

  const handleClose = () => {
    setInputValues(template);
    setSelectedValues({
      start_month: "",
      start_year: "",
      end_month: "",
      end_year: "",
    });
    setDialogOpen(false);
    formik.setSubmitting(false);
    formik.resetForm();
  };

  const handleClickItem = (id, data) => {
    const _data = data;
    if (_data) {
      const newInput = {
        id: _data?.id,
        school: _data?.school,
        degree: _data?.degree,
        major: _data?.major,
        is_currently_persuing: _data?.is_currently_persuing,
      };

      let fromValue = moment(_data.duration?.from);
      let toValue = moment(_data.duration?.to);
      const newDate = {
        start_year: fromValue ? fromValue.year() : null,
        start_month: fromValue ? fromValue.month() + 1 : null,
        end_year: toValue ? toValue.year() : null,
        end_month: toValue ? toValue.month() + 1 : null,
      };
      setSelectedStartDate(fromValue);
      setSelectedEndDate(toValue);
      setInputValues(newInput);
      setSelectedValues(newDate);
      setPostSubmit(false);
    }

    if (id == 1) {
      setDialogOpen(true);
    }
  };

  const handleDateChange = (e, val) => {
    const newDate = selectedValues;
    let value = moment(val);
    switch (e) {
      case "start_date":
        newDate["start_month"] = value.month() + 1;
        newDate["start_year"] = value.year();
        newDate["end_month"] = "";
        newDate["end_year"] = "";
        setSelectedEndDate(null);
        break;
      case "end_date":
        if (val > selectedStartDate) {
          newDate["end_month"] = value.month() + 1;
          newDate["end_year"] = value.year();
        } else {
          window.alert("Please input valid end date");
          newDate["end_month"] = "";
          newDate["end_year"] = "";
          setSelectedEndDate(null);
        }
        break;
    }
    setSelectedValues({ ...newDate });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={styles.container}>
        <div className={styles.head}>
          <Typography variant="h4" className={styles.title}>
            Education
          </Typography>
          <Button
            variant="outlined"
            className={styles.addBtn}
            onClick={() => {
              setInputValues(template);
              setPostSubmit(true);
              setDialogOpen(true);
            }}
          >
            <AddCircleRounded className={styles.addIcon} />
            ADD EDUCATION
          </Button>
        </div>
        <div className={styles.content}>
          {educationData.length > 0 ? (
            educationData.map((data) => (
              <div key={data.id} className={styles.itemWrapper}>
                <Typography className={styles.itemText}>
                  <b style={{ marginRight: 5 }}>{data.school}</b>
                  {" - " + data.degree}
                </Typography>
                <Typography className={styles.itemText}>
                  <HistoryOutlined className={styles.itemIcon} />
                  {moment(data?.duration?.from)
                    .locale("en")
                    .format("MMMM yyyy")}
                  {data.is_currently_persuing
                    ? " - Currently Pursuing"
                    : " - " +
                      moment(data?.duration?.to)
                        .locale("en")
                        .format("MMMM yyyy")}
                </Typography>
                <Typography className={styles.itemText}>
                  <ImportContacts className={styles.itemIcon} />
                  {data.major}
                </Typography>
                <IconButton
                  disableRipple
                  onClick={() => handleClickItem(1, data)}
                  className={styles.moreIcon}
                >
                  <EditIcon />
                </IconButton>
              </div>
            ))
          ) : (
            <div>
              <p>
                No Education Yet, Add your education background to get more job
                opportunity
              </p>
            </div>
          )}
        </div>
        <DefaultDialog isOpen={dialogOpen} onClose={handleClose}>
          <Typography className={styles.dialogTitle}>
            {postSubmit ? "Add" : "Edit"} Education
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.formGroup}>
              <InputLabel htmlFor="school" className={styles.inputLabel}>
                University / School
              </InputLabel>
              <OutlinedInput
                disabled={formik.isSubmitting}
                className={styles.input}
                id="school"
                placeholder="University / School"
                value={formik.values["school"]}
                onChange={formik.handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <InputLabel htmlFor="degree" className={styles.inputLabel}>
                Degree
              </InputLabel>
              <Autocomplete
                disabled={formik.isSubmitting}
                id="degree"
                options={degreeOptions}
                getOptionLabel={(option) => option}
                style={{ marginRight: 10, flex: 1 }}
                value={formik.values["degree"]}
                onChange={(_, event) => formik.setFieldValue("degree", event)}
                renderInput={(params) => (
                  <OutlinedInput
                    {...params}
                    ref={params.InputProps.ref}
                    className={styles.input}
                    placeholder="Degree"
                  />
                )}
              />
            </div>
            <div className={styles.formGroup}>
              <InputLabel htmlFor="major" className={styles.inputLabel}>
                Major
              </InputLabel>
              <OutlinedInput
                disabled={formik.isSubmitting}
                className={styles.input}
                id="major"
                placeholder="Major"
                value={formik.values["major"]}
                onChange={formik.handleChange}
              />
            </div>
            <div className={styles.formGroup}>
              <InputLabel className={styles.inputLabel}>Start Date</InputLabel>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <DatePicker
                  autoOk
                  minDate={moment("1970-01-01")}
                  maxDate={moment()}
                  variant="dialog"
                  id="start_month"
                  placeholder="Month"
                  disabled={formik.isSubmitting}
                  value={selectedStartDate}
                  format="MM/yyyy"
                  required
                  views={["year", "month"]}
                  onChange={(val) => {
                    setSelectedStartDate(val);
                    handleDateChange("start_date", val);
                  }}
                  TextFieldComponent={(props) => (
                    <OutlinedInput
                      {...props}
                      style={{ marginRight: 10 }}
                      className={styles.input}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <InputLabel className={styles.inputLabel}>End Date</InputLabel>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <DatePicker
                  autoOk
                  maxDate={moment()}
                  minDate={moment(selectedStartDate).add(1, "month")}
                  variant="dialog"
                  id="end_month"
                  placeholder="Month"
                  disabled={
                    formik.values["is_currently_persuing"] ||
                    formik.isSubmitting
                  }
                  required={!formik.values["is_currently_persuing"]}
                  value={selectedEndDate}
                  format="MM/yyyy"
                  views={["year", "month"]}
                  onChange={(val) => {
                    setSelectedEndDate(val);
                    handleDateChange("end_date", val);
                  }}
                  TextFieldComponent={(props) => (
                    <OutlinedInput
                      {...props}
                      style={{ marginRight: 10 }}
                      className={styles.input}
                    />
                  )}
                />
              </div>
              <FormControlLabel
                disabled={formik.isSubmitting}
                control={
                  <Checkbox
                    checked={formik.values["is_currently_persuing"]}
                    onChange={() =>
                      formik.setFieldValue(
                        "is_currently_persuing",
                        !formik.values["is_currently_persuing"]
                      )
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="I currently study here"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "65px 0 10px 0",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{ marginRight: 20 }}
                className={clsx(styles.btn, styles.grayBtn)}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                style={{ marginLeft: 20 }}
                className={clsx(styles.btn, styles.blueBtn)}
              >
                SAVE CHANGES
              </Button>
            </div>
          </form>
        </DefaultDialog>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  users_reducer: state.users_reducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEducations: () => dispatch(getEducations()),
  addEducations: (body) => dispatch(addEducations(body)),
  editEducations: (body) => dispatch(editEducations(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EducationContent);
