import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../themes";

const useStyles = makeStyles({
  searchWrapper: {
    width: "100%",
    borderRadius: 5,
    fontStyle: "italic",
    backgroundColor: "white",
  },
  searchWrapperMd: {
    width: "100%",
    fontStyle: "italic",
  },
  searchForm: {
    display: "flex",
    padding: "15px 30px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  searchFormMd: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  searchBtn: {
    backgroundColor: Colors.blue,
    alignSelf: "center",
    color: "white",
    height: "45px",
    minWidth: "150px",
    "&:hover": {
      backgroundColor: Colors.blue,
    },
    "@media (max-width: 768px)": {
      height: 60,
      width: "100%",
    },
  },
  inputForm: {
    height: 45,
    borderRadius: 5,
    margin: "10px",
  },
});

export default useStyles;
