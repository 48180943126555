import React, { useEffect, useState } from "react";
import { Paper, Typography, Button, TextField, Grid } from "@material-ui/core";

import { Formik } from "formik";
import useStyles from "./ForgotPasswordStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import Alert from "../../components/Alert";

const ForgotPasswordScreen = (props) => {
  const styles = useStyles();
  const [hasRendered, setHasRendered] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [openSnackbarError, setOpenSnackbarError] = useState(false);
  const submitForgotPassword = (values, setSubmitting) => {
    setOpenSnackbarSuccess(false);
    setOpenSnackbarError(false);
    const { email } = values;
    props.doForgotPassword(email);
    setSubmitting(false);
  };

  const onCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarSuccess(false);
    setOpenSnackbarError(false);
  };

  useEffect(() => {
    let loading = props.forgot_password.loading;
    let error = props.forgot_password.error;
    let success = props.forgot_password.success;

    if (!loading && !error && success && hasRendered) {
      setOpenSnackbarSuccess(true);
    } else if (!loading && error && !success && hasRendered) {
      setOpenSnackbarError(true);
    }
  }, [props.forgot_password.loading]);

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.forgotContainer}>
        <Paper className={styles.forgotBox}>
          <Typography
            className={styles.forgotTitle}
            style={{ marginBottom: "19px" }}
          >
            Forgot Your Password
          </Typography>
          <Typography style={{ textAlign: "center", marginBottom: "42px" }}>
            Please enter your email address below and we’ll send you
            instructions on how to change your password
          </Typography>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setHasRendered(true);
              setSubmitting(true);
              submitForgotPassword(values, setSubmitting);
            }}
          >
            {({ handleSubmit, isSubmitting, handleChange }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="email"
                      placeholder="Email Address"
                      variant="outlined"
                      onChange={handleChange}
                      inputProps={{
                        className: styles.inputText,
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className={styles.forgotButton}
                >
                  SUBMIT
                </Button>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
      <Alert
        message={props.forgot_password.message}
        isOpen={openSnackbarError}
        isError={true}
        onClose={onCloseSnackBar}
      />
      <Alert
        message={"Please check your email"}
        isOpen={openSnackbarSuccess}
        isError={false}
        onClose={onCloseSnackBar}
      />
      <Footer />
    </div>
  );
};

export default ForgotPasswordScreen;
