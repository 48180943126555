import { ActionType, Api, ApiConfig } from "../utils/constan";
import client from "../utils/service";

export const getParameters = (page, page_size) => async (dispatch) => {
  dispatch({ type: ActionType.REQUEST_PARAMETERS });
  try {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/masters`;
    const response = await client.get(
      `${Api.parameters}?page=${page}&page_size=${page_size}&code=CU&is_active=true`
    );
    const message = response?.data?.meta?.message;

    if (response?.status === 200) {
      let data = response?.data?.data;
      dispatch({
        type: ActionType.REQUEST_PARAMETERS_SUCCESS,
        payload: { data, message },
      });
    } else {
      dispatch({
        type: ActionType.REQUEST_PARAMETERS_FAILED,
        payload: { message },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.REQUEST_PARAMETERS_FAILED,
      payload: { message: "Failed Request Parameter" },
    });
  }
};
