import { makeStyles } from "@material-ui/core/styles";
// import { Colors } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  resetContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "35px",
    marginBottom: "36px",
    [theme.breakpoints.down("md")]: {
      padding: "0 23px",
    },
  },
  resetBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: "859px",
    height: "540px",
    padding: "58px 111px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      padding: "12px 0",
      boxShadow: "none",
      height: "auto",
    },
  },
  resetTitle: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "19px",
    color: "#23201D",
  },
  inputText: {
    height: "44px",
    padding: "0 30px",
  },
  inputError: {
    border: "1px solid red",
    color: "red",
    height: "44px",
    padding: "0 30px",
  },
  errorMessage: {
    color: "red",
  },
  resetButton: {
    marginTop: "39px",
    width: "100%",
    background: "#1875D1",
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: "16px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "15px",
    textTransform: "uppercase",
  },
  passwordNotice: {
    marginTop: "5px",
    fontSize: "15px",
    lineHeight: "30px",
    color: "#808080",
  },
}));

export default useStyles;
