import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import useStyles from "./BlogStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { Paper, Grid, Typography, Button, InputBase } from "@material-ui/core";

import ArticleCard from "../../components/ArticleCard";
import FeaturedArticle from "../../components/FeaturedArticle";

import { ApiConfig } from "../../utils/constan";
import client from "../../utils/service";

const BlogScreen = () => {
  const [articles, setArticles] = useState(null);
  const [featuredArticle, setFeaturedArticle] = useState(null);
  const [search, setSearch] = useState("");

  const formik = useFormik({
    initialValues: {
      keyword: "",
    },
    onSubmit: (values) => {
      setSearch(values.keyword);
    },
  });

  const fetchArticles = async (keyword = "") => {
    client.defaults.baseURL = `${ApiConfig.baseUrl}/blogs`;
    const {
      data: { data: response },
    } = await client.get("/qareer-blog", {
      params: {
        search: keyword,
      },
    });
    console.log(keyword, "<<keyword");
    console.log(response);
    const featured = response.splice(0, 1);
    const listArticle = response.splice(0, 6);
    setFeaturedArticle(featured);
    setArticles(listArticle);
  };
  useEffect(() => {
    setArticles(null);
    fetchArticles(search);
  }, [search]);
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.searchArticle}>
        <Typography variant="h5" className={styles.searchTitle}>
          Career Articles
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", display: "contents" }}
        >
          <Paper className={styles.searchBox}>
            <InputBase
              name="keyword"
              placeholder="Blog title or keywords"
              onChange={formik.handleChange}
              value={formik.values.keyword}
              style={{
                flexGrow: 1,
                background: "#FFFFFF",
                width: "100%",
                borderRadius: "7px",
                padding: "5px 20px",
              }}
            />
            <Button type="submit" className={styles.searchButton}>
              Search
            </Button>
          </Paper>
        </form>
      </div>
      {!articles || !featuredArticle ? (
        <div className={styles.blogsContainer}>
          <Grid container spacing={3} className={styles.articleWrapper}>
            <Grid item xs={4}>
              <ArticleCard loading />
            </Grid>
            <Grid item xs={4}>
              <ArticleCard loading />
            </Grid>
            <Grid item xs={4}>
              <ArticleCard loading />
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={styles.blogsContainer}>
          <Typography className={styles.containerTitle}>
            {!search
              ? "Blogs"
              : `${
                  featuredArticle.length ? "" : "No "
                }Search result for keyword: "${search}"`}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {featuredArticle.length ? (
                <FeaturedArticle article={featuredArticle[0]} />
              ) : (
                <></>
              )}
            </Grid>
            {articles.length ? (
              articles.map((article) => {
                return (
                  <Grid item xs={12} md={4} key={article.id}>
                    <ArticleCard article={article} />
                  </Grid>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
          <div className={styles.moreContainer}>
            {featuredArticle.length ? (
              <a href={ApiConfig.wordpressURL} className={styles.buttonMore}>
                VIEW ALL
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default BlogScreen;
