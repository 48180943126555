import qareer_logo from "../assets/images/qareer-logo.png";
import linkedin_logo from "../assets/images/linkedin-logo.png";
import nextArrow from "../assets/images/arrow-right.png";
import prevArrow from "../assets/images/arrow-left.png";
import uploadFile from "../assets/images/upload-file.png";
import trashIcon from "../assets/images/trash-icon.svg";
import starIcon from "../assets/images/star.png";
import starBlueIcon from "../assets/images/star-blue.svg";
import pdfIcon from "../assets/images/pdf_icon.png";
import downloadIcon from "../assets/images/download_icon.png";
import dummyCompany from "../assets/images/dummy-company.png";
import othersCompany from "../assets/images/others-company.svg";
import linardi_logo from "../assets/images/linardi_logo.png";
import linardi_logo_white from "../assets/images/linardi_logo_white.png";
import About_Image from "../assets/images/About_Image.png";
import clock_icon from "../assets/images/clock_icon.png";
import file_icon from "../assets/images/file_icon.png";
import placeholder from "../assets/images/Placeholder.png";
import next from "../assets/images/next.png";
import instagram from "../assets/images/Instagram.png";
import pertamina from "../assets/images/pertamina.jpeg";
import up from "../assets/images/up.png";
import dwidaya from "../assets/images/logo_dwidaya.png";
import cav from "../assets/images/pt_cav.png";
export const Images = {
  clock_icon,
  file_icon,
  qareer_logo,
  linkedin_logo,
  nextArrow,
  prevArrow,
  uploadFile,
  trashIcon,
  starIcon,
  starBlueIcon,
  pdfIcon,
  dummyCompany,
  othersCompany,
  downloadIcon,
  linardi_logo,
  linardi_logo_white,
  About_Image,
  placeholder,
  next,
  instagram,
  pertamina,
  up,
  dwidaya,
  cav,
};

/* COLORS */
export const Colors = {
  green: "#4BAE4F",
  blue: "#1875D1",
  red: "#DE1A1B",
  light_gray: "#808080",
  lighter_gray: "#F6F6F6",
  footer_link: "#23201d",
};
