import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 18,
    left: "calc(-50% + 33px)",
    right: "calc(50% + 33px)",
    [theme.breakpoints.down("xs")]: {
      top: 3,
      left: "calc(-50% + 13px)",
      right: "calc(50% + 13px)",
    },
  },
  active: {
    "& $line": {
      borderColor: "#CC0000",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#CC0000",
    },
  },
  line: {
    borderColor: "#B3B3B3",
    borderTopWidth: 8,
    borderRadius: 1,
    [theme.breakpoints.down("xs")]: {
      borderTopWidth: 4,
    },
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#B3B3B3",
    display: "flex",
    width: 40,
    height: 40,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: 10,
      height: 10,
    },
  },
  active: {
    color: "#CC0000",
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    [theme.breakpoints.down("xs")]: {
      width: 10,
      height: 10,
    },
  },
  completed: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: "#CC0000",
    [theme.breakpoints.down("xs")]: {
      width: 10,
      height: 10,
    },
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={completed ? classes.completed : classes.circle} />
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

export { QontoStepIcon, QontoConnector };
