import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  Paper,
  Grid,
  Typography,
  Chip,
  Button,
  Dialog,
} from "@material-ui/core";

import useStyles from "./JobDetailStyles";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseIcon from "@material-ui/icons/Close";
import { AccessTime } from "@material-ui/icons";
// import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";

import parse from "html-react-parser";
// import moment from "moment";
import { Images } from "../../themes";
import JobCard from "../../components/JobCard";

const JobDetailScreen = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [job_detail, setjob_detail] = useState(props.job_detail?.data);
  const recommendation = props.recommendation?.data.filter(
    (job) => job.id != job_detail?.id
  );
  const { job_id } = useParams();
  const styles = useStyles();
  const [isApplied, setIsApplied] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const submitApplyJob = (job_id) => {
    props.doApplyJob(job_id);
    setIsApplied(true);
    setModalSuccess(true);
    setModalConfirmation(false);
  };

  const confirmationApply = () => {
    if (props.isLoggedIn) {
      setModalConfirmation(true);
    } else {
      history.push("/signin");
    }
  };

  const submitSavedJob = (job_id) => {
    if (props.isLoggedIn) {
      props.doSavedJob(job_id);
      setIsSaved(true);
    } else {
      history.push("/signin");
    }
  };

  const submitUnsavedJob = (job_id) => {
    props.doUnSavedJob(job_id);
    setIsSaved(false);
  };

  // const getTimeStamp = (date) => {
  //   let now = moment(),
  //     days = now.diff(date, "days"),
  //     months = now.diff(date, "months"),
  //     years = now.diff(date, "years"),
  //     result = "Today";

  //   if (days) {
  //     result = days + (days === 1 ? " day ago" : " days ago");
  //     if (months) {
  //       result = months + (months === 1 ? " month ago" : " month ago");
  //       if (years) {
  //         result = years + (years === 1 ? " year ago" : " years ago");
  //       }
  //     }
  //   }

  //   return result;
  // };

  const parseToHtml = (val) => {
    const htmlNode = document.createElement("div");
    htmlNode.classList.add(styles.wrapperDesc);
    htmlNode.innerHTML = val;
    htmlNode.querySelectorAll("*").forEach((node) => {
      node.style.removeProperty("font-family");
    });

    return parse(htmlNode.outerHTML);
  };

  useEffect(() => {
    setjob_detail(props.job_detail.data);
    setIsApplied(props.job_detail.data?.Is_Applied);
    setIsSaved(props.job_detail.data?.Is_Saved);
    const keyword = localStorage?.lastKeyword;
    const params = {
      Work_Experience: props.job_detail.data?.Work_Experience,
      Job_Opening_Name: keyword || "",
      Industry_Preference: !keyword
        ? props.job_detail.data?.Industry_Preference
        : "",
    };
    props.getRecommendation(1, 4, params);
    console.log(props.job_detail.data);
  }, [props.job_detail.data]);

  useEffect(() => {
    props.getDetailJob(job_id);
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tk = query.get("_tk");
    if (tk !== null) {
      props.autoLogin(tk);
      props.getDetailJob(job_id);
    }
  }, [location]);

  return (
    <>
      <Dialog
        className={styles.modalConfirmation}
        modal={true}
        fullScreen
        open={modalSuccess}
      >
        <div className={styles.modalWrapper}>
          <CloseIcon
            className={styles.closeButton}
            onClick={() => setModalSuccess(false)}
          />
          <CheckRoundedIcon
            style={{
              width: "109px",
              height: "82px",
              marginLeft: "15px",
              marginTop: "30px",
            }}
          />
          <Typography
            className={styles.modalText}
            style={{ marginTop: "15px" }}
          >
            <span className={styles.modalTextBold}>Congratulations</span>.
          </Typography>
          <Typography
            className={styles.modalText}
            style={{ marginTop: "15px" }}
          >
            Your application for{" "}
            <span className={styles.modalTextBold}>
              {job_detail.Job_Opening_Name}
            </span>{" "}
            at{" "}
            <span className={styles.modalTextBold}>
              {job_detail?.Confidential
                ? "Confidential Company"
                : job_detail.Client_Name?.name}
            </span>{" "}
            has been submitted.
          </Typography>
        </div>
      </Dialog>
      <Dialog
        className={styles.modalConfirmation}
        modal={true}
        fullScreen
        open={modalConfirmation}
      >
        <div className={styles.modalWrapper}>
          <CloseIcon
            className={styles.closeButton}
            onClick={() => setModalConfirmation(false)}
          />
          <Typography
            className={styles.modalText}
            style={{ marginTop: "30px" }}
          >
            You apply to the position{" "}
            <span className={styles.modalTextBold}>
              {job_detail.Job_Opening_Name}
            </span>{" "}
            at&nbsp;
            <span className={styles.modalTextBold}>
              {job_detail?.Confidential
                ? "Confidential Company"
                : job_detail.Client_Name?.name}
            </span>
            .
          </Typography>
          <Typography
            className={styles.modalText}
            style={{ marginTop: "15px" }}
          >
            Your profile will be submitted with your application.
          </Typography>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.blueButton}
              onClick={() => {
                submitApplyJob(job_detail?.id);
              }}
            >
              Submit Profile
            </Button>
            <Button
              className={styles.whiteButton}
              onClick={() => history.push("/profile")}
            >
              View Profile
            </Button>
          </div>
        </div>
      </Dialog>
      <div className={styles.container}>
        <Navbar />
        <div className={styles.jobsContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} style={{ marginBottom: 50 }}>
              <Paper className={styles.detailJobBox}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <img
                      onError={(e) => (e.target.src = Images.othersCompany)}
                      src={
                        job_detail.Industry_Logo
                          ? job_detail.Industry_Logo
                          : Images.othersCompany
                      }
                      width="109"
                      height="109"
                      className={styles.companyPhoto}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <div className={styles.companyNamePosition}>
                      <Typography
                        className={styles.detailTitle}
                        style={{ fontWeight: "600" }}
                      >
                        {job_detail.Job_Opening_Name}
                      </Typography>
                      <Typography className={styles.companyName}>
                        {job_detail?.Confidential
                          ? "Confidential Company"
                          : job_detail.Client_Name?.name}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <div className={styles.content}>
                  <span className={styles.contentItem}>
                    <img
                      src="/assets/icons/location.png"
                      className={styles.contentIcon}
                    />
                    {job_detail.Location}
                  </span>
                  {/* <span className={styles.contentItem}>
                    <MonetizationOnOutlinedIcon
                      className={styles.contentIcon}
                    />
                    {job_detail.Minimum_Salary_Range}
                  </span> */}
                  <span className={styles.contentItem}>
                    <AccessTime className={styles.contentIcon} />
                    {job_detail.Job_Type}
                  </span>
                  <Typography
                    style={{
                      color: "#808080",
                      fontSize: "12px",
                      marginTop: "0",
                      marginBottom: "15px",
                    }}
                  >
                    {/* Posted {getTimeStamp(job_detail.Created_Time)} */}
                  </Typography>
                  <div className={styles.jobActionWrapper}>
                    <Button
                      disabled={isApplied}
                      className={styles.applyButtonDesktop}
                      onClick={() => confirmationApply()}
                    >
                      APPLY JOB
                    </Button>
                    <Button
                      className={
                        isSaved
                          ? styles.unSavedButtonDesktop
                          : styles.savedButtonDesktop
                      }
                      onClick={() => {
                        isSaved
                          ? submitUnsavedJob(job_detail?.id)
                          : submitSavedJob(job_detail?.id);
                      }}
                    >
                      {isSaved ? "UNSAVED JOB" : "SAVE JOB"}
                    </Button>
                  </div>
                  <Paper className={styles.mobileApplyBox}>
                    <Button
                      className={styles.favoriteButton}
                      onClick={() => {
                        isSaved
                          ? submitUnsavedJob(job_detail?.id)
                          : submitSavedJob(job_detail?.id);
                      }}
                    >
                      <img
                        src={isSaved ? Images.starBlueIcon : Images.starIcon}
                        style={{ backgroundColor: "#ffffff" }}
                      />
                    </Button>
                    <Button
                      disabled={isApplied}
                      className={styles.applyButton}
                      onClick={() => confirmationApply()}
                    >
                      Apply Job
                    </Button>
                  </Paper>
                </div>
                <Typography className={styles.detailTitle}>
                  Jobs Description
                </Typography>
                {job_detail?.Job_Description ? (
                  parseToHtml(job_detail?.Job_Description)
                ) : (
                  <Typography>-</Typography>
                )}
                <Typography className={styles.detailTitle}>
                  Requirement
                </Typography>
                {job_detail?.Requirement ? (
                  parseToHtml(job_detail?.Requirement)
                ) : (
                  <Typography>-</Typography>
                )}
                <Typography className={styles.detailTitle}>
                  Required Skills
                </Typography>
                <div className={styles.requiredSkills}>
                  {!job_detail?.Required_Skills_Experiences?.length ? (
                    <Typography>-</Typography>
                  ) : (
                    job_detail?.Required_Skills_Experiences.map(
                      (skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          className={styles.chip}
                        />
                      )
                    )
                  )}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={styles.recommendationContainer}>
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    lineHeight: "20px",
                    marginLeft: "12px",
                  }}
                >
                  Recommended Jobs
                </Typography>
                {props.recommendation.loading ? (
                  <>
                    <JobCard loading />
                    <JobCard loading />
                  </>
                ) : (
                  <>
                    {recommendation.map((job) => {
                      return <JobCard job={job} key={job.id} />;
                    })}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default JobDetailScreen;
