import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import useStyles from "./styles";

const DefaultDialog = ({ md, isOpen, onClose, children, fullwidth = true }) => {
  const styles = useStyles();

  return (
    <Dialog
      fullWidth={fullwidth}
      maxWidth="md"
      scroll="body"
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent
        className={md ? styles.dialogContainerMd : styles.dialogContainer}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DefaultDialog;
